define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "Contracts": "Contracts",
    "Files": "Files",
    "Partners": "Customers",
    "account": {
      "code": "Code",
      "competencies": "Roles",
      "contact": "Contact",
      "dailyAllocatedWorkTime": "Daily allocated working hours",
      "email": "Email",
      "employmentEndDate": "End date of employment",
      "employmentStartDate": "Start date of employment",
      "enabled": "Enabled",
      "language": "Language",
      "lastPasswdModify": "Last password modification",
      "leader": "Leader",
      "learnerId": "Learner ID",
      "longName": "Full name",
      "maxRegistredHours": "Max recordable hours",
      "modelName": "User",
      "monthlyAllocatedWorkTime": "Monthly allocated working hours",
      "numberOfTickets": "Number of tasks",
      "password": "Password",
      "passwordConfirmation": "Password confirmation",
      "phone": "Phone",
      "photo": null,
      "rawCost": "Raw hourly rate",
      "repairEngineer": "Repair Engineer",
      "reportAsAnotherUser": "Report as another user",
      "subcontractor": "Subcontractor",
      "userGrade": "Job position",
      "yearlyHolidays": "Annual vacation limit"
    },
    "action": {
      "competencies": "Competencies",
      "modelName": "Menu",
      "name": "Name",
      "parent": "Parent menu",
      "route": "Route",
      "systemName": "System name"
    },
    "activities": {
      "activity": "Activity",
      "date": "Date",
      "id": "Id",
      "modeCreate": "Create",
      "modeUpdate": "Update",
      "schema": "Schema",
      "tableName": "Table name"
    },
    "add-news": "Add news",
    "address": {
      "address": "Address",
      "addressType": "Type",
      "buildings": "Buildings",
      "clientPartner": "Partner",
      "country": "Country",
      "defaultWorking": "Default place of work",
      "distance": "Distance (km)",
      "floors": "Floors",
      "fullAddress": "Work location",
      "modelName": "Address",
      "name": "Name",
      "place": "Place",
      "possibility": "Work possibility",
      "town": "City",
      "zipCode": "Zip code"
    },
    "advanced-filter": {
      "please-select": "Please select"
    },
    "aiops": {
      "customer": {
        "contracts": "Contracts",
        "parameters": "Parameters",
        "services": "Services",
        "severance-pay": "Fixed wage data"
      },
      "dashboars": {
        "3-monthly": "3 months",
        "accept-planned-values": "I accept AI's suggestions",
        "accepted-by-aiops": "Adopted by AIOps",
        "accuracy-of-classified-tickets": "Accuracy of classified tickets",
        "all-tickets": "All tickets",
        "amount-month": "Contractual monthly amount",
        "assigned": "Assigned",
        "avg-spent-time": "Average solution time",
        "balance-calculation": "Payback calculation",
        "block": "Data lock",
        "blocked": "Data locked",
        "categorized": "Categorized",
        "daily": "Daily",
        "distribution-of-working-hours": "Distribution of working hours",
        "draft": "Advance notice",
        "error-values-allocated-by-aiops": "Error values allocated by AIOps according to tickets",
        "half-year": "Half year",
        "in-progress-tickets": "Tickets in progress",
        "indefinite": "Uncertain",
        "itil-tpye": "ITIL type",
        "max-spent-time": "Longest solution time",
        "monthly": "Monthly",
        "near-sla-tickets": "Tickets near SLA violation",
        "not-accepted-by-aiops": "Not accepted by AIOps",
        "operation-service-level": "Service",
        "overhed-fee": "Actual expenditure",
        "p1": "P1",
        "p2": "P2",
        "pcs": "pcs",
        "priority": "Priority",
        "qty": "pcs",
        "resolver-blocked": "Solver locked",
        "score": "Place",
        "sla-tickets": "SLA violated tickets",
        "solved-tickets": "Solved tickets",
        "solver": "Solver",
        "spent-time": "Working hours",
        "spent-times": "Work hours spent on tickets",
        "sum-spent-time": "Cumulative time distribution",
        "suspend-tickets": "Suspended tickets",
        "ticket-per-day": "Average number of tickets per day",
        "ticket-type": "Ticket type",
        "time-utilization-chart": "Time burden",
        "top-solvers": "Top solvers",
        "unsuccess": "Unsuccessful",
        "user-avg-spent-time": "Average solution time of the solver",
        "user-grade": "Competence",
        "utilization": "Work time utilization",
        "weekly": "Weekly",
        "work-log-in-competency": "Work hours spent on competence",
        "work-log-out-competency": "Work hours spent below competence",
        "year": "Annual"
      },
      "excel": {
        "downlaod-template": "Download template"
      },
      "interface": {
        "deleted": "Deleted",
        "diff": "Deviation",
        "help": {
          "title": "1."
        },
        "model": "Model",
        "modified": "Modified",
        "new": "New",
        "sync": "Synchronization"
      },
      "kanban": {
        "assign": "Allocation",
        "drop-a-ticket": "Throw a ticket",
        "matrix": "Matrix",
        "optimalization": "Optimization",
        "refresh": "Update",
        "relaod": "It resets",
        "settings": "Settings"
      },
      "matrix": {
        "ticket": "Task"
      },
      "operation-service-level": {
        "service-levels": "Service Levels"
      },
      "solvers": {
        "parameters": "Parameters",
        "user-skills": "Qualifications"
      },
      "ticket": {
        "cp": "Certainty",
        "notification-data": "Notification details",
        "other-data": "Other data",
        "planned": "Designed value",
        "time-data": "Deadlines"
      },
      "unsuccess": {
        "no-planned-operation-service-level": "AI did not find the right service!",
        "no-planned-priority": "The AI did not find the right priority!",
        "no-planned-ticket-type": "The AI did not find the right type!",
        "no-priority-or-operation-service-level-or-type": "AI did not find a suitable service or priority or type!",
        "no-service-level": "There is no suitable service level for the given service contract!",
        "too-many-operation-service-level": "Too many possible service contracts!",
        "uncertain": "Uncertain prediction!"
      },
      "unsuccess-categorization": "Categorization failed!"
    },
    "aiops-contract": {
      "active": "Active",
      "contractAmount": "Contract amount",
      "dtMode": "Data loading method",
      "modelName": "Contract",
      "name": "Designation",
      "sourceId": "Source ID",
      "validFrom": "Start of validity",
      "validTo": "Expiration date"
    },
    "aiops-contract-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Contract type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-customer": {
      "active": "Active",
      "address": "Title",
      "contact": "Contact",
      "dtMode": "Data loading method",
      "email": "E-mail",
      "modelName": "Customer",
      "name": "Customer name",
      "phone": "Phone",
      "solvers": "Dedicated solvers",
      "sourceId": "Source ID",
      "tax": "Tax number"
    },
    "aiops-day-type": {
      "active": "Active",
      "code": "Mist",
      "modelName": "Day type",
      "name": "Designation"
    },
    "aiops-interface": {
      "active": "Active",
      "dtMode": "Data loading method",
      "endpoint": "End point",
      "interfaceType": "Interface type",
      "modelName": "Interface",
      "name": "Designation",
      "password": "Password",
      "sourceId": "Source ID",
      "syncDate": "Last sync",
      "username": "Username"
    },
    "aiops-interface-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Interface type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-operation-service-level": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Service",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-optimization-item-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Optimization parameter type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-optimization-parameter": {
      "active": "Active",
      "defaultValue": "Default value",
      "dtMode": "Data loading method",
      "modelName": "Optimization parameters",
      "name": "Designation",
      "optimizationItemType": "Optimization parameter type",
      "parameterCategory": "Parameter category",
      "parameterType": "Parameter type",
      "relevancyValue": "Default relevance",
      "sourceId": "Source ID",
      "valueFrom": "Interval value (from)",
      "valueTo": "Interval value (to)"
    },
    "aiops-parameter-category": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Parameter category",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-parameter-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Parameter type",
      "name": "Designation",
      "parent": "Parent type",
      "sourceId": "Source ID"
    },
    "aiops-partner": {
      "active": "Active",
      "address": "Title",
      "contact": "Contact",
      "deleted": "Deleted",
      "email": "E-mail",
      "modelName": "Partner",
      "name": "Partner name",
      "partnerType": "Partner type",
      "phone": "Phone",
      "region": "Region",
      "tax": "Tax number"
    },
    "aiops-partner-type": {
      "active": "Active",
      "modelName": "Partner type",
      "name": "Designation"
    },
    "aiops-pm-type-customer-assoc-computed": {
      "optimizationParameter": "Optimization parameter",
      "paramValue": "Parameter value"
    },
    "aiops-pm-type-partner-assoc": {
      "optimizationParameter": "Optimization parameter",
      "paramValue": "Parameter value"
    },
    "aiops-pm-type-user-assoc-computed": {
      "optimizationParameter": "Optimization parameter",
      "paramValue": "Parameter value"
    },
    "aiops-service-level": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Service level",
      "name": "Designation",
      "reactionTime": "Reaction time",
      "solvingTime": "Solution time",
      "sourceId": "Source ID"
    },
    "aiops-services-contracts-x": {
      "active": "Active",
      "contract": "Contract",
      "customer": "Customer",
      "dailySeverancePay": "Daily penalty",
      "dailySeverancePayCount": "daily penalty limit (day)",
      "dtMode": "Data loading method",
      "failingSeverancePay": "Failure penalty",
      "failingSeverancePayCount": "Failure penalty limit (days)",
      "modelName": "Contractual service",
      "operationServiceLevel": "Service",
      "severancePay": "Lump sum",
      "severancePayCount": "Fixed wage limit (day)",
      "sourceId": "Source ID"
    },
    "aiops-severance-pay": {
      "active": "Active",
      "contractType": "Contract type",
      "customer": "Customer",
      "dtMode": "Data loading method",
      "modelName": "Lump sum",
      "severancePayFeeMax": "Fixed fee max.",
      "severancePayFeeMin": "Fixed fee min.",
      "severancePayType": "Fixed wage type",
      "sourceId": "Source ID"
    },
    "aiops-severance-pay-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Fixed wage type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-solver": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Solver",
      "name": "Designation",
      "overheadFee": "Overhead hourly rate",
      "sourceId": "Source ID",
      "userGrade": "Competence"
    },
    "aiops-test-ticket": {
      "description": "Description",
      "email": "E-mail",
      "modelName": "TEST ticket",
      "operationServiceLevel": "Service",
      "priority": "Priority",
      "publicSpentTime": "Spending time",
      "subject": "Object",
      "ticketType": "Ticket type",
      "userGrade": "Position"
    },
    "aiops-ticket": {
      "active": "Active",
      "customer": "Customer",
      "department": "Organization",
      "description": "Description",
      "dtMode": "Data loading method",
      "email": "E-mail",
      "modelName": "Ticket",
      "name": "Reporter",
      "open-ticket": "Open ticket",
      "operationServiceLevel": "Service",
      "operationServiceLevelCp": "Service certainty %",
      "plannedOperationServiceLevel": "Planned service",
      "plannedPriority": "Planned priority",
      "plannedPublicSpentTime": "Planned investment of time",
      "plannedResolver": "Designed solver",
      "plannedServiceLevel": "Planned service level",
      "plannedTicketType": "Planned ticket type",
      "plannedUserGrade": "Planned job title",
      "priority": "Priority",
      "priorityCp": "Priority certainty %",
      "processStatus": "Processing status",
      "publicSpentTime": "Spending time",
      "publicSpentTimeCp": "Time investment certainty %",
      "reactionTime": "Response time (minutes)",
      "resolver": "Solver",
      "resolverCp": "Solver certainty %",
      "serviceLevel": "Service level",
      "serviceLevelCp": "Service Level Assurance %",
      "solvingTime": "Obligation to solve (minutes)",
      "sourceId": "Source ID",
      "startTime": "Start obligation (minutes)",
      "subject": "Object",
      "ticketReactionTime": "Ticket response time",
      "ticketSolvingTime": "Ticket resolution date",
      "ticketStartTime": "Ticket start date",
      "ticketStatus": "Ticket status",
      "ticketType": "Ticket type",
      "ticketTypeCp": "Ticket type certainty %",
      "userGrade": "Position",
      "userGradeCp": "Job certainty %"
    },
    "aiops-ticket-check-planned": {
      "modelName": "Ticket",
      "operationServiceLevel": "Service",
      "plannedOperationServiceLevel": "Planned service",
      "plannedPriority": "Planned priority",
      "plannedPublicSpentTime": "Planned investment of time",
      "plannedTicketType": "Planned ticket type",
      "plannedUserGrade": "Planned job title",
      "priority": "Priority",
      "publicSpentTime": "Spending time",
      "sourceId": "Source ID",
      "subject": "Object",
      "ticketType": "Ticket type",
      "userGrade": "Position"
    },
    "aiops-ticket-state": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Ticket status",
      "name": "Designation",
      "sourceId": "Source ID",
      "ticketStatusSystem": "System status"
    },
    "aiops-ticket-state-system": {
      "modelName": "Ticket status (system)",
      "name": "Designation"
    },
    "aiops-ticket-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Ticket type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-user-absence": {
      "active": "Active",
      "day": "Date",
      "dayType": "Type",
      "dtMode": "Data loading method",
      "modelName": "Absence",
      "sourceId": "Source ID",
      "user": "Solver"
    },
    "aiops-user-grade": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Competence",
      "name": "Designation",
      "sourceId": "Source ID",
      "userGradeType": "Type"
    },
    "aiops-user-grade-type": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Competency type",
      "name": "Designation",
      "sourceId": "Source ID"
    },
    "aiops-user-skill": {
      "active": "Active",
      "dtMode": "Data loading method",
      "modelName": "Qualification",
      "operationServiceLevel": "Service",
      "skillLevel": "Skill level",
      "sourceId": "Source ID",
      "user": "Solver"
    },
    "appcomponentcmdb": {
      "choose-atrribute": "Select an attribute",
      "choose-operator": "Select an operator"
    },
    "appcomponentforms": {
      "choose-notifier": "Please select a notifier"
    },
    "appcomponenthrm": {
      "all-day": "All day",
      "create-an-event": "Create event",
      "free-day": "Day off",
      "more": "more...",
      "or-create-new": "Create a new one",
      "select-account": "Select a user!",
      "select-an-event-or": "Select an event, or",
      "select-leader": "Select leader",
      "select-user": "Select user"
    },
    "application-to-intervene": {
      "Inspection-carried": "Inspection carried out",
      "accepted": "Accepted",
      "add-contact": "Add contact",
      "add-subtask": "Add subtask",
      "allocations": "Allocations",
      "authorizers": "Authorizers",
      "course-intervention": "Course of intervention",
      "deadline": "Deadline",
      "downtime": "Downtimes",
      "effect-system": "What effect is expected on the affected systems",
      "executor": "Executor",
      "hours-available": "Available hours",
      "inspection-description": "Description of the inspection",
      "new-allocations": "New allocations",
      "not-accept": "Did not accept",
      "not-reported-back": "Not reported back yet",
      "planned-hours": "Planned hours",
      "preventive-measures": "Preventive measures",
      "recovery": "Recovery",
      "recovery-description": "Description of the recovery",
      "risk-description": "Nature and description of the risk",
      "risk-intervention": "Risks of intervention",
      "scheduled-hpurs": "Scheduled hours",
      "select-ci": "Select CI type",
      "ssz": "Ssz.",
      "stoped-or-modified": "System component to be stopped or modified (HW,SW)",
      "system-component": "System component",
      "system-intervention-request": "System intervention request",
      "technical": "Technical",
      "token-valid": "Token valid",
      "which-effect-system": "Which systems are at risk from the intervention",
      "which-system": "Which systems are affected by the intervention",
      "yes": "Yes"
    },
    "attachments": {
      "uploadDocument": "Upload files",
      "uploadTitle": "Upload files",
      "wrongExtension": "Only files with the extension {extensions} are accepted for the {documentType} category",
      "wrongMime": "Wrong mime type"
    },
    "availability-generate": {
      "serviceAreaError": "The service area is not filled!"
    },
    "bank-account": "Bank account",
    "bank-balance": {
      "balance": "Balance",
      "balanceOwnCurrency": "HUF currency",
      "bankAccount": "Bank account",
      "bankAccountOwner": "Bank Account Owner",
      "currency": "Currency",
      "date": "Date",
      "exchangeRate": "Exchange rate",
      "modelName": "Bank balance"
    },
    "bank-name": "Bank name",
    "bs-table": {
      "custom-filters": "Custom filters",
      "datas": "Datas",
      "default-colon": "Default:",
      "filter": "Filter",
      "filter-name": "Filter name",
      "filter-name-colon": "Filter name:",
      "saved-filters": "Saved filters"
    },
    "building": {
      "address": "Address",
      "clientPartner": "Partner",
      "floors": "Floor",
      "modelName": "Building",
      "name": "Name"
    },
    "builtin-acl": {
      "accessType2": "Access Type",
      "builtinAclActionAssocs": "Actions",
      "model": "Model",
      "modelName": "ACL",
      "permission": "Permission",
      "property": "Property",
      "readonly": "Read only"
    },
    "business-service": {
      "entityType": "Type",
      "entityTypeCategory": "Category",
      "modelName": "Business service",
      "name": "Name",
      "siteZone": "Zone"
    },
    "buttons": {
      "absence": "Day offs",
      "add": "Add",
      "add-critic": "Add criteria",
      "add-new-building": "Add new building",
      "add-new-dictionary": "Add new dictionary",
      "add-new-element": "Add new element",
      "add-new-level": "Add new level",
      "add-new-location": "Add new location",
      "add-new-worksheet": "Add new worksheet",
      "add-new-zone": "Add new zone",
      "add-to-news": "Add news",
      "add-value": "Add value",
      "blueprint": "Blueprint",
      "cancel": "Cancel",
      "capture-new-worksheet": "Add new worksheet",
      "change-status": "Change status",
      "ci-type-relation": "CI type relation",
      "clone": "Duplication",
      "close": "Close",
      "close-on-first-call": "Close on First Call",
      "close-planning": "Close planning",
      "collapse": "Collapse",
      "completion-certificate": "Certificate of performance",
      "complex-filter": "Complex filter",
      "connect-to-it": "Connect",
      "copying": "Copy",
      "create": "Create",
      "create-notification": "Create report",
      "create-shedule": "Create scheduling",
      "default": "default",
      "delete": "Delete",
      "delete-filters": "Delete filter",
      "deletetwo": "Delete",
      "done": "Done",
      "expand": "Expand",
      "export": "Export",
      "filtering": "Filter",
      "filters-dots": "Filters...",
      "finish": "Finish",
      "former-reports": "Former reports",
      "free-word-search-dots": "Free word search ...",
      "generate-offer": "Generate offer",
      "generate-xml": "Generate XML",
      "generation": "Generate",
      "hide-condition": "Hide condition",
      "hourly-rate": "New hourly rate",
      "hourly-rates": "Hourly rate",
      "info": "info",
      "invoices": "Invoices",
      "it-can-go": "It can go",
      "loadingdots": "Loading...",
      "login": "Login",
      "mass-closure": "Mass closure",
      "mass-connection": "Mass connection",
      "modify": "Modify",
      "new": "New",
      "new-application": "New application",
      "new-attribute": "New attribute",
      "new-bankaccount-number": "New bank account number",
      "new-business-effect": "New business impact",
      "new-category": "New category",
      "new-checklist": "New checklist",
      "new-ci-category": "New CI category",
      "new-ci-type": "New CI type",
      "new-colleague": "New colleague",
      "new-connection-type": "New connection type",
      "new-contract": "New contract",
      "new-day-type": "New day type",
      "new-day-type-definition": "New day type definiton",
      "new-department": "New department",
      "new-document-type": "New document type",
      "new-file": "New file",
      "new-file-status": "New file status",
      "new-incoming-offer": "New incoming offer",
      "new-integration": "New integration",
      "new-item": "New item",
      "new-job": "New job",
      "new-leave-application": "New leave application",
      "new-manufacturer": "New manufacturer",
      "new-mapper": "New mapper",
      "new-notification": "New ticket",
      "new-notification-group": "New notification group",
      "new-notification-type": "New notification group",
      "new-notification-without-aiops": "New meeting / training",
      "new-opportunity": "New opportunity",
      "new-outgoing-offer": "New outgoing offer",
      "new-partner": "New partner",
      "new-period": "Add period",
      "new-priority": "New priority",
      "new-profile": "New profile",
      "new-region": "New region",
      "new-relation": "Add new relations",
      "new-result-product": "New result product",
      "new-role": "New role",
      "new-service-level": "New service level",
      "new-service-time": "New service time",
      "new-software": "New software",
      "new-solver-group": "New solving group",
      "new-sub-task": "New subtask",
      "new-task-btn": "New project task",
      "new-title": "New title",
      "new-tool-type": "New asset type",
      "new-type": "New type",
      "new-urgency": "New urgency",
      "new-user": "New user",
      "new-vat": "New VAT",
      "next": "Next",
      "note-notification": "Report from note",
      "notification-apply-to-all": "Apply priority and notification to all services",
      "notification-group-apply-to-all": "Apply notification group to all services",
      "open-contract": "Open contract",
      "organizational-units": "Departments",
      "otherRange": "Other duration",
      "pdf": "PDF",
      "performance-certificate": "Certificate of performance",
      "posting": "Posting",
      "public-holiday": "Public holiday",
      "rbk-start": "Start new RBK",
      "refresh-list": "Refresh list",
      "register-customer": "Register customer",
      "registration": "Registration",
      "remote-access-informations": "Remote access and permissions",
      "reopen-planning": "Reopen planning",
      "restart-inventory": "Restart inventory",
      "save": "Save",
      "save-and-close": "Save and close",
      "save-filter": "Save filter",
      "save-two": "Save",
      "send": "Send",
      "send-two": "Send",
      "show-condition": "Show condition",
      "show-less": "Show less 🠝",
      "show-more": "Show more 🠟",
      "solver-apply-to-all": "Apply solving group to all services",
      "staff": "Coworkers",
      "start-monitoring": "Start monitoring",
      "stop-inentory": "Stop inventory",
      "stop-monitoring": "Stop monitoring",
      "syncronize": "Synchronization",
      "titles": "Titles",
      "unique-relation": "Unique relation",
      "update": "Update",
      "vacation": "Holiday",
      "vip": "VIP",
      "workday": "Workday"
    },
    "character-counter": "{remaining} characters remaining",
    "character-counter-over": "{remaining} characters over the allowed limit",
    "closedTickets": "Closed tickets",
    "cmdb": {
      "attributes": {
        "add-dictionary": "Add dictionary",
        "device-type-fields": "Device type fields",
        "region-fields": "Region fields"
      },
      "business-service": "Business service",
      "certificate": "Certificates",
      "change-requests": {
        "affected-entities": "Affected CI entities"
      },
      "clone-host": "Clone host",
      "dashboard": "Dashboard",
      "entities": {
        "new-business-serv": "New business service",
        "new-license": "New license",
        "new-software": "New software",
        "new-tech-serv": "New technical service"
      },
      "entity": {
        "assigned-contact": "Assigned contact",
        "change-assigned-partner-warning": "The location set for the CI element belongs to the user's partner, and it will be deleted if the assigned partner is changed, along with saving the element! Are you sure you want to continue?",
        "ci-type": "CI type",
        "ci-type-choose": "Select CI type",
        "custom-fields": "Custom fields",
        "device-type-fields": "Device type fields",
        "handover": "Handover",
        "owner-contact": "Owner's contact",
        "partner-owner": "Partner / Owner"
      },
      "entity-types": {
        "adding-new-device": "Add new device",
        "manage-procedures": "Manage procedures"
      },
      "hardware": "Hardware",
      "licence": "Licences",
      "procedures": {
        "ci-name": "CI name",
        "host": "Host",
        "notification-critical": "Notification (critical)",
        "notification-critical-hu": "Notification (critical)",
        "notification-down": "Notification (down)",
        "notification-down-hu": "Notification (down)",
        "notification-warning": "Notification (warning)",
        "notification-warning-hu": "Notification (warning)",
        "priority-critical": "Priority (critical)",
        "priority-down": "Priority (down)",
        "priority-warning": "Priority (warning)",
        "service": "Service",
        "solution-group": "Solution group"
      },
      "relation-manager": "Add relations",
      "relation-visualisation": {
        "business": "Business servant.",
        "hardware": "Tools",
        "license": "License",
        "software": "Softwares",
        "technology": "Tech.\nservant"
      },
      "relations": {
        "add-new-relation": "Add new relation"
      },
      "software": "Software",
      "technical-service": "Technical service",
      "visualisation": {
        "icon-select": "Select icon",
        "visualisation": "Visualization"
      }
    },
    "cmdb-import": {
      "query-schemas": "Download templates",
      "summary": {
        "category": "Category",
        "skipped": "Skipped",
        "total": "Total",
        "with-error": "With errors",
        "without-error": "Without errors"
      },
      "summary-error": "Row: {row} Column: {column} Message: {text} Error: {error}",
      "summary-error-found": "Row: {row} Column: {column} Message: {text} Found: {found}",
      "summary-skipped": "Row: {row} has not been imported. For error details check the Excel file",
      "title": "Import CMDB entities"
    },
    "cmdb-visualisation": {
      "accountable": "Responsible",
      "available": "Available",
      "category": "Category",
      "categoryName": "Category name",
      "checks": "Checks",
      "comment": "Comment",
      "downtimes": "Downtimes",
      "entityType": "Type CI",
      "entityTypeName": "CI type name",
      "expirationDate": "Expiration date",
      "license": "License",
      "location": "Location",
      "manufacturer": "Manufacturer",
      "monitoring": "Monitored",
      "name": "Identification",
      "noChanges": "There is no change in services.",
      "noChangesTitle": "No change!",
      "others": "Other",
      "partner": "Customer",
      "partnerName": "Name of client",
      "procedure": "Procedure",
      "productKey": "Product key",
      "productType": "Type",
      "quantity": "Quantity",
      "relation": "Connects",
      "relation-master": "Connecting (master)",
      "relation-slave": "Slave",
      "remote-access": "Remote access",
      "serialNumber": "Serial Number",
      "service": "Service",
      "status": "Status",
      "version": "Version"
    },
    "code-store": {
      "modelName": "Dictionary",
      "models": "Dictionaries"
    },
    "comment": {
      "socket": {
        "success": "A new comment has been received",
        "title": "A comment has been received"
      }
    },
    "competency": {
      "accounts": "Users",
      "modelName": "Role",
      "name": "Name"
    },
    "completionCertificate": {
      "steps": {
        "fulfil": "Fulfillment",
        "invoiceItems": "Invoice items",
        "project": "Project identification"
      }
    },
    "components": {
      "file-tariff": {
        "actual-tariff-period": "Current tariff valid through:",
        "no-actual-tariff": "No current tariff set"
      },
      "fullcalendar": {
        "day": "Day",
        "listWeek": "Weeks",
        "month": "Months",
        "today": "Today",
        "week": "Seven"
      },
      "shift-picker": {
        "sub-title": "For the solving group {solverGroupName} belongs to more than one shift change. Please chose one",
        "title": "Select shift begin"
      }
    },
    "components-main": {
      "AAM": "AAM",
      "ATK": "ÁTK",
      "EAM": "EAM",
      "EUE": "EUE",
      "EUFAD37": "EUFAD37",
      "EUFADE": "EUFADE",
      "HO": "HO",
      "KBA": "KBA",
      "NAM": "NAM",
      "OK": "OK",
      "TAM": "TAM",
      "actions": "Actions",
      "active-expenditure": "Active expenditure",
      "advance-invoice": "Advance invoice",
      "amount-units": "Amount units",
      "anonymus": "Anonymus",
      "comment": "Comment",
      "complete": "Complete",
      "complex-filter": "Complex filter",
      "contract-number-colon": "Contract number:",
      "costumer": "Customer",
      "date-of-minutes-colon": "Date of minutes:",
      "distance": "Distance",
      "dokumentation": "Dokcumentation",
      "duty-free-export-product": " -  Duty-free export of a product to a third country",
      "ending-colon": "Ending:",
      "exempt-taxpayer": "- Tax exempt",
      "filters": "Filters ...",
      "finished-tasks": "Finished tasks",
      "forbidden": "Forbidden",
      "general-vat-rates": "General VAT rates",
      "gross-interpol": "Gross",
      "help": "Help",
      "inactive-active-expenditure": "Inactive / Active expenditure",
      "inactive-expenditure": "Inactive expenditure",
      "intra-community-product": " - Intra-community supply of goods",
      "km-implemented": "{km} km",
      "licenses": "Licenses",
      "material-duty-free": " - Material duty free",
      "nav-outscope": "The value outOfScope will be true to NAV for items where this type of tax rate is specified",
      "nav-towards-vat-code": "The value given in the VAT code will be used as the reason for the VAT to NAV",
      "net-interpol": "Net",
      "net-unit-price": "Net unit price",
      "other-member-state": " - Transaction not subject to reverse charge in another Member State",
      "other-vat-not-37": "- Reverse charge transaction not subject to VAT under § 37 of the VAT Act, carried out in another Member State",
      "page-break-colon": "Page break:",
      "percent": "{percent}%",
      "performance-amount": "Performance amount",
      "period-hour": "Please select an hour",
      "period-minute": "Please select a minute",
      "priority-colon": "Priority:",
      "private": "Private",
      "project-manager-colon": "Project manager:",
      "project-name-colon": "Project name:",
      "public-holidays": "Public holidays",
      "rows-per-page": "Rows per page",
      "searching": "Search ...",
      "service-provider": "Service provider",
      "signatory-colon": "Signatory:",
      "status-colon": "Status:",
      "subject-of-performance": "Performance",
      "system-codes": "System codes",
      "system-codes-one-vat-key": "System code 1 represents the default VAT rate",
      "tax-free-other-transactions": "- Tax-free for other international transactions",
      "tax-free-vat-rates": "Tax-free VAT rates",
      "third-country-tehk": "- Transaction carried out in a third country (TEHK)",
      "ticketOfDate": "Completion date of the bidding task",
      "title-colon": "Title:",
      "transaction-id-colon": "Transaction ID:",
      "unknown": "Unknown",
      "vat-law-repealed-keys": "VAT-law repealed keys",
      "vat-material-repeal": " - Outside the scope of VAT",
      "vat-tv-37": "- Reverse charge transaction carried out in another Member State under § 37 of the VAT Act",
      "workdays": "Workdays",
      "xwiki": {
        "choose-path": "Select path",
        "error": {
          "error-title": "Unable to add wiki article",
          "page-name": "Page name required",
          "path": "Select path is mandatory",
          "tag": "At least one tag is required"
        },
        "page-name": "Page name",
        "route": "Route",
        "route-select": "Select route",
        "tags": "Tags"
      }
    },
    "contact": {
      "active": "Active",
      "applicant-choosing": "Select an applicant",
      "applicant-details": "Client’s details",
      "applicant-email": "EMAIL",
      "applicant-location": "LOCATION",
      "applicant-name": "APPLICANT NAME",
      "applicant-new": "New contact",
      "applicant-phone": "PHONE",
      "code": "Code",
      "contractName": "Contract name",
      "dateEnd": "Remaining time",
      "defaultReporter": "Default reporter",
      "department": "Department",
      "email": "E-mail",
      "entities": "Devices",
      "event-log": "Event log",
      "i2user": "I2 user",
      "inventoryManager": "Inventory manager",
      "isVip": "VIP",
      "messages": {
        "defaultReporterCheck": {
          "message": "A default reporter is already registered for the specified partner.",
          "title": "Default reporter already exists!"
        },
        "defaultReporterCheckCMDB": {
          "message": "There is no default reporter for the given partner.",
          "title": "There is no default reporter!"
        }
      },
      "modelName": "Contact",
      "moves": "Handovers",
      "name": "Name",
      "new-contact": "New contact",
      "notPaid": "Accounts receivable",
      "notificationGroups": "Notification groups",
      "partner": "Partner",
      "partnerShortName": "Customer",
      "phone": "Phone",
      "portal-user-tab": "Portal User",
      "position": "Position",
      "privateOperationServices": "Custom visibility",
      "states": "Notification statuses",
      "subcontractor": "Subcontractor",
      "types": "Notification issues",
      "user": "User"
    },
    "contract": {
      "fixed": "Definite",
      "modelName": "Contract",
      "onlyTandm": "Only T&M",
      "permanent": "Undecided",
      "tandm": "T&M"
    },
    "country": {
      "code": "Country code",
      "modelName": "Country",
      "name": "Name"
    },
    "credit-value-day": {
      "button": "Set",
      "info": "Monthly budget will be credited on the selected day. If the selected date is after the end of the month, the lower value will be applied."
    },
    "crm": {
      "contrats": {
        "edit-contract": "Edit contract",
        "new-contract": "New contract"
      },
      "main": {
        "add-coworker": "Add coworker",
        "domestic": "Domestic",
        "edit-coworker": "Edit coworker",
        "export": "Export",
        "foreign": "Foreign",
        "how-many-day-unpaid-invoice": "Number of days since the invoice is unpaid",
        "inland": "Inland",
        "maincontracting": "Main contracting",
        "market": "Market",
        "payment-deadline-left-behind": "Average number of days payment deadline is exceeded",
        "payment-discipline": "Payment discipline",
        "state": "State",
        "subcontracting": "Subcontracting"
      },
      "opportunities": {
        "add-item": "Add item",
        "add-offer": "Add offer",
        "add-result-product": "Add result product",
        "add-task": "Add task",
        "edit-item": "Edit item",
        "edit-offer": "Edit offer",
        "edit-oport-space": " Edit opportunity",
        "edit-result-product": "Edit result product",
        "edit-task": "Edit task",
        "import-items": "Import items",
        "import-tasks": "Import task",
        "new-task": "New task",
        "orde": "Order",
        "order": "Order"
      },
      "partners": {
        "acl": "Permissions",
        "add-bank-account-number": "Add bank account number",
        "add-contact": "Add contact",
        "add-title": "Add title",
        "addresses": "Addresses",
        "bank-accounts": "Bank account numbers",
        "contact-new-partner": "⚠️ You can only create a coworker with a saved partner!",
        "contacts": "Contacts",
        "default-data": "Basic data",
        "departments": "Departments",
        "edit-bank-account-number": "Edit bank account number",
        "edit-contact": "Edit contact",
        "edit-organizational-unit": "Edit organizational unit",
        "edit-partner": "Edit partner",
        "edit-title": "Edit title",
        "new-department": "New organizational unit"
      }
    },
    "cron": {
      "button": "Set Cron",
      "fieldName": "Schedule"
    },
    "cron-job": {
      "active": "Active",
      "cron": "Cron",
      "cronJobResults": "Results of run",
      "lastSuccessfulRun": "Last successful run",
      "modelName": "Cron",
      "name": "Name",
      "nextRun": "Next run"
    },
    "cron-job-result": {
      "createDate": "Run time",
      "creator": "Creator",
      "modelName": "Cron job result",
      "success": "Successful"
    },
    "currency": {
      "code": "Code",
      "modelName": "Currency",
      "name": "Currency",
      "round": "Rounding to decimal place"
    },
    "dashboard": {
      "done": "Weekly done tasks",
      "file-colon": "File:",
      "hour": "hour",
      "own-project-tasks": "Own project tasks",
      "project-readiness": "Project readiness",
      "task-colon": "Task:",
      "ticket": {
        "inProgress": "In progress",
        "incoming": "Forthcoming",
        "late": "Late"
      },
      "today": "Today's task",
      "unfinished": "Remaining tasks",
      "weekly": "Weekly tasks",
      "weekly-statement": "Weekly statement",
      "weekly-statement-two": "Weekly statement"
    },
    "date-type": {
      "excludes": "Exclude",
      "includes": "Include",
      "modelName": "Day Type",
      "name": "Name"
    },
    "date-type-definition": {
      "dates": "Days",
      "days": "Days / Dates",
      "modelName": "Day Type Definition",
      "name": "Name"
    },
    "date-type-file": {
      "excludes": "Exclude",
      "includes": "Include",
      "modelName": "Day Type",
      "name": "Name"
    },
    "dateRangePicker": {
      "lastSevenDays": "7 days have passed",
      "lastThirtyDays": "30 days have passed",
      "previousMonth": "Last month",
      "thisMonth": "This is the month",
      "today": "Today",
      "yesterday": "Yesterday"
    },
    "department": {
      "modelName": "Department",
      "name": "Name",
      "partner": "Partner"
    },
    "description": "Name",
    "document-type": {
      "competencies": "Roles",
      "extensions": "Extensions",
      "modelName": "Document type",
      "name": "Név",
      "shortName": "Short name"
    },
    "dueTickets": "Tickets due to expire soon",
    "duty": {
      "beginDate": "Begin date",
      "copy-monday": "Copy monday",
      "endDate": "End date",
      "frDutyDay": "Friday",
      "moDutyDay": "Monday",
      "modelName": "Hero of the day",
      "name": "Name",
      "only-top-can-be-deleted": "Only the first record can be deleted",
      "saDutyDay": "Saturday",
      "solverGroup": "Solver group",
      "solverGroupId": "Solver group ID",
      "suDutyDay": "Sunday",
      "thDutyDay": "Thursday",
      "tuDutyDay": "Tuesday",
      "weDutyDay": "Wednesday"
    },
    "entity": {
      "contact": "Contact",
      "description": "Description",
      "entityType": "CI type",
      "entityTypeCategory": "Category",
      "location": "Location",
      "modelName": "CI ite,",
      "monitoringWarning": "When changing the zone of the device, the monitoring acknowledgments belonging to the device are lost!",
      "name": "ID",
      "ownershipType": "Connection type",
      "partner": "Partner",
      "productKey": "Serial number",
      "remark": "Note",
      "serialNumber": "Serial number",
      "siteZone": "Zone",
      "status": "Status",
      "template": "Template",
      "worksite": "Worksite"
    },
    "entity-attribute": {
      "dependent": "The field can only be written if the {dependentAttribute} field has a value.",
      "listable": "Listable",
      "modelName": "Attribute",
      "name": "Name",
      "orderIndex": "Order",
      "parent": "Parent",
      "property": "Field name",
      "required": "Required",
      "searchable": "Searchable",
      "type": "Type",
      "types": {
        "array": "Block",
        "belongsTo": "Drop down list",
        "checkbox": "Check box",
        "date": "Date",
        "dateTime": "Date time",
        "hasMany": "Dropdown list (multi)",
        "number": "Song",
        "string": "Text input field",
        "text": "Multiline text field"
      }
    },
    "entity-contact-type": {
      "modelName": "Contact type",
      "name": "Name"
    },
    "entity-license": {
      "address": "Address",
      "contact": "CI Owner",
      "contactName": "Responsible",
      "department": "Department",
      "entityName": "CI element",
      "expirationDate": "Expiration date",
      "licenseName": "License",
      "licenseTypeName": "License type",
      "location": "Location",
      "modelName": "License",
      "partner": "Partner"
    },
    "entity-licensed-entity-assoc": {
      "entity": "Connected CI",
      "modelName": "Connected licences",
      "new-button": "Add item",
      "parentEntity": "License"
    },
    "entity-manufacturer": {
      "modelName": "Manufacturer",
      "name": "Name"
    },
    "entity-move": {
      "date": "Date",
      "description": "Description",
      "destination": "Destination",
      "entity": "Equipment",
      "entityName": "Identification",
      "entityType": "Device type",
      "modelName": "Equipment movements",
      "name": "Name",
      "owner": "Executor",
      "source": "Source",
      "type": "Type",
      "type-options": {
        "owner": "Owner",
        "user": "User"
      }
    },
    "entity-procedure": {
      "changes": "Perceived differences",
      "entityName": "Device",
      "modelName": "Procedure",
      "name": "Service",
      "notificationCritical": "Notification (critical)",
      "notificationWarning": "Notification (warning)",
      "priorityCritical": "Priority (critical)",
      "priorityWarning": "Priority (warning)",
      "procedures": "Procedures",
      "saveSuccess": "The order of procedure has been saved successfully.",
      "solvingGroup": "Solving group"
    },
    "entity-product-type": {
      "manufacturer": "Manufacturer",
      "modelName": "Entity type",
      "name": "Type"
    },
    "entity-type": {
      "approvals": "Approvals",
      "code": "Code",
      "codeStore": "Dictionary",
      "contacts": "Contact",
      "entities": "CI elements",
      "entityAttributes": "Attributes",
      "entityRelationTemplates": "Relation templates",
      "entityTypeCategory": "Category",
      "generateName": "Name generator",
      "generator": "Generator",
      "icingaName": "Icinga",
      "icon": "IIcon",
      "licensedEntity": "Related items",
      "locations": "Locations",
      "longName": "Full name",
      "missingData": "There has been a change in services.\nPlease fill in the missing information.",
      "modelName": "CI type",
      "moves": "Movements",
      "name": "Name",
      "otherData": "Other data",
      "parent": "Parent",
      "relations": "Relationships",
      "software": "Softwares",
      "template": "Template",
      "tickets": "Tickets"
    },
    "entityTypeVisualisation": {
      "themeSwitcher": "Theme switcher"
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "The value of {description} must be greater than {gt}.",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "has-many": "Select at least {min} items",
      "has-many-add": "Add at least {min} items",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} can only contain numbers.",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "passwordNotMatch": "The password does not match",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "service-period-interval": {
        "wrong-format": "Incorrect format (eg: 21:12)"
      },
      "singular": "{description} can't be a collection",
      "tooLong": "The value of {description} is too long (maximum {max} characters)",
      "tooShort": "The filled-in text is too short (at least {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "expiredTickets": "Expired",
    "fetchmail": {
      "active": "Active",
      "authenticationType": "Authentication type",
      "authenticationTypeBasic": "Basic",
      "authority": "Authentication Address (URL)",
      "clientId": "Client ID (clientId)",
      "clientSecret": "Secret client code (clientSecret)",
      "connect": "Server",
      "error-logs": "Error log",
      "fetchmailPassword": "Password",
      "fetchmailType": "Type",
      "fetchmailUser": "Username",
      "host": "Server name",
      "isSsl": "SSL/TLS",
      "lastSuccessfulRun": "Last successful run",
      "modelName": "Fetchmail",
      "name": "Designation",
      "original": "Original retention",
      "port": "Port",
      "user": "Responsible"
    },
    "fetchmail-log": {
      "createDate": "Date",
      "description": "Description",
      "email": "E-mail",
      "fetchmail": "Fetchmail",
      "log": "Journal entry",
      "modelName": "Fetchmail log",
      "subject": "Object"
    },
    "fetchmail-type": {
      "modelName": "Fetchmail type",
      "name": "Designation"
    },
    "file-business-type": {
      "modelName": "Business type",
      "name": "Name",
      "profitCenter": "Profit Center"
    },
    "file-operational": {
      "overview-operational": "Overview operational",
      "statistics": {
        "incomings": "Revenues",
        "outgoings": "Expenditures"
      },
      "ticket-not-deletable": "The ticket has already been booked, therefore it cannot be deleted."
    },
    "file-outgoing-proposal": {
      "amount": "Gross amount",
      "amountUntaxed": "Net amount",
      "createDate": "Create date",
      "currency": "Currency",
      "cycle": "Cycle",
      "defaultAmount": "Gross amount (default)",
      "defaultAmountUntaxed": "Net amount (default)",
      "defaultDescription": "In the case of equipment transportation, the bidder reserves the ownership rights of the ordered products until full payment has been received for the purchase. In the case of service orders, the fulfillment deadline is determined in a separate written document upon acceptance and placement of the order. Payment is due within 8 business days from the date of invoice issuance. The basis for invoicing is the delivery note for equipment transportation or the Certificate of Performance (CoP) for services, both of which must be signed by both parties. Exchange rate conditions: Our price quote is based on the daily exchange rates of the MNB currency EUR/HUF and USD/HUF. We incorporate changes in exchange rates and manufacturer list prices into our prices. If the exchange rate increases by more than 1.5% by the time of invoicing, the Bidder is entitled to adjust the contractual price by the corresponding amount until full payment has been received for the purchase.\n",
      "description": "Note",
      "discount": "Discount",
      "discountAmount": "Price reduction",
      "endDate": "End of validity",
      "exchangeRateContract": "Exchange rate (Contract)",
      "fileProductResults": "Result products",
      "fromDate": "Start of validity",
      "isOrdered": "Ordered",
      "modelName": "Outgoing quotation",
      "name": "Name",
      "quotationNumber": "ID",
      "tandm": "T&M",
      "type": "Type",
      "type_base": "Operation",
      "type_project": "Project",
      "type_tandm": "T",
      "type_tandm_overtime": "T",
      "valid": "Valid"
    },
    "file-outgoing-proposal-item": {
      "amount": "Gross amount",
      "amountUntaxed": "Net amount",
      "defaultAmount": "Gross amount (default)",
      "description": "Description",
      "discount": "Margin / Discount (%)",
      "modelName": "Outgoing quotation item",
      "name": "Name",
      "price": "Price",
      "quantity": "Quantitiy",
      "uom": "Unit of measurement",
      "vat": "VAT"
    },
    "file-outgoing-proposal-user-grade-cost": {
      "cost": "Hourly rate",
      "fileOutgoingProposal": "Outgoing offer",
      "modelName": "Hourly rate",
      "userGrade": "Classification"
    },
    "file-product-result": {
      "billable": "Billable",
      "comment": "Description",
      "modelName": "Result product",
      "name": "Name",
      "plannedEndDate": "End date",
      "plannedStartDate": "Start date"
    },
    "file-proposal": {
      "amount": "Gross amount",
      "amountUntaxed": "Net amount",
      "currency": "Currency",
      "description": "Description",
      "exchangeRate": "Exchange rate",
      "modelName": "Incoming quotation",
      "name": "ID",
      "paid": "Paid",
      "partnerRef": "Supplier reference",
      "supplier": "Supplier",
      "valid": "Valid"
    },
    "file-proposal-item": {
      "amount": "Gross amount",
      "amountUntaxed": "Net amount",
      "arrivalDate": "Arrival date",
      "changeExchangeRate": "Exchange rate override",
      "currency": "Currency",
      "defaultAmount": "Gross amount (default)",
      "defaultAmountUntaxed": "Net amount (default)",
      "description": "Description",
      "exchangeRate": "Exchange rate",
      "exchangeRateContract": "Exchange rate (Contract)",
      "exchangeRateDate": "Exchange rate date",
      "modelName": "Incoming quotation item",
      "name": "Name",
      "partNumber": "P/N",
      "price": "Price",
      "productResult": "Result product",
      "qty": "Quantity",
      "uom": "Unit of measurement",
      "vat": "VAT"
    },
    "file-state": {
      "allocation": "Allocation",
      "contract": "Contract",
      "modelName": "File status",
      "name": "Név",
      "navName": "NAV name",
      "observation": "Monitoring",
      "opportunity": "Opportunity",
      "orderOk": "Order OK",
      "outgoingProposal": "Outgoing proposal",
      "planning": "Planning",
      "probabilityMax": "Maximum probability",
      "probabilityMin": "Minimum probability",
      "proposal": "Proposal"
    },
    "file-states": {
      "title": "File states"
    },
    "file-tariff": {
      "amount": "Tariff value",
      "dateEnd": "Completion",
      "dateStart": "The beginning",
      "modelName": "Tariff"
    },
    "file-type": {
      "code": "Code",
      "contract": "Contract",
      "export": "Export",
      "fileBusinessType": "Business type",
      "modelName": "File type",
      "name": "Name",
      "orderOk": "Valid proposal",
      "outgoingProposal": "Outgoing proposal",
      "planning": "Planning",
      "projectType": "File type",
      "proposal": "Proposal"
    },
    "file-types": {
      "title": "File types"
    },
    "file-user-grade-cost": {
      "cost": "Hourly rate",
      "file": "File",
      "modelName": "Hourly rate",
      "plannedCost": "Planned Cost",
      "resetUserGrades": "Reset hourly rates",
      "resetUserGradesConfimation": "Are you sure, that you want to erset the planned hourly rates?",
      "userGrade": "Rate"
    },
    "filters": {
      "attributeTypes": {
        "array": "List",
        "belongsTo": "Connecting",
        "checkbox": "True False",
        "date": "Date",
        "hasMany": "They are related",
        "number": "Song",
        "string": "Text"
      },
      "closed": "Closed",
      "export": "Export",
      "fuzzy-search-placeholder": "Text search...",
      "new-filter": "New filter",
      "operators": {
        "Boolean": "Yes No",
        "Contains": "Included",
        "Equal": "Exactly",
        "GreaterThan": "Greater than",
        "LessThan": "Smaller than",
        "Null": "Not filled in"
      },
      "remove-filter": "Remove filter",
      "reset-filters": "Reset to default",
      "save-tab": "Save filter",
      "save-tab-instruction": "Please enter the filter name",
      "show-inactive": "Show inactive"
    },
    "finances": {
      "account-number": "Account number",
      "amount": "Amount",
      "amount-paid": "Paid amount",
      "balance": "Balance",
      "contracts": "Contracts",
      "creation": "Creation",
      "current-balance": "Current balance",
      "demand-payment": "Payment demand",
      "device-service": "Device/Service",
      "disabled": {
        "correcting": "A correcting invoice cannot be issued for this invoice",
        "delete-invoice": "Only pre-invoices can be deleted",
        "final-invoice": "Not a final invoice",
        "nav": "NAV status cannot be queried",
        "nav-send": "Cannot be submitted to NAV",
        "normal-pre-invoice": "Only normal and pre-invoices can be copied",
        "pre-invoice": "Only pre-invoices can be edited",
        "storno": "Cannot be cancelled"
      },
      "final-invoice": "Final invoice",
      "floor": {
        "address": "Title",
        "building": "Building",
        "clientPartner": "Partner",
        "modelName": "Level",
        "name": "Level"
      },
      "given": "Given",
      "gross": "Gross",
      "incoming-invoices": {
        "data-source": "Data source",
        "delivery-date": "Transfer date",
        "invoice-pdf-size": "Invoice pdf size",
        "new": "New incoming invoice",
        "transfer-to-accounting": "Transfer to accounting"
      },
      "industry": "Industry",
      "invoice-number": "Invoice number",
      "main": {
        "accepted-by-them": "Accepted",
        "account-number": "Account number",
        "accounting-date": "Accounting date",
        "add-days-info": "With the selected partner add the number of payment days to the current date",
        "advance-invoice": "Advance invoice",
        "approved-by-them": "Approved",
        "create-invoice-error": "Error creating invoice",
        "currency-rate": "Currency",
        "mixed": "Mixed",
        "nav": "NAV",
        "new-invoice": "New invoice",
        "not-available": "N/A",
        "one-time": "One time",
        "partner-address-error": "No billing address set for the selected partner",
        "payment-amount": "Payment amount",
        "payment-mode": "Payment mode",
        "services": "Services",
        "submission-error": "Error on submission",
        "unique": "Unique",
        "warning": "Warning",
        "word": "{word}"
      },
      "nav": {
        "check-state": "Check status",
        "check-state-error": "Error while checking status",
        "check-state-response": "The received status: {status}",
        "send": "Submit invoice to NAV",
        "send-error": "Error while submitting invoice",
        "state": "NAV state"
      },
      "nav-connection": "NAV connection",
      "nav-connection-live": "NAV live API",
      "nav-connection-test": "NAV test API",
      "nav-instruction": "Select the connection to use for data reporting",
      "nav-warning": "WARNING: The invoice will be automatically forwarded to the NAV.",
      "opportunities": "Opportunities",
      "overdue": "Overdue",
      "overhead-cost": "Overhead",
      "own-re-invoicing": "Own/Forwarded",
      "paid": "Paid",
      "paid-out": "Paid off",
      "partner": "Partner",
      "payment": "Payment",
      "payment-method": "Payment method",
      "payments": "Payments",
      "payments-date": "Payment date",
      "pre-invoice": "Pre-invoice",
      "pre-invoices": "Pre-invoices",
      "prepayment-save": "Save as pre-invoice",
      "preview": "Prewiew",
      "profit-center": {
        "account-number": "Account number",
        "choose-file-business-type": "Select a business type",
        "choose-file-type": "Select a project type",
        "choose-profit-center": "Select a profit center",
        "profit-center": "Profit center",
        "profit-center-fields": "Profit center fields"
      },
      "quotation-generation": "Generate quote",
      "sector": "Sector",
      "tig-generation": "Generate certificate of performance / Generate TIG",
      "time-interval": "Time interval",
      "unpaid": "Unpaid",
      "vat-paid": "VAT paid",
      "weighted": "Weighted",
      "xml-generation": "Generate XML"
    },
    "floor": {
      "address": "Address",
      "building": "Building",
      "clientPartner": "Partner",
      "locations": "Locations",
      "modelName": "Floor",
      "name": "Name"
    },
    "forms": {
      "access-type": "Access Type",
      "account-category-properties": "Account Category Properties",
      "account-details": "Account details",
      "account-serial-number": "Invoice serial number",
      "account-type": "Account type",
      "account-type-properties": "Account Type Properties",
      "accountable": "Accountable",
      "accountcredit": "Account Number: Payable",
      "accountdebit": "Account Number: Receivable",
      "action": "Action",
      "active": "Active",
      "add": "Add",
      "add-allocation": "Add allocation",
      "add-manufacturer": "Add manufacturer",
      "add-new-exception": "Add new exception",
      "add-new-requirement": "Add new requirement",
      "add-notification-group": "Add notification group",
      "add-type": "Add type",
      "additional-disclosure": "Additional disclosure",
      "agile": "Agile",
      "all-day-question": "All day?",
      "allocable-hours": "Allocable hours",
      "application-description": "Ticket description",
      "area-of-service": "Area of service",
      "assets-to-be-transferred": "Assets to be transferred",
      "attachments-colon": "Attachments:",
      "automatic-name-generation": "Automatic name generation",
      "available-qty": "Available Quantity",
      "bank": "Bank",
      "barcode": "Barcode",
      "base-url": "URL",
      "billingadress": "Billing address",
      "billingcycle": "Billing Cycle",
      "booking-for-tickets": "How many days back can the booking of tickets created on this file go",
      "building": "Building",
      "built-in-device": "Built in device",
      "cash": "Cash",
      "cashbookid": "Cash Book ID",
      "change-reason": "Change reason",
      "change-type": "Change type",
      "checkip": "Check IP address: {mask}",
      "chmlvl": "Critical, High, Medium, Low, Negligible",
      "choose-manager": "Select manager",
      "choose-option-fixing": "Select option for fixing",
      "choose-product-results": "Select the result product(s) to be invoiced",
      "choose-sponsor": "Select sponsor",
      "choose-technicalmanageruser": "Select technical customer manager",
      "ci": "CI",
      "ci-name": "CI name",
      "ci-s": "CIs",
      "ci-type": "CI type",
      "classification": "Classification",
      "client-partner": "Partner",
      "close-file": "Close file",
      "code": "Code",
      "code-colon": "Code:",
      "code1": "Code 1",
      "code2": "Code 2",
      "comments": "Comments",
      "communication": "Communication",
      "company-name": "Company name",
      "companyname": "COMPANY NAME",
      "companynamecolon": "COMPANY NAME:",
      "connected-to-it": "Connected",
      "connection-type": "Connection type",
      "connections": "Connections",
      "consolidated-notifications": "Consolidated notifications",
      "content": "Content",
      "continous": "Continous",
      "contract": "Contract",
      "contract-colon": "Contract:",
      "copied-to-clipboard": "Copied to clipboard!",
      "copy-success": "Copy successful",
      "cost-price": "Cost price",
      "cost-price-two": "Cost price",
      "costumer-buyer-colon": "Customer/Buyer:",
      "country": "Country",
      "coworker": "Contact",
      "currency": "Currency",
      "currency-code": "Currency code",
      "currency-name": "Currency name",
      "currently-no-announcements": "Currently no consolidated announcements",
      "custom-attributes": "Custom attributes",
      "customer-it-connection": "Customer IT connection",
      "customer-manager": "Customer manager",
      "customer-sat-ques": "Customer Satisfaction Questionnaire",
      "customercolon": "Customer:",
      "customersponsor": "Customer sponsor",
      "date": "Date",
      "date-end": "End date",
      "date-of-entry": "Date of entry",
      "date-start": "Start date",
      "datePerRange": "Date/Range",
      "dated": "Dated",
      "dates": "Dates",
      "datetype": "Datetype",
      "day-dates": "Days / Dates",
      "dayhourminute": "Day / Hour / Minute",
      "days": "Days",
      "deadlines": "Deadlines",
      "debit": "Debit",
      "default": "Default",
      "default-business-service": "Default business service",
      "default-service-level": "Default contractual service",
      "default-working-address": "Default place of work",
      "defenetly-no": "Definitely not",
      "degree-readiness": "Degree of readiness",
      "degree-rounding": "Rounding Degree",
      "delegations": "Delegations",
      "deleted-entry": "This entry will be deleted!",
      "demand": "Demand",
      "dependencies": "Dependencies",
      "descripction-colon": "Description:",
      "description": "Description",
      "designers": "Designers",
      "detract": "Detract",
      "device": "Device",
      "device-designed-to-control-service": "Device designed to control service",
      "device-type": "Device type",
      "distancekm": "Distance (km)",
      "edit-notification-group": "Edit notification group",
      "emailcolon": "EMAIL:",
      "emailcolonlower": "E-mail:",
      "end-of-validity": "end of validity",
      "ending-time": "End time",
      "entity-relation-type": "Relation type",
      "entityTypeCategory": "Category",
      "eu-taxnumber": "EU VAT number",
      "exception-colon": "Exception:",
      "exchangerate": "Exchange Rate",
      "exchangeround": "Decimal Places for Exchange Rate",
      "exchangeunit": "Exchange Rate Unit",
      "expiration": "Expiration",
      "expiration-date": "Expiration date",
      "export-colon": "Export:",
      "extencions": "Extencions",
      "external-integration": "Integration",
      "external-key": "External key",
      "external-value": "External value",
      "fastener": "Fastener",
      "feedback-deadline": "Feedback deadline",
      "fieldname": "Field name",
      "file-code-format": "File code format",
      "filter-params": "Basic query",
      "financial-number": "Financial reference number",
      "financial-relationship": "Financial relationship",
      "finish-crop": "Finish editing image",
      "fix-worksheet": "Save worksheet",
      "fquantity": "Quantity",
      "friday": "Friday",
      "from": "Date from",
      "fulladdress": "Address",
      "generate-name": "Generate name",
      "gimuy": "g,i,m,u,y",
      "goal-fulfillment": "Goal fulfillment",
      "gross-colon": "Gross: ",
      "gross-price": "Gross price",
      "group-taxnumber": "Group VAT number",
      "hourly-rate": "Hourly rate",
      "hours": "{hours} hours",
      "i2user": "I2 user",
      "iban": "IBAN",
      "ichigatwo": "Icinga2",
      "icingaName": "Icinga",
      "identifier": "Identifier",
      "imac-limit-colon": "IMAC limit:",
      "imac-question": "IMAC?",
      "imacd": "IMACD",
      "imacd-lower": "Lower IMACD limit",
      "immediacy": "Immediacy",
      "impact-change": "Impact of change",
      "inactive": "Inactive",
      "incoming-offer-colon": "Incoming offer:",
      "industry": "Industry",
      "informations": "Informations",
      "inprogress": "In progress",
      "inside-job": "Inside job",
      "inside_the_zone": "Notifications inside the zone",
      "internal-accounting": "Internal accounting",
      "internally-accounted": "Internally accounted",
      "inventory-organization": "Inventory organization",
      "inverse": "Inverse",
      "invoiceitems": "Invoice items",
      "invoicename": "Invoice name",
      "iris-key": "Iris key",
      "iris-value": "Iris value",
      "islisted": "Listed",
      "it-connection": "IT connection",
      "its-ending": "It's ending",
      "itsname": "Name",
      "job-opportunity": "Work possibility",
      "justification-upper": "JUSTIFICATION",
      "key": "Key",
      "level": "Level",
      "location": "Location",
      "locationcolon": "LOCATION:",
      "main-notification-id": "Main notification ID",
      "main-paymentmethod": "Main payment method",
      "max-duration": "Max: {duration} minute",
      "max-recorded": "Maximum recorded (hours)",
      "maxdot": "Max.",
      "menu": "Menu",
      "min-duration": "Min: 0 minute",
      "mindot": "Min.",
      "model": "Model",
      "monday": "Monday",
      "monitor-device": "Monitor Device",
      "monitoring": "Monitoring",
      "name-colon": "Name:",
      "name-of-notifier-colon": "NAME OF NOTIFIER:",
      "name-of-service": "Name of service",
      "name-status": "Status name",
      "namecolon": "Name:",
      "nature-of-the-file": "Type of file",
      "nav-name": "NAV name",
      "net-amount-colon": "Net amount: ",
      "net-colon": "Net: ",
      "netprice": "Net price",
      "new-ticket-booking": "Maximum hours/day bookable for newly created tickets",
      "no-more-to-do": "There is no more to do.",
      "no-result-product": "No result product",
      "normal": "Normal",
      "not-billable-elements": "Non-billable elements",
      "note": "Note",
      "noticeperioddays": "Notice Period (in days)",
      "notification-groups": "Notification groups",
      "notification-statuses": "Notification statuses",
      "notification-types": "Notification types",
      "notificationGroupType": "Notification type",
      "notifier-id": "Notifier identification",
      "obligatory": "Obligatory",
      "open-file": "Open file",
      "operation": "Operation",
      "ordered": "Ordered",
      "organization": "Partner",
      "organizational-unit": "Department",
      "other": "Other",
      "outbound-offer-colon": "Outbound offer:",
      "outsider-id": "External identifier",
      "overhead": "Overhead",
      "owner": "Owner",
      "parameters": "Parameters",
      "parent": "Parent",
      "part-number": "P/N",
      "partner-inbound-accounts": "Partner's incoming invoices",
      "partner-it-connection": "Partner IT connection",
      "partner-owner": "Partner",
      "partnersponsor": "Representative",
      "payment-deadline": "Payment Deadline",
      "payment-methodproperties": "Payment Method Properties",
      "payment-type": "Payment type",
      "paymentdeadlinecolon": "Payment Deadline: ",
      "paymentmethod": "Payment method",
      "performance": "Performance",
      "performance-period": "Performance period",
      "periods": "Periods",
      "permission": "Permission",
      "personal-conditions": "Personal conditions",
      "phase-name": "Phase name",
      "phone": "Phone",
      "phone-number": "Phone number",
      "phonecolon": "PHONE:",
      "place-of-work": "Place of work",
      "plan-spent-hours": "Planned spent hours",
      "planned-end": "Planned end",
      "planned-start": "Planned start",
      "planned-task-hours": "Planned task hours",
      "planning-colon": "Planning:",
      "portion": "Portion",
      "possible-solvers": "Possible solvers",
      "prfapproval": "Person responsible for approval",
      "price": "Price",
      "pricepercycle": "Price per Cycle",
      "primary-duty-officer": "Primary duty officer",
      "prime-contractor": "Customer",
      "priority": "Priority",
      "prioritycolon": "Priority:",
      "probability": "Probability",
      "probabilitymax": "Probability Max",
      "probablilitymin": "Probability Min",
      "procedure-in-sync": "Congratulations! The procedure is in sync.",
      "product-key": "Product key",
      "project": "File",
      "project-data-copied-to-clipboard": "Project data copied to clipboard!",
      "project-manager": "Project manager",
      "projectcolon": "Project:",
      "projecttwo": "Project",
      "property": "Property",
      "proposal-upper": "PROPOSAL",
      "qty": "Quantity",
      "rbk": "RBK",
      "reaction-time-colon": "Reaction time:",
      "reapeater-question": "Recurring?",
      "reason-for-change": "Reason for change",
      "reasoning": "Justification",
      "recommended-hourly-rate": "Recommended hourly rate",
      "regexp": "RegExp",
      "region-properties": "Region properties",
      "related-device": "Related device",
      "relation": "Relation",
      "remaining-time": "Remaining time (hh:mm)\"",
      "remote-access": "Remote access and connections",
      "repeating-colon": "Repeating:",
      "result-product": "Result product",
      "result-product-colon": "Result product:",
      "resync-cron": "Resync timing",
      "sataille": "Satellite",
      "saturday": "Saturday",
      "save-procedure": "Save Procedure",
      "save-procedure-success": "The order(s) have been saved successfully.",
      "sd": "SD",
      "searcheable": "Searchable",
      "secondary-duty-officer": "Secondary duty officer",
      "secondcurrency": "Currency",
      "select-address": "Select address",
      "select-building": "Select building",
      "select-building-first": "Select address first",
      "select-customer-manager": "Select customer manager",
      "select-designers": "Select Designers",
      "select-level": "Select level",
      "select-level-first": "Select building first",
      "select-location": "Select location",
      "select-location-first": "Select level first",
      "select-partner-first": "Select partner first",
      "select-solvers": "Select Solvers",
      "serial-number": "Serial Number",
      "service-desk": "Service Desk",
      "service-period": {
        "dateError": "The {from} time must be less than the {to} time"
      },
      "service-provider-supplier-colon": "Service Provider/Supplier:",
      "service-sat": "HOW SATISFIED ARE YOU WITH THE SERVICE?",
      "service-time": "Service time",
      "serviceperiodcolon": "Service period:",
      "settlement": "City",
      "settlementdate": "Settlement date",
      "severity-levels": "Severity levels",
      "si-minimum-fulfillment-colon": "Minimum of SI fulfillment:",
      "skip-procedure": "Skip Procedure",
      "sla-covered": "SLA covered:",
      "sla-hour-colon": "hour(s):",
      "sla-times-colon": "SLA times:",
      "smb": "Small, Medium, Big",
      "solution-time-colon": "Solution time:",
      "solution-time-two-colon": "Solution time:",
      "solutions": "Solutions",
      "solver": "Solver",
      "solvercolon": "Solver:",
      "solvers": "Solvers",
      "start-of-contract": "Start of contact",
      "start-of-validity": "Start of validity",
      "start-of-warranty": "Start of warranty",
      "start-time-colon": "Start time:",
      "startendcycles": "Difference Between Start and Expiration/Termination Notice Period (in months) / Billing Cycle (in months) * Price per Cycle",
      "starting-time": "Start time",
      "state-of-service": "State of service",
      "statusesdots": "Statuses ...",
      "subject": "Subject",
      "subject-of-application": "Subject of application",
      "subjectcolon": "Subject:",
      "sum-colon": "Sum: ",
      "sunday": "Sunday",
      "supplier-deliver-notes": "Supplier Delivery Notes",
      "supplier-project-number": "Project Identifier",
      "supplier-reference": "Supplier reference",
      "suppliers": "Suppliers",
      "swift": "SWIFT",
      "switches": "Switches",
      "syncronise-attributume": "Synchronizing the attribute with the monitoring system",
      "system-code": "System code",
      "szolg-eszk": "Service/Device",
      "tariff": "Estimated value.\nThe planned cost per cycle.",
      "task": "Task",
      "task-identifier": "Task identifier",
      "task-state": "Task state",
      "task-status": "Task status",
      "tax": "Tax",
      "taxnumbercolon": "Tax number: ",
      "taxnumbereucolon": "Tax number EU: ",
      "technical-contact": "Technical contact",
      "technicalmanageruser": "Technical customer manager",
      "template": "Template",
      "templatecolon": "Template:",
      "templates": "Templates",
      "thursday": "Thursday",
      "ticket-code-format": "Ticket code format",
      "timed": "Can be timed",
      "timing": "Timing",
      "title-of-work": "Work address",
      "tmbased-colon": "T&M based:",
      "to": "Date until",
      "to-be-billed-outwards": "To be billed outwards",
      "totaly": "Totally",
      "tuesday": "Tuesday",
      "type": "Type",
      "type-rounding": "Rounding Type",
      "typecolon": "Type:",
      "types": "Types",
      "unit-of-measure": "Unit of measure",
      "unit-price": "Unit Price",
      "unitprice": "Unit price",
      "uns": "Urgent, Normal, Deferred",
      "upload-items": "Upload items",
      "upload-task": "Uploaded task",
      "uploaded-file": "Uploaded file",
      "urgencylevel": "Urgency level",
      "used": "Used",
      "user-it": "User",
      "user-name": "Username",
      "user-pass": "Password",
      "valid-offer-colon": "Valid offer:",
      "valid-steps": "Valid steps",
      "value": "Value",
      "valuecurrency": "Currency of Value",
      "vat": "VAT",
      "vat-code": "VAT code",
      "vat-properties": "VAT properties",
      "vat-type": "VAT type",
      "vat-value": "VAT value",
      "vatrate": "VAT rate",
      "version": "Version",
      "vip": "VIP",
      "vip-involvement": "VIP involvement",
      "virtual": "Virtual",
      "volumen": "Volumen",
      "warning-capital": "Warning",
      "warranty-expiration": "Warranty expiration",
      "waterfall": "Waterfall",
      "wazuh": "Wazuh",
      "wednesday": "Wednesday",
      "wiki-knowledge-base": "Wiki/Knowledge base",
      "work-in-field": "Work on site?",
      "worklog-format": "Work log format",
      "worksheet-fixing-day": "Worksheet fixing (days)",
      "worksheet-number": "Worksheet number",
      "zip-code": "Zip code",
      "zone": "Zone"
    },
    "general": {
      "abort": "Abort",
      "activate": "Activate",
      "active": "Active",
      "all": "All",
      "allowed-extensions": "Allowed extensions:",
      "approval": "Approve",
      "approve": {
        "error": "Error occurred during approval",
        "success": "Approved successfully",
        "success-title": "Successful approval"
      },
      "assign": "Allocation",
      "attribute": "Attribute",
      "back": "Back",
      "back-to-top": "Back to top",
      "beginning": "Beginning",
      "clone": {
        "clone": "Clone",
        "error": "Error occurred during duplication!",
        "success": "Succesfull duplication!",
        "title": "The new duplicate record has been created"
      },
      "close": "Close",
      "close-down": "Close",
      "complete": "Complete",
      "completion": "Finish",
      "confirmation": "OK",
      "confirms": "Confirm",
      "continue": "Continue",
      "create-entry": "Create one",
      "creation": "Creation",
      "delete": {
        "cant-delete": "This record cannot be deleted!",
        "cant-delete-constraint": "This record has {relation} relationship with {qty} active elements",
        "cant-delete-constraint-warning": "⚠ Warning! {nrNotDeletable} of the selected {nrOfSelection} items will not be deleted because they are associated with active records",
        "error": "Error occurred during deletion!",
        "success": "Successful deletion!",
        "success-title": "Deleted successfully",
        "system-record": "System record cannot be deleted!"
      },
      "deletion": "Delete",
      "denomination": "Denomination",
      "download": "Download",
      "enabled": "Enabled",
      "error": "Error occurred!",
      "etc": "etc",
      "examples": "Examples",
      "first": "First",
      "generation": {
        "error": "Error generating!"
      },
      "goToDate": "Go to date",
      "here": "here",
      "import": "Import",
      "inactive": "Inactive",
      "inactive-doesnt-show": "It does not appear to be inactive",
      "inactive-show": "It seems inactive",
      "info": "Information",
      "last": "Last",
      "list-refreshed": "The list has been updated!",
      "loading": "Loading...",
      "loading-error": "Error loading!",
      "maintenance": "Maintenance",
      "modification": "Modification",
      "modify": "Modify",
      "move": "Move",
      "name": "Name",
      "new": "New",
      "new-data": "New data",
      "no": "No",
      "no-data-available": "No data available to display.",
      "no-entries": "No data available",
      "open": "Open",
      "over": "Over",
      "pause": "Pause",
      "pc": "{pc} pcs",
      "pdf-generate": "Generate PDF",
      "pdf-generate-error": "Error generating PDF",
      "pdf-generate-success": "The PDF has been generated successfully",
      "reactivate": "Restart",
      "reading": "Reading",
      "regex": {
        "wrong": "The value provided does not match the regex {regex}"
      },
      "run-manually": "Run manually",
      "save": {
        "error": "Error occurred during save",
        "success": "Saved successfully",
        "success-title": "Successful save"
      },
      "save-and-sync": "Save and sync",
      "select": "Select",
      "selection": "Select",
      "show-less": "Show less",
      "show-more": "Show more",
      "since": "Since {date}",
      "skip": "Skip",
      "socket": {
        "id": "Socket identifier",
        "reconnected": "The websocket connection has been restored!"
      },
      "summary": "Summary",
      "to": "to",
      "translation": "Translation",
      "unknown-error": "Unknown error",
      "unsaved-changes": {
        "body": "There are unsaved changes on the form. Are you sure you want to leave the form?",
        "title": "Unsaved changes"
      },
      "upload-error": "Error uploading file {fileName}",
      "upload-image-instruction": "Browse to upload an image. Allowed extensions:",
      "upload-instruction": "rag and drop the file into the frame or click on it and browse for it!",
      "upload-queue": "The file will be loaded after saving the record",
      "warning": "Warning!",
      "yes": "Yes"
    },
    "hr": {
      "description": "Description: ",
      "description-event": "Event description",
      "end": "Event end",
      "event": "Event",
      "event-name": "Event name",
      "justification": "Justification",
      "name": "Name: ",
      "rejection": "Reason of rejection: ",
      "start": "Event start: ",
      "start2": "Event start",
      "type": "Type: ",
      "type2": "Type",
      "urgent": "Urgent: ",
      "user-name": "User: "
    },
    "hrm": {
      "absence": "Absence",
      "accept": "Accept",
      "already-accepted-holiday": "A holiday request has already been accepted for one of the days of the specified period!",
      "createEvent": "Create an eventsssss",
      "days": "Days",
      "denied": "Rejected",
      "example": "Example",
      "few-words": "A few words is enough, but not mandatory...",
      "grounds-refusal": "Reason of rejection",
      "holidayRequestSent": "Leave request sent!",
      "notEnoughHoliday": "You don't have enough time off for the period!",
      "notEnoughHolidayTitle": "Little freedom",
      "ownEvents": "Own events",
      "rejects": "Reject",
      "resource-period": {
        "choose-period": "Choose a capacity period",
        "close-planning": "Closing the planning",
        "close-planning-confirmation": "Are you sure you want to close the planning?",
        "close-planning-succes": "The planning has been completed successfully!",
        "openPlanning": "Open planning",
        "reopen-planning": "Reopen planning",
        "reopen-planning-confirmation": "Are you sure you want to reopen the planning?"
      },
      "resource-plan": {
        "overdue": "Too many hours have been planned for the user.",
        "period-planned-hours": "Planned hours for the period:",
        "tua-planned-hours": "Planned hours for ticket:"
      },
      "select-days": "Select days from the calendar to create an event!",
      "types": "Legend",
      "urgent": "Urgent",
      "vacation": "Vacation"
    },
    "human-resource-management": {
      "main": {
        "remaining-freedoms-colon": "Remaining days off: ",
        "type-designations": "Legend"
      }
    },
    "import-incoming-invoice": {
      "abort-import": "Abort import",
      "add-partner": "Add partner",
      "add-partner-info": "The partner associated with the invoice does not exist in the database.",
      "checklist": {
        "automata": "The invoice was imported via automation.",
        "automata-info": "Complete the missing fields.",
        "invoice": {
          "payment-method": "No payment method was specified for the invoice, Transfer was set as the default."
        },
        "uom": "The unit of measurement in the NAV system is {uom}.",
        "uom-own": "The supplier's own unit of measurement is {uom}.",
        "vat-exemption": {
          "case": "The TAX exemption code is {case}.",
          "reason": "The description of the TAX exemption is {reason}."
        },
        "vat-out-of-scope": {
          "case": "The code for VAT out of scope is {case}.",
          "reason": "The description of VAT out of scope is {reason}"
        }
      },
      "checklist-info": "Not all data could be clearly matched during import. The invoice must be checked. Check the list.",
      "checklist-title": "Checklist",
      "import-invoice": "Import invoices",
      "import-invoice-subtitle": "Import incoming invoices from the NAV system",
      "inDatabase": "In database",
      "invoice-in-db": "The invoice already exists in the database",
      "missing-data": "Missing data",
      "missing-data-info": "Enter not stored data in the NAV system",
      "partner-exists-info": "The partner associated with the invoice already exists in the database.",
      "partner-in-db": "Partner already exists in the database",
      "partner-not-in-db": "Partner does not exist in the database",
      "query": "Query",
      "skip-invoice": "Skip invoice"
    },
    "incident-form": {
      "allocation": "Allocation",
      "attachments": "Attachments",
      "business-impact": "BUSINESS IMPACT",
      "business-service": "Business service",
      "closed-ticket-comment": "Commenting on closed ticket is not allowed!",
      "cofc": "Close on First Call",
      "create-issue": "Create ticket",
      "enter-corn-rule": "Please enter a cron rule!",
      "getting-started": "START",
      "getting-started-lc": "Start",
      "group": "Group",
      "individual": "Person",
      "me": "Me",
      "merged-tickets": "Merged tickets",
      "mode-notification": "Mode of reporting",
      "news": "News",
      "next-step": "Next",
      "operation-service-level": "Contractual service",
      "parent-ticket": "Parent ticket",
      "planned-cost": "Estimated liability",
      "planned-grade": "Estimated role",
      "planned-hours": "Estimated hours",
      "planned-resources": "Estimated resources",
      "planned-resources-error": "Estimated resources could not be determined because the hourly rate could not be found",
      "priority-new-info-1": "Please select urgency and business impact!",
      "priority-new-info-2": "There is no service level associated with the selected urgency and business impact combination!",
      "reaction": "REACTION",
      "reaction-lc": "Reaction",
      "resolved-before-changing-status": "Incident must be assigned to someone before it can be resolved!",
      "service-manager": "Service manager",
      "similar-tickets": "Similar tickets",
      "solution": "SOLUTION",
      "solution-lc": "Solution",
      "status": "Status",
      "subject-notification": "Subject",
      "subject-notification-ci": "Subject of notification CI",
      "subtask": "Subtasks",
      "urgency": "URGENCY",
      "visualization": "Visualization",
      "warning-before-closing-ticket": "Are you sure you want to close the ticket?",
      "work-log-save": "Save worksheet",
      "work-log-save-info": "Worksheet saved successfully"
    },
    "incoming-invoice": {
      "accountNumber": "Invoice number",
      "balance": "Balance",
      "brutto": "Gross",
      "currency": "Currency",
      "dateFulfilled": "Fulfillment",
      "dateIssued": "Issued date",
      "datePaid": "Paid",
      "datePaymentDue": "Payment due date",
      "exchangeRate": "Exchange rate",
      "invoiceNetAmount": "Net amount of the invoice in the invoice currency",
      "invoiceOwner": "Invoice owner",
      "invoiceVatAmount": "VAT amount of the invoice in the invoice currency",
      "modelName": "Incoming invoice",
      "navBrutto": "NAV gross",
      "navDifference": "Rounding difference",
      "navNetto": "NAV net",
      "needsAction": "Approval needed",
      "netto": "Net",
      "partner": "Partner",
      "setExchangeRate": "Apply exchange rate",
      "simplified-invoice": "Simplified invoice",
      "subject": "Subject",
      "supplierName": "Supplier name",
      "supplierTaxNumber": "Supplier tax number",
      "vat": "VAT"
    },
    "intervene": {
      "authorizers": "Authorizer",
      "authorizers-two": "Authorizer(s)",
      "basic-data": "Basic data",
      "billed": "To be billed",
      "contacts": "Contacts",
      "control-subtasks": "Control subtasks",
      "control-task": "Control tasks",
      "cost": "Cost",
      "customer": "Customer",
      "descriptions": "Descriptions",
      "duration": "Duration (hour)",
      "file": "File",
      "imacd": "IMACD reduced by {sum} hours",
      "name": "Name",
      "new-stop": "New stop interval",
      "no-data-available": "No data available",
      "not-billed": "Not to be billed",
      "notifiers": "List of notifiers",
      "purpose": "Purpose of intervention",
      "sla": "Covered by SLA",
      "status": "Status",
      "subtasks": "Subtasks",
      "support-requested": "Requested on-site support",
      "tandm": "T&M based",
      "task-name": "Task name",
      "thread": "Thread",
      "time": "Time of intervention"
    },
    "inventory": {
      "chooseContacts": "Identifier",
      "contact": "Co-worker",
      "createDate": "Date",
      "inventoryContacts": "Colleagues",
      "modelName": "Inventory",
      "partner": "Partner",
      "partnerNames": "Partners / Colleagues",
      "resend": "Resend inventory",
      "status": "Status",
      "stop": "Stopped",
      "valid": "Valid"
    },
    "invoice": {
      "accountNumber": "Invoice Number",
      "balance": "Balance",
      "brutto": "Gross",
      "correcting-invoice": "The modifying account of {accountNumber}",
      "currency": "Currency",
      "dateExchangeRate": "Exchange Rate Date",
      "dateFulfilled": "Fulfillment",
      "dateIssued": "Issued date",
      "datePaymentDue": "Paymenz Due Date",
      "deleting-invoice": "Cancel account of {accountNumber}",
      "editPreInvoice": "Edit prepaid account {id}",
      "endInvoice": "Final bill",
      "info": "",
      "modelName": "Invoice",
      "netto": "Net",
      "paidDays": "Payment",
      "partner": "Partner",
      "preInvoice": "Prepaid account",
      "saveItems": "Saving invoice items",
      "saveItemsError": "Error saving items",
      "sendToNAVError": "Error submitting invoice",
      "sendToNAVSuccess": "The invoice has been successfully submitted to NAV.\nTransaction ID: {response}",
      "sendingToNAV": "Submitting an invoice to NAV",
      "subject": "Subject"
    },
    "invoice-type": {
      "code": "Code",
      "formatCode": "Template",
      "modelName": "Invoice type",
      "name": "Name"
    },
    "labels": {
      "total": "Total"
    },
    "location": {
      "address": "Address",
      "building": "Building",
      "clientPartner": "Partner",
      "entities": "CI elements",
      "floor": "Floor",
      "modelName": "Location",
      "name": "Name"
    },
    "matrix": {
      "first": "First",
      "last": "Last"
    },
    "menus": {
      "account": "Account",
      "add-to-news": "Create news",
      "aiops": {
        "customers": {
          "customers": "Partners",
          "root": "Partners",
          "severance-pay-types": "Penalty types"
        },
        "dashboards": {
          "aiops": "System",
          "competencies": "Competencies",
          "customers": "Partners",
          "root": "Dashboards",
          "solvers": "Solvers",
          "ticket-tracking": "Ticket tracking"
        },
        "data": {
          "api": "APIs",
          "excel": "Excels",
          "interface": "Interfaces",
          "root": "Imports"
        },
        "optimalizations": {
          "optimalization-defaults": "Optimization default values",
          "optimalizations": "Optimization",
          "root": "Optimization"
        },
        "root": "AIOps",
        "source-data": {
          "customers": {
            "contract-types": "Contract types",
            "customers": "Customers",
            "root": "Customers",
            "severance-pay-types": "Penalty types"
          },
          "root": "Source data",
          "solvers": {
            "solvers": "Solvers",
            "user-absences": "Absences",
            "user-grades": "User-grade"
          },
          "tickets": {
            "operation-service-levels": "Services",
            "ticket-status": "Ticket statuses",
            "ticket-types": "Ticket types",
            "tickets": "Tickets"
          }
        }
      },
      "api": "API endpoints",
      "availability-generate": "Generate availabilities",
      "beta": "Béeta",
      "board": "Board",
      "change-log": "Change log",
      "change-requests": "Change request",
      "changes": "Change management",
      "close-report": "Close report",
      "closed-reports": "Closed tickets",
      "cmdb": {
        "dashboard": "Dashboard",
        "entity-types": "CMDB",
        "root": "CMDB"
      },
      "contracted": "Contracted staff",
      "contracts": "Contracts",
      "correcting-invoice": "Correcting invoice",
      "crm": {
        "contacts": "Contacts",
        "departments": "Departments",
        "notification-groups": "Notification groups",
        "operational-file": "Operational file",
        "opportunities": "Opportunities",
        "partners": "Partners",
        "root": "CRM",
        "sites": {
          "buildings": "Buildings",
          "floors": "Floors",
          "locations": "Locations",
          "root": "Sites",
          "site-zones": "Zones"
        }
      },
      "cron-jobs": "Timed jobs",
      "dashboard": "Overview",
      "data": "Data uploads",
      "day-types": "Sun types",
      "delegates": "Delegates",
      "documents": "Documents",
      "edit": "Edit",
      "edit-integration": "Edit integration",
      "edit-mapper": "Edit mapper",
      "edit-preticket": "Edit pre-report",
      "engineer-utility-report": "Engineer utilization report",
      "entities": "Assets",
      "excel": "Excel import",
      "fetchmail": "Fetchmail",
      "finances": {
        "bank-balances": "Bank balance",
        "incoming-invoices": "Incoming invoices",
        "invoices": "Invoices",
        "pipelines": "Pipeline",
        "root": "Finances"
      },
      "forbidden": "Rejected",
      "former-reports": "Former reports",
      "forward-to-accounting": "Forward to accounting",
      "guarantees": "Warranties",
      "human-resource-management": {
        "event-approvement": "Approve holiday",
        "event-manager": "Manage events",
        "my-events": "My holidays, days off",
        "resource-periods": "Capacity plan periods",
        "root": "HRM"
      },
      "impact-study": "Impact study",
      "index": "Dashboard",
      "interface": "Interfaces",
      "inventories": "Inventories",
      "invoice-payments": "Payments",
      "licenses": "License dashboard",
      "list": "List",
      "manage-time-offs": "Manage time offs",
      "moves": "Asset moves",
      "new": "New",
      "new-aiops": "New announcement",
      "new-contact": "New contact",
      "new-entity": "New asset",
      "new-incident": "New report",
      "new-integration": "New integration",
      "new-mapper": "New mapper",
      "new-task": "New project task",
      "news-feed": "News feed",
      "not-found": "The requested page cannot be found",
      "operation": {
        "access-requests": "Access requests",
        "application-to-intervene": "RBK management",
        "change-requests": "Change management",
        "duty": "Hero of the day",
        "duty-calendar": "Calendar",
        "operational-file": "Operational file",
        "portal-dashboard": "Contact",
        "root": "Operation",
        "scheduled-tasks": "Scheduled tasks",
        "sd-dashboard": "Overview"
      },
      "operation-service-level-type": "Type of contracted service",
      "operation-service-level-type-manager": "Contract service maintenance",
      "operations": "Operation",
      "opportunities": "Opportunities",
      "optimization-item-types": "Optimization parameter types",
      "optimization-parameters": "Optimization parameters",
      "organizations": {
        "root": "Sites",
        "sites": {
          "buildings": "Buildings",
          "floors": "Floors",
          "locations": "Locations",
          "root": "Sites"
        }
      },
      "other-information": "Other information about the reporter",
      "overview": "Overview",
      "parameter-categories": "Parameter categories",
      "parameter-types": "Parameter types",
      "partner-bankcodes": "Bank accounts",
      "partner-sectors": "Sectors",
      "pipelines": "Pipeline",
      "plans": "My plans",
      "pre-reports": "Pre-reports",
      "procedures": "Procedures",
      "processed-reports": "Tickets in progress",
      "projects": {
        "files": "Files",
        "root": "Project monitoring",
        "statements": "Devices"
      },
      "prompt": "Payment prompt",
      "remote-access-informations": "Remote access and permissions",
      "report": "Reports",
      "report-as-another-user": "Report as another user",
      "report-submit": "New ticket",
      "reports": {
        "inventories": "Inventories",
        "root": "Reports"
      },
      "sectors": "Sectors",
      "settings": {
        "acl": {
          "acl": "Rights",
          "actions": "Actions",
          "finance-actions": "Financial permissions",
          "menus": "Menus",
          "roles": "Roles",
          "root": "Permissions",
          "users": "Users"
        },
        "aiops": {
          "day-types": "Day types",
          "optimization-item-types": "Optimization parameter types",
          "optimization-parameters": "Optimization parameters",
          "parameter-categories": "Parameter categories",
          "parameter-types": "Parameter types",
          "partner-types": "Partner types",
          "partners": "Partners",
          "root": "AIOps settings"
        },
        "cmdb": {
          "code-stores": "Dictionaries",
          "contact-types": "Contact types",
          "entity-categories": "CI categories",
          "entity-statuses": "Entity statuses",
          "entity-types": "Dashboard",
          "manufacturers": "Manufacturers",
          "root": "CMDB",
          "types": "Asset types"
        },
        "cron-jobs": "Scheduled jobs",
        "date-type-definitions": "Date type definitions",
        "date-types": "Day types",
        "document-types": "Document types",
        "finances": {
          "grade-costs": "Hourly rates",
          "invoice-categories": "Invoice categories",
          "invoice-types": "Invoice types",
          "nav-settings": "NAV settings",
          "payment-methods": "Payment methods",
          "payments": "Currency settings",
          "root": "Finances",
          "tax-number-patterns": "Tax number patterns",
          "uoms": "Units of measurement",
          "vats": "VAT settings"
        },
        "integrations": {
          "mappers": "Mappers",
          "root": "Integrations",
          "services": "Integrations"
        },
        "invoices": {},
        "operations": {
          "fetchmail": "Fetchmail",
          "operation-service-level-type": "Contractual service types",
          "priority": "Priority",
          "priority-importance": "Business impact",
          "priority-matrix": "Priority matrix",
          "priority-urgency": "Urgencies",
          "repair-claims": "Repair claims",
          "report-types": "Report types",
          "resource-groups": "Capacity groups",
          "resource-plan-priorities": "Plan priorities",
          "root": "Operation",
          "service-areas": "Service areas",
          "service-levels": "Service levels",
          "service-periods": "Service periods",
          "solving-group": "Solving groups",
          "statuses": "Statuses",
          "ticket-audit-list": "Ticket audit list"
        },
        "partners": {
          "countries": "Countries",
          "partner-sectors": "Sectors",
          "root": "Partners"
        },
        "project": {
          "file-business-types": "Business types",
          "file-states": "File statuses",
          "file-types": "File types",
          "product-result-templates": "Pre-defined result products",
          "profit-centers": "Profit centers",
          "project-ticket-template-groups": "Pre-defined task groups",
          "project-ticket-templates": "Pre-defined tasks",
          "root": "Files"
        },
        "regions": "Regions",
        "reports": "Report",
        "root": "Settings"
      },
      "settings-entity-types": "CI types",
      "solved-reports": "Solved tickets",
      "srepo": {
        "root": "SWRepo"
      },
      "statements": "Statements",
      "storno": "Storno invoice",
      "supports": "Supports",
      "tasks": {
        "availabilities": "Availabilities",
        "goals-fulfillment": "My goals fulfillment",
        "root": "My tasks",
        "tasks": "My tasks",
        "work-logs": "My worksheets"
      },
      "tasks-and-reports": "Tasks, Reports",
      "ticket": "Incident",
      "ticket-states": "Statuses",
      "tickets": "Ticket details",
      "time-off": "Time offs",
      "user-grades": "Job positions",
      "view-pdf": "View PDF",
      "visualisation": "Visualization",
      "warranties": "Warranties",
      "wiki": {
        "root": "Knowledge base"
      }
    },
    "modals": {
      "clone-confirmation": {
        "error": {
          "info": "Error duplicating",
          "title": "An error occurred"
        },
        "info": "Are you sure you want to duplicate the selected record?",
        "success": {
          "info": "The new record was created!",
          "title": "Successful duplication!"
        },
        "title": "Duplicate record"
      },
      "delete-confirmation": "Are you sure you want to delete?",
      "file-upload": "Upload file"
    },
    "models": {
      "accountManagerUser": "Account Manager",
      "allSpentTime": "All spent time",
      "alreadyAssignedTimes": "Number of allocations",
      "amount": "Amount",
      "amountUntaxed": "Amount without VAT",
      "approvalDate": "Approval date",
      "approximatelyValue": "Approximate value",
      "billable": "Billable",
      "bulk-delete-confirmation": "Are you ure you want to continue? (Only the tickets without worklogs will be deleted!)",
      "bulk-delete-response": "From the seleceted {all} tickets {deleted} were deleted. {skipped} has worklogs.",
      "businessService": "Business service",
      "calendar": "Calendar",
      "callAfterBaseHours": "Call after base hours",
      "changeRequest": {
        "category": "CI category",
        "email": "E-mail",
        "end": "It's over",
        "entityTypeCategory": "Type CI",
        "entityTypeCategoryName": "CI type name",
        "name": "Name",
        "partner": "Partner",
        "partnerName": "Partner name",
        "respond": "Feedback",
        "solver": "Solver",
        "source": "Source",
        "start": "Beginning",
        "ticket": "Ticket"
      },
      "checklist": "Checklist",
      "clientPartner": "Partner",
      "closed": "Closed",
      "code": "Code",
      "completionCertSigner": "CoP signer (Partner)",
      "completionLevel": "Goal accomplished",
      "conditions": "Conditions",
      "contactDescription": "Contact description",
      "contactId": "Contact",
      "continuous": "Continuous",
      "contract": "Contract",
      "cost": "Cost",
      "crVersion": "VK version",
      "createDate": "Create date",
      "createUid": "Creator identifier",
      "createuser": "Option recorder",
      "currencyId": "Currency ID",
      "currencyPayment": "Currency",
      "customer": "Customer",
      "dateCreate": "Created",
      "dateEnd": "End",
      "dateEndType": "Contract end date",
      "dateStart": "Start",
      "defaultAmount": "Amount (base currency)",
      "deletedAt": "Deleted",
      "description": "Description",
      "endDate": "Validity end date",
      "evaluationNegative": "What needs to be done differently",
      "evaluationPositive": "What went well",
      "event": {
        "modelName": "Holiday"
      },
      "expiredDays": "Days of expired invoice",
      "file": "File",
      "fileId": "File ID",
      "fileNumber": "File number",
      "fileNumberIndex": "FileNumberIndex",
      "fileState": "Project status",
      "fileType": "Project type",
      "fromDate": "Validity start date",
      "hardwareDeliveryInMoney": "Hardware volume",
      "impactOfChange": "Impact of change",
      "invoiceOwner": "Supplier",
      "invoicePayment": "Type",
      "isActive": "Active",
      "isactive": "Enabled",
      "lastTicketStateChange": "Last ticket state change",
      "lotusCode": "Lotus code",
      "managerUser": "Manager",
      "manufacturer": "Manufacturer",
      "maxHoursPerWorkLog": "Max hours per worksheet",
      "mergedParent": "Parent of merged ticket",
      "name": "Name",
      "newCustomer": "New customer",
      "operationServiceLevel": "Contracted Service",
      "operationalFile": {
        "accountable": "Accountable",
        "closed": "Closed",
        "endDate": "End date",
        "fileBusinessType": "Industry",
        "fileOwnerPartner": "File owner company",
        "manager": "Manager",
        "name": "File name",
        "partner": "Partner",
        "primeContractor": "Customer",
        "publicExternal": "Public (external)",
        "publicInternal": "Public (Internal)",
        "smtm": "SZM/TAM",
        "sponsor": "Sponsor",
        "status": "Status",
        "subcontractor": "Subcontracting",
        "type": "Type",
        "volumen": "Volume (thousand)"
      },
      "operationalManagerUser": "Operational Manager",
      "orderIndex": "Order",
      "outgoing-email": "Outgoing e-mail",
      "ownerUser": "Owner",
      "paid": "Paid",
      "parentId": "Partner",
      "partner": "Partner",
      "partnerId": "Partner ID",
      "partnerSector": "Sector",
      "partnerSponsor": "Partner sponsor",
      "paymentDisciplineNegative": "Negative payment discipline",
      "paymentDisciplineNegativeDays": "Negative payment discipline days",
      "paymentDisciplineNegativeOne": "Negative payment discipline",
      "paymentDisciplinePositive": "Positive payment discipline",
      "paymentDisciplinePositiveDays": "Positive payment discipline days",
      "paymentDisciplinePositiveOne": "Positive payment discipline",
      "paymentdays": "Payment days",
      "periodicalSettlement": "Periodical invoicing",
      "planPeriodCloseDate": "Plan period end date",
      "plannedEndDate": "Planned end date",
      "plannedExpensesInHour": "Allocated hours",
      "plannedStartDate": "Planned start date",
      "plannedUserGrade": "Role",
      "plannedUserGradeId": "Role ID",
      "plannedValue": "Planned value",
      "primeContractor": "Customer",
      "priority": "Priority",
      "probability": "Probability",
      "productResult": "Result product",
      "productResultId": "Result product ID",
      "project-name": "Project name",
      "projectValue": "Estimated income",
      "publicId": "Contract ID",
      "reactionDeadline": "Reaction deadline",
      "refOfferId": "Reference offer",
      "referenceTicketId": "Related task",
      "region": "Region",
      "regionId": "Region ID",
      "remoteAccess": "Remote access",
      "reportCloseMotivation": "Close motivation",
      "reportCloseProposal": "Close incoming offer",
      "reportSubmit": "New ticket",
      "reportTypes": "Report type",
      "resolver": "Solver",
      "resolverId": "Solver",
      "serviceAreaId": "Service area",
      "serviceDeliveryInMoney": "Service volume",
      "serviceLevel": "Service level",
      "serviceRating": "Service rating",
      "shortName": "Short name",
      "shortNametwo": "Shortname",
      "solveDeadline": "Solve deadline",
      "solvingGroup": "Solving group",
      "solvingGroupId": "Solving group",
      "sponsor": "Sponsor",
      "sponsorUser": "Sponsor",
      "startDeadline": "Start deadline",
      "state": "State",
      "stateChange": "Last status change",
      "subcontractor": "Subcontractor",
      "subject": "Subject",
      "subsidiaries": "Subsidiaries",
      "successful": "Successful",
      "sumPublicSpentTime": "Total public spent time",
      "sumSpentTime": "Total spent time",
      "supplier": "Supplier",
      "supplierPartnerId": "Supporting partner ID",
      "tax": "Tax type",
      "taxEu": "EU tax type",
      "taxnumber": "Tax number",
      "technicalAccountManagerUser": "Technical Account Manager",
      "ticketNumber": "Ticket number",
      "ticketNumberIndex": "Ticket number index",
      "ticketSourceId": "Ticket source ID",
      "ticketStateId": "Ticket status ID",
      "ticketStatusId": "Status",
      "ticketTypeId": "Type ID",
      "type": "Type",
      "unsaved-changes": "Attention! There are unsaved changes!",
      "userGradeId": "Hourly rate ID",
      "valid": "Valid",
      "valuePayment": "Value currency",
      "valuePeriod": "Value period",
      "vatId": "VAT ID",
      "violationReaction": "Reaction time",
      "violationSolve": "Solve time",
      "violationStart": "Start time",
      "warning": "Is goal in danger?",
      "workLogNotification": "Email notification",
      "workplaceAddressId": "Workplace address ID"
    },
    "my-ticket": {
      "alreadyAssignedTime": "Already assigned hours",
      "clientPartner": "Partner",
      "contact": "Contact",
      "fileName": "File",
      "manager": "Manager",
      "modelName": "My Tickets",
      "operationServiceLevel": "Operation service",
      "plannedEndDate": "Deadline",
      "plannedExpensesInHour": "Planned hours",
      "primeContractor": "Customer",
      "priority": "Priority",
      "productResult": "Product result",
      "serviceLevel": "Service level",
      "solver": "Solver",
      "state": "State",
      "subject": "Subject",
      "ticketNumber": "Ticket number",
      "type": "Type"
    },
    "nav-setting": {
      "demo": "Test environment",
      "exchangeKey": "XML exchange key",
      "import": "Automatic import",
      "login": "NAV technical username",
      "modelName": "NAV settings",
      "partner": "Partner",
      "pass": "Technical user password",
      "signatureKey": "XML signature key",
      "taxNumber": "Tax number"
    },
    "news": {
      "contents": "Content",
      "createDate": "Creation time",
      "developerNews": "Development log update",
      "developerNewsContents": "Development log updated, click title for details.",
      "lastUpdate": "Last modification time",
      "subject": "Topic"
    },
    "notification-group": {
      "contact": "Contact",
      "inside_the_zone": "Notification inside the zone",
      "modelName": "Notification group",
      "name": "Name",
      "notificationGroupType": "Notification type",
      "partner": "Partner"
    },
    "operation": {
      "access-request": {
        "new-request": "New request"
      },
      "change-request": {
        "accepted": "Accepted",
        "add-ci-elem": "Add CI element",
        "add-person": "Add person",
        "add-task": "Add task",
        "approve-request": "Finalize",
        "approver": "Approving",
        "back": "Back to list",
        "canClose": "If you wish, you can close the change management",
        "change-manager": "Change manager",
        "changeProcess": "Process of change",
        "changeSuccess": "Successful change!",
        "check-task": "Verification task",
        "clone": "Duplicate change request",
        "clone-info": "Are you sure you want to duplicate the change request?",
        "close-change-request": "Close change request",
        "close-change-request-confirm": "Are you sure you want to close the change request?",
        "closeSuccessfull": "Successful closing!",
        "contact": "To be notified",
        "crApproved": "Successful completion!",
        "crCloseError": "Error closing <b>{changeRequest}</b> change request!",
        "crCloseSuccess": "The <b>{changeRequest}</b> change request has been closed!",
        "crSaved": "Change request saved",
        "declined": "The change request has already been declined",
        "edit-task": "Edit task",
        "emailsSent": "Emails have been successfully sent!",
        "errorInRequest": "Error while querying the affected elements!",
        "evaluation": "Evaluation",
        "feedback-processing": "Please be patient, feedback is being processed",
        "file": "File",
        "from": "Beginning",
        "fromPartner": "From partner",
        "fromProvider": "From the service provider",
        "fromThirdParty": "Third party",
        "generated-incidents": "Generated incidents",
        "handle": "Handling method",
        "id": "ID",
        "impact": "Impact",
        "mainData": "Basic data",
        "missing-service-levels": "Attention! There is no Change service level associated with the specified business service! Please contact the service manager!",
        "name": "Name",
        "new": "New change request",
        "new-cr-title": "The new change request has been created!",
        "noAnswer": "No answer",
        "only-draft-can-be-deleted": "Can only be deleted in Draft state!",
        "operationServiceLevel": "Business service",
        "person": "Person",
        "personAdded": "The person has been successfully added!",
        "personalDependencies": "Personal conditions",
        "persons-approve": "Approvers",
        "persons-notify": "Persons to notify",
        "please-choose-a-person": "Please select a person",
        "please-choose-a-resolver": "Please select a solver",
        "priority": "Priority",
        "priorityName": "Priority",
        "rejected": "Denied",
        "risk-default": "Risks of intervention failure",
        "risk-infra": "Risks affecting related systems",
        "risk-intervention": "Risks of intervention",
        "risks": "Risks",
        "select-ci-elem": "Select CI element",
        "select-ci-elem-help": "Click on the row to select/delete the element",
        "source": "Source",
        "state": "Status",
        "successful": "Successful",
        "to": "Completion",
        "token-valid": "Approval deadline"
      },
      "choose-main-announcement": "Select the main announcement",
      "closing": "Closing",
      "currently-no-announcements": "Currently, there are no announcements",
      "currently-no-pre-announcements": "Currently, there are no announcements",
      "currently-no-solution": "Currently, there are no tickets with an ongoing solution",
      "main": {
        "all-subtasks": "All subtasks",
        "appendix": "Appendix",
        "assigned-tasks": "Allocated tasks",
        "auto-monitoring": "Auto-monitoring",
        "change-priority": "Change priority",
        "change-priority-text": "Attention! Changing priority will result in recalculating SL times. If you tighten the priority, the calculation will start again. Are you sure you want to continue?",
        "contract-is-expired": "Contract has expired",
        "contract-is-valid": "Contract is valid",
        "control-subtasks": "Control subtasks",
        "critical-annoucements": "Critical notifications",
        "desk-statistic": "Desk statistics",
        "feedbacked": "Feedbacked",
        "new-rbk": "New RBK",
        "not-subcontracting": "Not subcontracting",
        "notes": "Notes",
        "number": "{number}",
        "own-tasks": "Own tasks",
        "ppriority": "P{priority}",
        "pre-notification-statistic": "Pre-notification statistics",
        "priority": "P{priority}",
        "sl-time-badge": {
          "tooltip": "{datetime}",
          "tooltip-in-24-hours": "{datetime} - within 24 hours!",
          "tooltip-violated": "{datetime} - Violated!",
          "tooltip-violated-label": "Violated!"
        },
        "sla-updated": "SLA updated",
        "subcontracting": "Subcontracting",
        "subtasks": "Subtasks",
        "suspend": "Suspended",
        "task-notification-satistics": "Task notification statistics",
        "web-form": "Web-form"
      },
      "no-pre-announcements": "Currently, there are no pre-announcements",
      "no-tickets": "Currently, there are no closed tickets",
      "notification-merger": "Merge tickets",
      "notification-merger-info": "The main ticket will include the other tickets. If there are any sub-tickets among the other tickets that were previously main tickets, their sub-tickets will also be reclassified to the main ticket.",
      "notification-merger-success": "Notification merger completed successfully",
      "operational-file": {
        "add-service-contract": "Add service contract",
        "edit-service-contract": "Edit service contract",
        "its-edit": " edit",
        "new-day-type": "New day type",
        "new-service-contract": "New service contract",
        "new-service-time": "New service time"
      },
      "portal-dashboard": {
        "new-notification": "New notification",
        "view": "View"
      },
      "reason-suspension": "Reason for suspension",
      "reason-suspension-removal": "Reason for suspension removal",
      "report-submit": {
        "create-notification": "Edit notification",
        "edit-shedule": "Edit schedule",
        "fill-reason": "Please fill out the reason!",
        "new-schedule": "New schedule",
        "new-subtask": "New subtask",
        "new-worksheet": "New worksheet",
        "remote-access-notific": "Remote accesses and accesses for notification",
        "subtaskAdded": "Subtask added!"
      },
      "restoration": "Restore tickets",
      "restoration-success": "Restoration completed successfully",
      "restore-selected-notifications": "Are you sure you want to restore the selected notifications?",
      "scheduled-tasks": {
        "choose-main-announcement": "Select the main announcement",
        "generate": "Generate task",
        "generate-error": "Error occurred while generating the task!",
        "generate-info": "Are you sure you want to generate the task?",
        "generate-success": "The task was generated successfully",
        "generate-success-info": "The new task has been created with ID {id} !",
        "new": "New scheduled task"
      },
      "sd-dashboard": {
        "customView": "Unique view",
        "liveOff": "Live broadcast off",
        "liveOn": "Live broadcast",
        "mergeTicketsError": "Select at least two items to merge",
        "mergeTicketsErrorUnique": "The selected items' contacts belong to different partners, therefor they can not be merged!",
        "preTicket": "Advance notice",
        "preTickets": "Advance notices",
        "preTicketsWithNumber": "Pre-Tickets ({numberOfPreTickets})",
        "repair-ticket": "Service tickets",
        "settings-load-error": "The logged-in user has multiple socket-io entries"
      },
      "service-period": {
        "add": "Add",
        "change-type-alert": "Attention! For change requests generated from tickets, 24/7 service period will be applied.",
        "name": "Name",
        "office-time": "Time intervals",
        "remove": "Remove",
        "service-periods": "Service periods"
      },
      "solve": "Solve",
      "solve-change": "Mark as solved",
      "solved": "Solved",
      "solved2": "solved",
      "sure": "Are you sure",
      "want-to-set": "Do you want to set the status to?",
      "worksheets": "Worksheets"
    },
    "operation-service-level": {
      "businessService": "Business Service",
      "imac": "IMAC",
      "imacLimit": "IMAC limit",
      "modelName": "Contracted Service",
      "name": "Name",
      "operationServiceLevelType": "Type of contracted service",
      "slSuccessMinimum": "Sl Success Minimum",
      "solvingGroup": "Solving group"
    },
    "operation-service-level-type": {
      "modelName": "Type of contracted service",
      "name": "Designation"
    },
    "operational-file": {
      "modelName": "Operational file",
      "name": "Service name"
    },
    "opportunities": {
      "executed": "Executed",
      "new-file": "New file",
      "offer": "Offer",
      "ordered": "Ordered",
      "paid": "Paid, closed",
      "possibility": "Possibility"
    },
    "organizations": {
      "main": {
        "new-coworker": "New Contact",
        "new-partner": "New partner",
        "partners-tools": "Partners / Tools"
      },
      "partners": {
        "contact-departments-warning": "⚠️  <i><b>Warning!</b> Only saved partners/organizational units can be selected when creating a new organizational unit!</i>",
        "edit-organization": "Edit organization"
      },
      "sites": {
        "add-new-zone": "Add new zone",
        "edit-building": "Edit building",
        "edit-level": "Edit level",
        "edit-location": "Edit location",
        "edit-zone": "Edit zone",
        "new-building": "New building",
        "new-level": "New level",
        "new-location": "New location"
      }
    },
    "pagination": {
      "next": "Next",
      "prev": "Previous"
    },
    "partner": {
      "accountManagerUser": "Customer account manager",
      "approved": "Approved",
      "approximatelyValue": "Approximate value",
      "callAfterBaseHours": "Call After Base Hours",
      "checklist": "Checklist",
      "children": "Subsidiaries",
      "code": "Code",
      "conditions": "Conditions",
      "contactDescription": "Contact Description",
      "contactId": "Contact",
      "currencyPayment": "Currency",
      "customer": "Buyer",
      "dateEndType": "Contract expiration date",
      "deletedAt": "Deleted",
      "domesticPartner": "Domestic/Foreign",
      "email": "Email",
      "expiredDays": "Late payment days",
      "financeContactEmail": "Financial contact",
      "impactOfChange": "Impact of Change",
      "invoicePayment": "Payment method",
      "isActive": "Active",
      "isactive": "Authorised",
      "kam": "Customer Representative",
      "lotusCode": "Lotus code",
      "maxHoursPerWorkLog": "Max. Hours per Worksheet",
      "modelName": "Partner",
      "multiOwnerPartner": "Own company",
      "name": "Name",
      "notPaidInvoicesTotal": "Open invoices (thousand)",
      "operationalManagerUser": "Operational account manager",
      "parent": "Subsidiary",
      "parentId": "Partner",
      "partner": "Customer",
      "partnerSector": "Sector",
      "partnerTypes": "Types",
      "paymentDays": "Payment Days",
      "paymentDisciplineNegative": "Negative payment discipline",
      "paymentDisciplineNegativeDays": "Payment discipline negative days",
      "paymentDisciplineNegativeOne": "Payment discipline negative one",
      "paymentDisciplinePositive": "Positive payment discipline",
      "paymentDisciplinePositiveDays": "Payment discipline positive days",
      "paymentDisciplinePositiveOne": "Payment discipline positive one",
      "paymentdays": "Payment days",
      "paymentmethod": "Payment Method",
      "periodicalSettlement": "Periodical invoicing",
      "plannedValue": "Planned value",
      "potential": "Rolling potential (thousand)",
      "publicId": "Contract identifier",
      "refOfferId": "Reference offer",
      "referenceTicketId": "Related task",
      "remoteAccess": "Remote Access",
      "reportCloseMotivation": "Close motivation",
      "reportCloseProposal": "Close incoming proposal",
      "reportSubmit": "New ticket",
      "reportTypes": "Report type",
      "serviceAreaId": "Service area",
      "serviceRating": "Service rating",
      "shortName": "Short name",
      "shortNametwo": "Shortname",
      "solvingGroupId": "Solving group",
      "state-market": "State/Market",
      "subsidiaries": "Subsidiaries",
      "tax": "VAT number / VAT ID",
      "taxEu": "EU VAT number",
      "taxGroup": "Group VAT number",
      "technicalAccountManagerUser": "Technical account manager",
      "ticketStatusId": "Status",
      "valuePayment": "Value currency",
      "valuePeriod": "Value period",
      "volumen": "Total volume to date (thousand)",
      "yearlyPotential": "Annual potential (thousand)"
    },
    "partner-bankcode": {
      "bankcode": "Bank Account Number",
      "bankname": "Bank Name",
      "clientPartner": "Partner",
      "currencyPayment": "Currency",
      "defaultBank": "Default",
      "iban": "IBAN",
      "modelName": "Bank Account",
      "swift": "SWIFT"
    },
    "partner-sector": {
      "modelName": "Sector",
      "name": "Name",
      "polity": "State"
    },
    "payment": {
      "accountCredit": "General Ledger Account Number: Payable",
      "accountDebit": "General Ledger Account Number: Receivable",
      "cashbookId": "Cashbook ID",
      "code": "Currency Code",
      "exchangeRate": "Exchange Rate",
      "exchangeRound": "Number of Decimal Places in Exchange Rate",
      "exchangeUnit": "Exchange Rate Unit",
      "modelName": "Currency Setting",
      "name": "Currency Name",
      "round": "Rounding rate",
      "roundType": "Rounding type",
      "systemFlag": "Main Payment Method",
      "type": "Payment Type"
    },
    "paymentTypes": {
      "bankCard": "Credit card",
      "billing": "Invoicing",
      "cash": "Cash",
      "coupon": "Coupon",
      "fulfilBilling": "Account financial performance",
      "loss": "Loss",
      "other": "Other"
    },
    "pick-list": {
      "selected-element": "Selected {length} Selected"
    },
    "preInvoiceGenerationSuccess": "The prepaid account was generated with the name {subject} and ID {id}",
    "priority": {
      "critical": "Critical",
      "high": "High",
      "low": "Low",
      "modelName": "Priority",
      "name": "Name",
      "normal": "Normal",
      "value": "Value",
      "veryLow": "Very low"
    },
    "priority-importance": {
      "modelName": "Business impact",
      "name": "Name",
      "value": "Value"
    },
    "priority-matrix": {
      "modelName": "Priority matrix",
      "name": "Name",
      "priority": "Priority",
      "priorityImportance": "Business impact",
      "priorityUrgency": "Urgency"
    },
    "priority-urgency": {
      "modelName": "Urgency",
      "name": "Name",
      "value": "Value"
    },
    "product-result-template": {
      "active": "Active",
      "billable": "Billable",
      "description": "Description",
      "modelName": "Predefined result product",
      "name": "Name"
    },
    "profit-center": {
      "code": "Code",
      "modelName": "Profit center",
      "name": "Name"
    },
    "project": {
      "accountable": "Accountable",
      "clientPartner": "Partner",
      "closed": "Close file",
      "comment": "Comment",
      "commission": "Commission",
      "completionLevel": "Target",
      "createNewProductResult": "Create a new result product",
      "dateEnd": "End date",
      "dateStart": "Start date",
      "fileNumber": "File number",
      "fileOwnerPartner": "Owner of the file",
      "fileOwnerPartnerCompany": "File owner",
      "filePlanners": "Planners",
      "fileSolverGroups": "Solver groups",
      "fileSolvers": "Solvers",
      "fileState": "Status",
      "fileSuppliers": "Suppliers",
      "import": {
        "error": "An error occurred while importing items.\nPlease try again later.",
        "success": "Items have been imported."
      },
      "investmentPlanned": "Plan",
      "managerUser": "Manager",
      "maxHoursPerWorkLog": "Max hours per worksheet (hour)",
      "modelName": "File",
      "name": "Project name",
      "noEndDate": "-",
      "noStartDate": "-",
      "onBudgetInHour": "Kk(ó)",
      "onBudgetInHourPerOnGoal": "Kk(ó)/C",
      "onBudgetInValue": "Ck(s)",
      "onBudgetInValuePerOnGoal": "Kk(é)/C",
      "onTimePerOnGoal": "Time/Goal",
      "onTimePercent": "Time (%)",
      "opportunitites": "File name",
      "orderNumber": "Order number",
      "overHead": "Overhead",
      "overwriteProductResult": "Create a new result product",
      "plannedBudgetMoneyThousand": "Weighted revenue (thousand)",
      "primeContractor": "Client",
      "projectTicketTemplateGroupId": "Predefined tasks",
      "projectValue": "Estimated revenue",
      "projectValueThousand": "Estimated revenue (thousand)",
      "providerCompletionCertSigner": "CoP signer (Provider)",
      "publicExternal": "Public (external)",
      "publicInternal": "Public (internal)",
      "registrationDayLimit": "Worksheet registration (day)",
      "report": "Report",
      "salesManager": "Sales Manager",
      "serviceDesk": "Service Desk",
      "sponsorUser": "Sponsor",
      "subcontractor": "Subcontractor",
      "tariff": "Tariff",
      "technicalManagerUser": "Technical Client Manager",
      "tickets": "Tickets",
      "workLogs": "Worklogs"
    },
    "project-ticket": {
      "generate-project-tickets": "Load template tasks",
      "info": "Attention! Based on the template, {ticketCount} tasks will be created automatically!"
    },
    "project-ticket-template": {
      "active": "Active",
      "description": "Description",
      "emailNotification": "Email notification",
      "maxHoursPerWorkLog": "Maximum hours per worksheet",
      "modelName": "Predefined task",
      "name": "Name",
      "plannedEndDate": "Planned end date",
      "plannedExpensesInHour": "Planned expenses in hours",
      "plannedUserGrade": "Role",
      "priority": "Priority",
      "productResultTemplate": "Result product",
      "projectTicketTemplateGroup": "Group",
      "subject": "Subject"
    },
    "project-ticket-template-group": {
      "active": "Active",
      "description": "Description",
      "modelName": "Predefined task group",
      "name": "Name",
      "operational": "Operational file",
      "operationalOpportunity": "Operational file opportunity",
      "project": "File",
      "projectOpportunity": "File opportunity"
    },
    "projects": {
      "actual-cost": "Actual cost",
      "categorize-file": "Categorize file",
      "change-request": "Change request",
      "change-request-started": "Change request is started for the file!",
      "change-story": "Change story",
      "changed-cost": "Changed cost",
      "collapse": "Collapse",
      "completion-certificate-generate-error": "Error while generating!",
      "completion-certificate-insure": "As the person authorized to accept the performance of the project named {name}, I certify that the Customer accepts the performed works, services and tools and considers them to be fulfilled according to the contract.\nIn the future, there are no objections regarding the performance.",
      "completion-certificate-sum": "The Service Provider is entitled to issue the invoice based on the items detailed below.",
      "completion-certificate-warning": "A certificate of completion and a pre-invoice already belong to this quotation. If you want to generate a new one, the previous ones will be deleted. Are you sure you want to continue?",
      "expand": "Expand",
      "files": {
        "accept-change-request": "Accept change request",
        "accountable-tooltip": "The system automatically sends a cost report via email for accountable files.",
        "activities": "Activities",
        "closed-and-overbooked": "Closed and overbooked",
        "closed-on-time": "Closed on time",
        "contact-departments-warning": "⚠️  <i><b>Attention!</b> Based on the template, <b>{ticketCount}</b> tasks will be automatically created!</i>",
        "evaluation": "Evaluation",
        "execution": "Execution",
        "expired": "Exprired",
        "file-state-change-info": "If you set the status option to \"Ordered\", the file will be listed under the \"Project Monitoring/Files\" menu after saving!",
        "hours": "Hours",
        "hours-active": "Active hours",
        "hours-allocated": "Allocated hours",
        "hours-inactive": "Inactive hours",
        "hours-overbooked": "Overbooked hours",
        "incident-worksheets": "Incident worksheets",
        "kk-goal": "KK(y) / Year",
        "no-billing-address": "Attention! The selected customer for the file does not have a billing address specified.",
        "no-billing-address-title": "Billing address not specified",
        "no-completion-cert-signer": "Attention! No performance certificate signer is selected on the File tab. Please select a signer.",
        "number-of-tickets": "Number of tickets",
        "overbooked-hh-pc": "Overbooked (hh/pc)",
        "overbooked-tasks": "Overbooked tasks",
        "partner-data": "Partner data",
        "print-performance-certificate": "Print performance certificate",
        "progres-and-in-time": "In progress and on time",
        "progres-and-overbooked": "In progress and overbooked",
        "public-external-tooltip": "All portal users can register for a public operation file.",
        "public-internal-tooltip": "All internal users can register for a public operation file.",
        "rejection-change-request": "Reject change request",
        "restores": "Restore",
        "satisfaction": "Satisfaction",
        "sliding-result-products": "Sliding result product",
        "tabs": {
          "acl": "Permissions",
          "attachments": "Attachments",
          "balance": "Balance",
          "calendar": "Calendar",
          "competencies": "Roles",
          "contract": "Contract services",
          "day-types": "Day types",
          "file": "File",
          "finance": "Finance",
          "finance-data": "Finance data",
          "invoices": "Invoices",
          "items": "Items",
          "main-data": "Basic data",
          "mainTickets": "Tickets",
          "others": "Other",
          "outgoing-proposals": "Outgoing quotation",
          "partner-data": "Partner data",
          "product-results": "Result products",
          "proposals": "Incoming quotations",
          "service-periods": "Service periods",
          "settings": "Settings",
          "sla": "Contract",
          "tandm": "T&M",
          "ticket": "Notifications",
          "tickets": "Tickets",
          "user-grade": "Hourly rates",
          "work-logs": "Work logs"
        },
        "task": "Task",
        "tasks-overbooked": "Overbooked tasks' number",
        "time-goal": "Time / Goal",
        "user": "User",
        "user-data": "User data"
      },
      "main": {
        "contract-in-order": "Contract is OK",
        "copy-file-data-to-clipboard": "Copy file data to clipboard",
        "incoming-offer": "Incoming offer",
        "incoming-offer-ok": "Incoming offer is OK",
        "incoming-offer-required": "Incoming offer is required",
        "live": "LIVE",
        "order": "Order",
        "order-in-order": "Order is OK",
        "order-in-order-two": "Order is OK",
        "order-is-required": "Order is required",
        "outgoing-offer": "Outgoing offer",
        "outgoing-offer-ok": "Outgoing offer is OK",
        "outgoing-offer-required": "Outgoing offer is required",
        "planning": "Planning",
        "planning-ok": "Planning is OK",
        "planning-required": "Planning is required",
        "vk": "VK"
      },
      "main-data": "Basic data",
      "missingProposal": "There are no valid outgoing offers.",
      "original-cost": "Original cost",
      "planned-cost": "Planned cost",
      "please-select-user": "Please select an user!",
      "product": "Product",
      "project-tickets": {
        "generate-fail": "Error generating tasks",
        "generate-success": "Tasks generated successfully"
      },
      "result-products": "Result products",
      "tickets": {
        "closed-task": "Closed tasks",
        "completion-certificate": "Completion certificate",
        "confirmation": "Confirmation",
        "hours-cannot-be-allocated": "Cannot allocate 0 hour",
        "open-task": "Open tasks",
        "result-products": "Result products",
        "result-products-managment": "Manage result products",
        "tasks": "Tasks"
      }
    },
    "push-notification": {
      "messageId": "Firebase message ID",
      "reaction": "Reaction to the push notification"
    },
    "region": {
      "code": "Code",
      "fileCodeFormat": "File code format",
      "files": "File",
      "modelName": "Region",
      "name": "Name",
      "ownerPartner": "Partner",
      "regionalSettings": "Regional settings",
      "ticketCodeFormat": "Ticket code format",
      "workLogFormat": "Worksheet format"
    },
    "regional-setting": {
      "description": "Description",
      "modelName": "Regional settings",
      "partner": "Partner",
      "settingKey": "Key",
      "settingValue": "Value"
    },
    "repair-claim": {
      "modelName": "Repair claim",
      "name": "Name"
    },
    "repair-form": {
      "clientAddress": "Address",
      "clientEmail": "Client email",
      "clientName": "Client name",
      "clientPhone": "Cline phone number",
      "dateRepairEnd": "Completion of work",
      "dateRepairStart": "Starting work",
      "dateTicketCreate": "Date of notification",
      "devicePartNumber": "P/N",
      "deviceSerialNumber": "Serial Number",
      "deviceType": "Device type",
      "externalID": "External ID",
      "ids": "IDs",
      "modelName": "Service worksheet",
      "newPartName": "New installed component SN, CT ID",
      "newPartSPN": "New component SPN",
      "orderNumber": "Order Number",
      "repairClaim": "Repair claim",
      "repairEngineer": "Service engineer",
      "repairFormNumber": "Worksheet number",
      "returnable": "Returnable",
      "save-info": "Changes to optional fields are saved before generating the PDF",
      "technial-details": "Technical details",
      "validation-error": "The ticket requires a service form which is not properly filled out.",
      "warranty": "Under warranty"
    },
    "reports": {
      "inventories": {
        "footer": "Footer",
        "for-assigned-contact": "Inventory for assigned contacts",
        "inventory-space": "Inventory ",
        "narrowing-inventory": "Narrow inventory",
        "reason": "Reason",
        "select-coworker": "Select coworker",
        "select-floor": "Select floor",
        "select-inventory": "Send inventory",
        "select-organizational-unit": "Select organizational unit",
        "select-pertner": "Select partner",
        "select-site": "Select a site",
        "tools-space": "Tools "
      },
      "inventory-pdf": "Inventory PDF",
      "pdf": "PDF",
      "preview": "Preview"
    },
    "resource-group": {
      "authorizer": "Authorizer",
      "manager": "Manager",
      "modelName": "Capacity planning group",
      "name": "Name",
      "planners": "Planners",
      "solvers": "Solvers"
    },
    "resource-period": {
      "approvedHours": "Approved Hours",
      "managerName": "Manager",
      "name": "Name",
      "planned-plannable": "Planned/Plannable",
      "resourcePeriodName": "Planning period"
    },
    "resource-plan": {
      "add-resource-plan": "Add plan",
      "approvedHours": "Approved hours",
      "description": "Description",
      "modelName": "Capacity planning",
      "plannedHours": "Planned hours",
      "priority": "Priority",
      "project": "Project",
      "rbk": "RBK",
      "rbkDate": "RBK date",
      "save-success": "The plan has been successfully saved",
      "save-title": "Save plan",
      "solver": "Solver",
      "state": "State",
      "ticket": "Ticket"
    },
    "resource-plan-priority": {
      "color": "Color",
      "intlKey": "Intl key",
      "modelName": "Plan priority",
      "name": "Name",
      "priority": "Priority",
      "value": {
        "must-have": "Obligatory",
        "nice-to-have": "Nice to have",
        "normal": "Normal"
      }
    },
    "roundTypes": {
      "five": "5 HUF rounding",
      "normal": "Normal rounding"
    },
    "scheduled-task": {
      "audit-log-info": "Last {limit} tickets generated from this template"
    },
    "service-level": {
      "critical": "Critical",
      "escalation": "Escalation",
      "groups": "Groups",
      "modelName": "Service Level",
      "name": "Name",
      "new-item": "New item",
      "normal": "Normal",
      "opening": "Opening",
      "overdue": "Overdue",
      "priority": "Priority",
      "repairService": "Repair Service",
      "roles": "Roles",
      "servicePeriods": "Service Periods",
      "solution": "Solution",
      "status-change": "Status change",
      "template-change-confirmation": "By separating the template, the current data is lost, and the service level with the template data is immediately saved in the database.\nAre you sure you want to continue?",
      "type": "Type",
      "users": "Users",
      "warning": "Warning",
      "worksheets": "Worksheets"
    },
    "service-level-template": {
      "modelName": "Service Level Template",
      "name": "Name",
      "reactionTime": "Reaction time",
      "solvingTime": "Solvint time",
      "startTime": "Start time"
    },
    "service-order": {
      "endDate": "It's over",
      "file": "File",
      "servicePerson1": "Primary",
      "servicePerson2": "Secondary",
      "startDate": "The beginning",
      "task": "Task",
      "weekDay": "Seven days"
    },
    "service-period": {
      "dateType": "Date type",
      "modelName": "Service time",
      "name": "Name",
      "servicePeriodIntervals": "Time intervals"
    },
    "service-period-file": {
      "dateType": "Day type",
      "modelName": "Service time",
      "name": "Name",
      "servicePeriodIntervals": "Time intervals"
    },
    "service-period-interval": {
      "end": "End date",
      "modelName": "Time period",
      "start": "Start date"
    },
    "settings": {
      "acl": {
        "create-new-acl": "Add new ACL",
        "create-role": "Add new role",
        "edit-role": "Edit role",
        "new-acl": "New ACL"
      },
      "cmdb": {
        "add-dictionary": "Add dictionary",
        "create-attribute": "Add new attribute",
        "create-ci-category": "Add new CI category",
        "create-ci-type": "Add new CI type",
        "create-connection-type": "Add new connection type",
        "create-element": "Add new element",
        "create-manufacturer": "Add new manufacturer",
        "create-tool-type": "Add new tool type",
        "edit-attribute": "Edit attribute",
        "edit-ci-category": "Edit CI category",
        "edit-ci-type": "Edit CI type",
        "edit-code-store": "Edit dictionary",
        "edit-connection-type": "Edit connection type",
        "edit-element": "Edit element",
        "edit-manufacturer": "Edit manufacturer",
        "edit-relation": "Edit relations",
        "edit-tool-type": "Edit tool type"
      },
      "date-type-definitions": {
        "add-date": "Add date",
        "new-day-type-definition": "New day type definition"
      },
      "document-types": {
        "document-type": "Document type",
        "new-document-type": "New document type"
      },
      "fetchmail": {
        "authenticationType": "Authentication type",
        "authenticationTypeBasic": "Basis",
        "authority": "Authentication Address (URL)",
        "clientId": "Client ID (clientId)",
        "clientSecret": "Secret client code (clientSecret)",
        "connect": "Server",
        "error-logs": "Error log"
      },
      "file-states": {
        "edit-file-type": "Edit file type",
        "file-status": "File status"
      },
      "finances": {
        "edit-invoice-category": "Edit invoice category",
        "edit-invoice-type": "Edit invoice type",
        "edit-uoms": "Edit unit of measure",
        "edit-vat": "Edit VAT",
        "exchangeKey": "exchangeKey",
        "login": "login",
        "new-invoice-category": "New invoice category",
        "new-invoice-type": "New invoice type",
        "new-uoms": "New unit of measure",
        "pass": "pass",
        "signaturekey": "signatureKey",
        "taxNumber": "taxNumber"
      },
      "grade-costs": {
        "edit-hourly-rate": "Edit hourly rate"
      },
      "main": {
        "contract-not-required": "Contract is not required",
        "contract-required": "Contract is required",
        "export-not-required": "Export is not required",
        "export-required": "Export is required",
        "fetchmail": {
          "connect": "Server",
          "error-logs": "Error log"
        },
        "income-quote-is-not-required": "Income quotation is not required",
        "income-quote-required": "Income quotation is required",
        "its-permission": " permission",
        "outbound-quote-not-required": "Outgoing quotation is not required",
        "outbound-quote-required": "Outgoing quotation is required",
        "planning-is-not-required": "Planning is not required",
        "planning-is-required": "Planning is required",
        "sponsor": "Pre-set sponsor",
        "valid-price-offer-not-required": "Valid quotation is not required",
        "valid-price-offer-required": "Valid quotation is required"
      },
      "manage-time-offs": {
        "new-leave-application": "New leave application"
      },
      "news-feed": {
        "add-news": "Add news",
        "edit-news": "Edit news"
      },
      "operations": {
        "add-new-element": "Add new element",
        "checking-list-elements": "Checklist elements",
        "edit-annoucement-type": "Edit announcement type",
        "edit-service-area": "Edit service area",
        "edit-service-level": "Edit service level",
        "edit-solving-group": "Edit solving group",
        "edit-status": "Edit status",
        "new-annoucement-type": "New announcement type",
        "new-list-elements": "New checklist",
        "new-service-area": "New service area",
        "new-service-area-two": "New service area",
        "new-service-level": "New service level",
        "new-service-level-two": "New service level",
        "new-solving-group": "New solving group"
      },
      "payments": {
        "edit-currency": "Edit currency"
      },
      "priority": {
        "edit-priority": "Edit priority",
        "new-priority": "New priority"
      },
      "priority-matrix": {
        "edit-priority-matrix": "Edit priority matrix"
      },
      "priority-urgency": {
        "edit-urgency": "Edit urgency",
        "new-urgency": "New"
      },
      "priotity-importance": {
        "edit-business-effect": "Edit business effect",
        "new-business-effect": "New business effect"
      },
      "regions": {
        "edit-region": "Edit region"
      },
      "ticket-status": {
        "new-ticket-status": "New ticket status",
        "ticket-status": "Ticket status"
      },
      "user-grades": {
        "add-position": "Add position",
        "edit-position": "Edit position"
      },
      "users": {
        "add-user": "Add user",
        "edit-holiday-number": "Edit holiday number",
        "edit-user": "Edit user",
        "inactivate-info": "Are you sure you want to inactivate the user?",
        "inactivate-user": "Inactivate user",
        "inactivateFailure": "Inactivation failed.",
        "inactivateSuccess": "Inactivation was successful.",
        "inactivating": "{name} inactivation"
      }
    },
    "site-zone": {
      "default": "Default",
      "modelName": "Zone",
      "name": "Name",
      "partner": "Partner"
    },
    "solving-group": {
      "accounts": "Users",
      "color": "Color",
      "default": "Default",
      "email": "Email",
      "leader": "Leader",
      "modelName": "Solving group",
      "name": "Name",
      "servicePerson1": "Primary solver",
      "servicePerson2": "Secondary solver",
      "shiftChanges": "Shift changes"
    },
    "substitution-modal": {
      "new-substitute": "Set new substitute",
      "no-substitute": "There is no substitute selected",
      "remove": "Remove substitute",
      "remove-confirmation": "Are you sure, that you want to remove the substitution?",
      "title": "Substitution"
    },
    "supplier": {
      "modelName": "Supplier",
      "name": "Supplier"
    },
    "tables": {
      "add-license": "Add license",
      "allocat": "Allocatable",
      "allocated": "Time frame",
      "arrow": "➜",
      "choose": "Select",
      "choose-item": "Select an item",
      "create-new-license": "Create a new license",
      "currently-no-critical-incidents": "Currently, there are no critical incidents",
      "currently-no-group-tasks": "Currently, there are no group tasks",
      "currently-no-out-of-hours-call": "Currently, there are no out-of-hours calls",
      "currently-no-pre-notice": "Currently, there is no pre-notice",
      "currently-no-scheduled-shutdowns": "Currently, there are no scheduled shutdowns",
      "currently-no-tasks": "Currently, there are no tasks",
      "day": "d",
      "delete": "Are you sure you want to delete?",
      "discount": "Margin / Discount (%)",
      "discount-sum": "Discount amount",
      "edit": "Edit",
      "elements": "Elements",
      "end-time": "End time",
      "expire-lower": "Expire",
      "expired-lower": "Expired",
      "file-upload": "File upload",
      "gross-amount": "Gross amount",
      "hours": "h",
      "minutes": "m",
      "nanda": "'N/A'",
      "nanda-lower": "n/a",
      "net-amount": "Net amount",
      "new-element": "New element",
      "new-license": "New license",
      "no-current-task": "Currently there are no assigned tasks",
      "normal-relations": "Normal relations",
      "not-provided": "Not provided",
      "planned": "Planned",
      "project-task": "Project task",
      "quantity": "Quantity",
      "quantity-unit": "Unit of measurement",
      "request-error": "⛔️ An error occurred during the request!",
      "reverse-relations": "Reverse relations",
      "start-time": "Start time",
      "status-changes": "Status changes",
      "sum": "Total: {length}",
      "surcharge-sum": "Margin amount",
      "surcharge-sum-info": "Will not be displayed on the quotation",
      "tax": "Tax",
      "under-consideration": "Under consideration",
      "unit-price": "Unit price",
      "vacation": "Currently, there is no vacation scheduled",
      "violed": "VIOLATED",
      "watched-hours": "Watched hours",
      "work-time": "Work time"
    },
    "tandm": {
      "activate-confirmation": "Are you sure you want to activate the T&M budget? You cannot change it later!",
      "alreadyInvoiced": "Billed out",
      "alreadyUsed": "Used",
      "amount": "Price per item",
      "balance": "Balance",
      "billingCycle": "Billing cycle",
      "calledHoursProposal": "Called hours",
      "called_hours": "Invoicing the hours called",
      "close-confirmation": "Are you sure you want to close the T&M budget?",
      "copyFromContract": "Copy from framework contract",
      "create": "Creation",
      "credit": "Crediting",
      "creditValueDay": "Day of crediting",
      "cumulable": "How many monthly scrolls",
      "custom": "individual",
      "dateEnd": "Support end",
      "dateStart": "Support start",
      "delete": "Deletion",
      "fixed": "Fixed budget",
      "generate-called-hours-proposal": "Generation of an offer for called classes",
      "generate-called-hours-proposal-success": "The offer for the called hours has been generated successfully.",
      "generate-overtime-proposal": "Generating an overbooking offer",
      "generate-overtime-proposal-success": "The overbooking offer has been generated successfully.",
      "generate-specified-amount-proposal": "Generating an offer for a specified amount",
      "generate-specified-amount-proposal-success": "The offer for the specified amount has been successfully generated.",
      "hoursBudget": "Hourly budget",
      "methodOfSettlement": "Method of settlement",
      "modelName": "T&M",
      "monthly": "monthly",
      "monthlyBooking": "Monthly settlement",
      "name": "Name",
      "operationServiceLevel": "Business service",
      "operationServiceLevels": "Business services",
      "overtimeModifier": "Overtime Modifier (%)",
      "overtimeProposal": "Overbooking",
      "price_includes": "The price per cycle includes the hours specified in the frame",
      "settlementOfOvertime": "Settlement of overtime",
      "specifiedAmountProposal": "Invoicing a specified amount",
      "specified_amount": "Amount specified for the hours specified in the frame",
      "startBalance": "Starting clock frame",
      "state": "Status",
      "task": "Task",
      "ticket": "Ticket",
      "tickets": "Attached tasks",
      "update": "Amendment",
      "userGrade": "Competence setting",
      "worklog": "Worksheet",
      "yearly": "annual"
    },
    "tandm-state": {
      "modelName": "T&M status"
    },
    "tandm-user-grade-cost": {
      "cost": "Hourly rate",
      "userGrade": "Classification"
    },
    "task": {
      "task-worksheets": "Task related worksheets"
    },
    "tasks": {
      "main": {
        "actual-colon": "Actual:",
        "add-date": "Add date",
        "all-colon": "All:",
        "all-day-single": "All day",
        "application-request": "Application requests waiting for approval",
        "beginning": "Beginning",
        "billeable-total-colon": "Billable total:",
        "date": "Date",
        "date-of-implementation": "Date of implementation",
        "day-of-week": "Day of week",
        "description-of-performed-task": "Description of performed task",
        "edit-worksheet": "Edit worksheet",
        "end-colon": "End:",
        "enter-time-intervals": "Enter time intervals",
        "expired-colon": "Expired:",
        "grouping": "Grouping",
        "hour": "Hour",
        "inactive-total-colon": "Inactive total",
        "limit-colon": "Limit:",
        "notification-number": "Ticket ID",
        "own-requests": "Own requests",
        "primary": "Primary",
        "secondary": "Secondary",
        "solved-colon": "Solved:",
        "start-colon": "Start:",
        "time": "Time"
      }
    },
    "tax-number-component": {
      "add-address": "Add new address",
      "ambiguous-country": "The partner has multiple billing addresses with different countries ({countries}). The country of the partner cannot be determined unambiguously.",
      "country-not-supported": "The country of the partner is not supported.",
      "list-of-supported-countries": "The list of supported countries can be viewed",
      "new-billing-address-country": "The selected country ({country}) requires tax number format validation using {regex}, which does not match the currently set tax number {taxNumber}. Please update on the \"Basic Data\" tab.",
      "no-billing-address": "There is no billing address provided for the partner to determine the country.",
      "no-form-checking": "Without a specified country, the system can only check for uniqueness, but cannot perform a formal check.",
      "partner-has-country": " Based on the billing address the country of the partner: ",
      "regexp": "Regular expression",
      "set-tax-number": "Set VAT number",
      "supported-tax-numbers": "Supported tax numbers",
      "supported-tax-numbers-info": "Supported regular expressions and their examples",
      "tax-number-invalid": "The entered tax number is invalid according to the regular expression",
      "tax-number-not-unique": "The entered tax number is not unique",
      "tax-number-unique": "The entered tax number is unique",
      "tax-number-valid": "The entered tax number is valid according to the regular expression",
      "title": "Title TAX number"
    },
    "tax-number-pattern": {
      "country": "Country",
      "modelName": "Tax number regex",
      "pattern": "Regular expression"
    },
    "tax-number-pattern-example": {
      "comment": "Comment",
      "example": "Example",
      "modelName": "Tax number regex example"
    },
    "templates": {
      "main": {
        "accepted": "accepted",
        "close": "Close",
        "damit-it-2016": "© 2016 Damit IT",
        "email": "Email",
        "email-sent": "E-mail send!",
        "fast-menu": "Quick menu",
        "forgotten-password": "Forgotten password?",
        "incorrect-email-or-pw": "Incorrect email and/or password!",
        "invalid-token": "Invalid token!",
        "logout": "Logout",
        "menu-switch": "Menu switch",
        "my-profile": "My profile",
        "navigation": "Navigation",
        "or": "or",
        "page-not-exist": "We're sorry, but the page you were looking for doesn't exist.",
        "password": "Password",
        "path-not-find": "The provided path cannot be found.",
        "pw-changed": "Password changed!",
        "reason-sent-successfuly": "Provide has been sent successfully.",
        "remember-me": "Remember me",
        "request-denied": "Request denied:",
        "sign-in": "Sign In",
        "time-mark": "Time mark",
        "toggle-sidebar": "Toggle sidebar",
        "ty-email-sent-with-info": "Thank you! We have sent further instructions to the provided email address.",
        "useful-links": "Here are some useful links",
        "you-declined-mail": "You have selected decline in the email. Please provide a reason (minimum 10 characters) and send it using the send button.",
        "you-from-mailstore": "You have send an email from the inventory"
      }
    },
    "ticket": {
      "activeTandm": "T",
      "allSpentTime": "All spent time",
      "alreadyAssignedTimes": "Registered time",
      "availableTimeToAssign": "Available time to assign",
      "businessService": "Business service",
      "businessServiceName": "Area",
      "clientPartner": "Customer",
      "completionLevel": "Completion level",
      "contact": "Contact",
      "createDate": "Create date",
      "creator": "Creator",
      "dependencies": "Függőségek",
      "description": "Description",
      "duplicateTasks": "{subject} (copy) {numberOfCopies}",
      "file": "File",
      "maxHoursPerWorkLog": "Max hours per worksheet",
      "mergedParentTicketNumber": "Parent ticket identifier",
      "modelName": "Task",
      "open-ticket": "Open ticket",
      "orderIndex": "Order index",
      "parent": "Parent task",
      "partner": "Partner",
      "plannedEndDate": "End date",
      "plannedExpensesInHour": "Planned expenses in hours",
      "plannedStartDate": "Start date",
      "plannedUserGrade": "Role",
      "priority": "Priority",
      "productResult": "Result product",
      "pst": "PST ID",
      "resolver": "Solver",
      "solveDeadline": "Deadline",
      "solverGroup": "Solution group",
      "solvers": "Stakeholders",
      "solvingGroup": "Solution group",
      "state": "State",
      "stateChanged": "Date of state change",
      "subject": "Subject",
      "sumPublicSpentTime": "Active spent time",
      "sumSpentTime": "Inactive spent time",
      "supplierPartner": "Partner",
      "ticketNumber": "ID",
      "ticketStateId": "State",
      "ticketUserAssocs": "Solvers",
      "type": "Type",
      "userAssocs": "Solvers",
      "workLogNotification": "Email notification",
      "workLogs": "Worksheets",
      "workplaceAddress": "Workplace address"
    },
    "ticket-state": {
      "applicationToIntervene": "RBK",
      "isSystemType": "System state",
      "management": "Valid steps",
      "modelName": "Notification status",
      "name": "Name",
      "project": "Project",
      "sd": "Operation"
    },
    "ticket-type": {
      "modelName": "Ticket type",
      "name": "Name",
      "scheduledTask": "Can be scheduled"
    },
    "ticket-user-assoc": {
      "allSpentTime": "Logged hours",
      "deadline": "End date",
      "endDate": "End date",
      "endDateLtStartDate": "\"End Time\" cannot be less than or the same as \"Start Time",
      "internalWork": "Internal work",
      "modelName": "Allocation",
      "plannedExpensesInHour": "Allocated hours",
      "remainingTime": "Remaining time (h:m)",
      "startDate": "Start date",
      "supplier": "Supplier",
      "ticket": "Task",
      "user": "Possible solver",
      "workLogs": "Logged hours"
    },
    "ticket-user-assoc-computed": {
      "deadline": "Remaining time (h:m)",
      "endDate": "End date",
      "modelName": "Allocation",
      "plannedExpensesInHour": "Allocated hours",
      "startDate": "Start date",
      "supplier": "Supplier",
      "ticket": "Task",
      "user": "Possible solver",
      "workLogs": "Logged hours"
    },
    "ticketsInProgress": "Pending tickets",
    "ticketsadd-news": "Add news",
    "time": {
      "day-month": "Day (month)",
      "day-week": "Day (week)",
      "days-abbrev": "{days}d",
      "hours": "Hour",
      "hours-abbrev": "{hours}h",
      "minute": "Minute",
      "minutes-abbrev": "{minutes}m",
      "month": "Month",
      "quarterOfYear": "A quarter",
      "year": "Year"
    },
    "timeline": {
      "credit": {
        "balance": "New balance: ",
        "credited": "credited.",
        "monthly-budget": "Monthly budget."
      },
      "export": "Export worksheet events",
      "proposal": {
        "called-hours-proposal": "outgoing offer of the hours called.",
        "create": "created it",
        "delete": "deleted it",
        "overtime-proposal": "outgoing offer for overbooked hours.",
        "specified-amount-proposal": "outgoing offer for the specified amount."
      },
      "statistics": {
        "dataset": "Accounting entries",
        "day": "Daily",
        "month": "Monthly",
        "week": "Weekly"
      },
      "tandm": {
        "create": "created the T&M",
        "update": "changed the T&M"
      },
      "tandm-osl": {
        "create": "added",
        "delete": "removed"
      },
      "ticket": {
        "checkbox": "the T&M value of the ticket",
        "create": "created",
        "ticket": "ticket",
        "to-false": "to false.",
        "to-true": "to true.",
        "update": "updated"
      },
      "work-log": {
        "cost": "with hourly rate",
        "create": "booked",
        "delete": "deleted",
        "hours": "hours",
        "ticket": "for the task",
        "update": "updated",
        "userGrade": "in position"
      }
    },
    "translation": {
      "description": "Description",
      "language": "Language",
      "modelName": "Translation",
      "name": "Designation"
    },
    "tsm-import": {
      "button": "Load data",
      "summary": {
        "all": "Total number of rows: ",
        "errors": "errors occurred in line and were not imported!",
        "errors-info": "Errors can be found in the last column of the downloaded file",
        "success": "Number of imported worksheets: "
      }
    },
    "unauth": {
      "accept": "Accept",
      "damit": "DAMIT",
      "dashboard": "Dashboard",
      "emailRegex": "Incorrect email!",
      "invalid-token": "The provided token is not valid.",
      "invalid-token2": "Invalid or already expired token!",
      "login": "Login",
      "main": {
        "copyright": "© Copyright 2024. All Rights Reserved.",
        "e-mail": "E-mail",
        "e-mail-address": "E-mail address",
        "forgotten-password": "Forgotten password",
        "iris": "IRIS",
        "new-password": "New password",
        "not-robot": "I am not a robot!",
        "password-again": "Confirm password",
        "registration-success": "Successful registration!",
        "two-fields-not-match": "The two fields do not match!",
        "verify-at-email-link": "To activate your account, please click on the link in the email sent to the provided email address."
      },
      "maximum-character": "Maximum 300 characters",
      "messageMaxChar": "The message cannot be longer than 300 characters!",
      "navigation": "Navigation",
      "note": "Note",
      "note-length": "note length: {length}",
      "passwordNotMatch": "Password does not match!",
      "passwordRegex": "The password must contain at least one uppercase letter, one lowercase letter and one number!",
      "please-accept": "Please accept, or if you do not agree, reject the change request. If you wish, justify your decision by filling in the Comment field.",
      "registration": "Registration",
      "registration-don": "Registration has been successfully completed.",
      "reject": "Reject",
      "successful-registration": "Sucessful registration",
      "task": "Tasks",
      "thank-you-feedback": "Thank you for your feedback!"
    },
    "uom": {
      "modelName": "Unit of measurement",
      "name": "Name",
      "navName": "NAV name"
    },
    "user": {
      "change-image": "Change image",
      "change-password": "Change password",
      "full-name": "Full name",
      "language": "Language",
      "leader": "Leader",
      "max-registred-hours": "Max hours to be registered",
      "number-of-tickets": "Number of tickets",
      "password": "Password",
      "password-again": "Confirm password",
      "position": "Position",
      "raw-cost": "Raw cost per hour",
      "report-as-another-user": "Report as another user",
      "yearly-holidays": "Yearly holiday allowance"
    },
    "user-grade": {
      "cost": "Hourly rate",
      "modelName": "Hourly rate",
      "name": "name"
    },
    "validators": {
      "entityRelated": {
        "presence": "This field cannot be empty ({attribute})"
      },
      "entityValue": {
        "between": "The value of the field must be between {min} and {max}",
        "max": "The value of the field must be a maximum of {max}",
        "min": "The value of the field must be at least {min}",
        "mustBeNumber": "The value of the field must be a number",
        "presence": "This field cannot be empty.\nAttributeId: {entityAttrId} Value: {value}",
        "regex": "The field value does not match the regular expression {regexp}.\nValue: {value}"
      },
      "numberOwn": {
        "mustBeNumber": "The field can only contain numbers"
      },
      "plannedExpenses": {
        "expendedOverPlanned": "The number of allocated hours cannot be greater than the number of planned hours.",
        "mustBeNumber": "The value of the field must be a number.",
        "presence": "This field cannot be empty.",
        "zeroExpense": "You cannot assign a task with 0 hours"
      },
      "presence": "This field is mandatory!",
      "quarterOfHour": "Only quarter hourly divisions are allowed!\ne.g.: 2.25, 2.50, 2.75, etc.",
      "slug": {
        "allowedCharacters": "The field can only contain letters, numbers and \"_\" or \"-\" characters",
        "bannedWords": "The field name cannot be {bannedWords}"
      }
    },
    "value": {
      "not-available": "N/A"
    },
    "vat": {
      "code1": "Code 1",
      "code2": "Code 2",
      "dateFrom": "Validity Start Date",
      "dateTo": "Validity End Date",
      "modelName": "VAT",
      "systemCode": "System code",
      "vatCode": "VAT code",
      "vatId": "VAT ID",
      "vatType": "VAT type",
      "vatValue": "VAT Value"
    },
    "wiki": {
      "main-data": "Basic data",
      "operational-project": "The operational record is typically used to manage operational tasks. Creating an operational record starts with the possibility to create a record by tracking real-time events.",
      "partner": "In the case of subcontracting the Customer can be determined.",
      "partner-data": "Partner data",
      "project": "The project record is typically used to manage system integration (project) tasks. Creating a project record starts with the possibility to create a record by tracking real-time events.",
      "record": "record",
      "settings": "Settings",
      "show-more": "Show all",
      "wiki": "Wiki"
    },
    "work-log": {
      "attachment": "Is there an attachment?",
      "beginDate": "Start date",
      "businessService": "Business service",
      "clientPartnerName": "Partner name",
      "comment": "Solution",
      "createDate": "Registration date",
      "decription": "Worksheet description (contents)",
      "endDate": "End date",
      "file": "File",
      "fileDateEnd": "End of project",
      "fileDateStart": "Project start",
      "fileName": "Project name",
      "generationDate": "Generation Date / Time",
      "isLate": "Are you late",
      "managerUserName": "Project Manager",
      "modelName": "Worksheet",
      "overlap": "There can be no more than 2 overlaps between events",
      "primeContractorName": "Customer name",
      "publicSpentTime": "Active hours",
      "solver": "Solver",
      "spentTime": "Inactive hours",
      "status": "Status",
      "ticketEnd": "End of task",
      "ticketName": "Task name",
      "ticketStart": "Task start",
      "workDate": "Date of work",
      "workLogNumber": "Worksheet number"
    }
  }], ["hu-hu", {
    "Contracts": "Szerződések",
    "Files": "Akták",
    "Partners": "Ügyfelek",
    "account": {
      "code": "Kód",
      "competencies": "Szerepkörök",
      "contact": "Munkatárs",
      "dailyAllocatedWorkTime": "Napi lekötött órakeret",
      "email": "Email",
      "employmentEndDate": "Kilépési dátum",
      "employmentStartDate": "Belépési dátum",
      "enabled": "Engedélyezett",
      "language": "Nyelv",
      "lastPasswdModify": "Utolsó jelszó módosítás",
      "leader": "Vezető",
      "learnerId": "Learner ID",
      "longName": "Teljes név",
      "maxRegistredHours": "Max rögzíthető óra",
      "modelName": "Felhasználó",
      "monthlyAllocatedWorkTime": "Havi lekötött órakeret",
      "numberOfTickets": "Feladatok száma",
      "password": "Jelszó",
      "passwordConfirmation": "Jelszó megerősítése",
      "phone": "Telefon",
      "photo": null,
      "rawCost": "Nyers óradíj",
      "repairEngineer": "Szerviz mérnök",
      "reportAsAnotherUser": "Bejelenthet más nevében",
      "subcontractor": "Alvállalkozó",
      "userGrade": "Munkakör",
      "yearlyHolidays": "Éves szabadság keret"
    },
    "action": {
      "competencies": "Szerepkörök",
      "modelName": "Menü",
      "name": "Név",
      "parent": "Szülő menü",
      "route": "Route",
      "systemName": "Rendszer név"
    },
    "activities": {
      "activity": "Tevékenység",
      "date": "Dátum",
      "id": "Id",
      "modeCreate": "Létrehozás",
      "modeUpdate": "Módosítás",
      "schema": "Séma",
      "tableName": "Táblanév"
    },
    "add-news": "Hír hozzáadása",
    "address": {
      "address": "Cím",
      "addressType": "Típus",
      "buildings": "Épületek",
      "clientPartner": "Partner",
      "country": "Ország",
      "defaultWorking": "Alapértelmezett munkavégzés helye",
      "distance": "Távolság (km)",
      "floors": "Emeletek",
      "fullAddress": "Munkavégzés helye",
      "modelName": "Cím",
      "name": "Megnevezés",
      "place": "Helység",
      "possibility": "Munkavégzési lehetőség",
      "town": "Település",
      "zipCode": "Irányítószám"
    },
    "advanced-filter": {
      "please-select": "Kérem válasszon"
    },
    "aiops": {
      "customer": {
        "contracts": "Szerződések",
        "parameters": "Paraméterek",
        "services": "Szolgáltatások",
        "severance-pay": "Kötbér adatok"
      },
      "dashboars": {
        "3-monthly": "3 havi",
        "accept-planned-values": "Elfogadom az AI javaslatait",
        "accepted-by-aiops": "AIOps által elfogadott",
        "accuracy-of-classified-tickets": "Besorolt ticketek pontossága",
        "all-tickets": "Összes ticket",
        "amount-month": "Szerződéses havi összeg",
        "assigned": "Kiosztott",
        "avg-spent-time": "Átlagos megoldási idő",
        "balance-calculation": "Megtérülés számítás",
        "block": "Adatok zárolása",
        "blocked": "Adatok zárolva",
        "categorized": "Kategorizált",
        "daily": "Napi",
        "distribution-of-working-hours": "Munkaórák eloszlása",
        "draft": "Előbejelentés",
        "error-values-allocated-by-aiops": "AIOps által kiosztott hibás értékek ticketek szerint",
        "half-year": "Féléves",
        "in-progress-tickets": "Folyamatban lévő ticketek",
        "indefinite": "Bizonytalan",
        "itil-tpye": "ITIL típus",
        "max-spent-time": "Leghosszabb megoldási idő",
        "monthly": "Havi",
        "near-sla-tickets": "SLA sértés közelében lévő ticketek",
        "not-accepted-by-aiops": "AIOps által nem elfogadott",
        "operation-service-level": "Szolgáltatás",
        "overhed-fee": "Tényleges ráfordítás",
        "p1": "P1",
        "p2": "P2",
        "pcs": "db",
        "priority": "Prioritás",
        "qty": "db",
        "resolver-blocked": "Megoldó zárolva",
        "score": "Helyezés",
        "sla-tickets": "SLA sértett ticketek",
        "solved-tickets": "Megoldott ticketek",
        "solver": "Megoldó",
        "spent-time": "Munkaóra",
        "spent-times": "Ticketen töltött munkaórák",
        "sum-spent-time": "Összesített időbeli eloszlás",
        "suspend-tickets": "Felfüggesztett ticketek",
        "ticket-per-day": "Napi átlag ticketszám",
        "ticket-type": "Jegy típus",
        "time-utilization-chart": "Időbeli leterheltség",
        "top-solvers": "Top megoldók",
        "unsuccess": "Sikertelen",
        "user-avg-spent-time": "A megoldó átlagos megoldási ideje",
        "user-grade": "Kompetencia",
        "utilization": "Munkaidő kihasználtság",
        "weekly": "Heti",
        "work-log-in-competency": "Kompetencián töltött munkaórák",
        "work-log-out-competency": "Kompetencián alul töltött munkaórák",
        "year": "Éves"
      },
      "excel": {
        "downlaod-template": "Sablon letöltése"
      },
      "interface": {
        "deleted": "Törölt",
        "diff": "Eltérés",
        "help": {
          "title": "1.&nbsp;Use the trigger diagnostics"
        },
        "model": "Model",
        "modified": "Módosított",
        "new": "Új",
        "sync": "Szinkronizáció"
      },
      "kanban": {
        "assign": "Kiosztás",
        "drop-a-ticket": "Dobj egy feladatot",
        "matrix": "Mátrix",
        "optimalization": "Optimalizáció",
        "refresh": "Frissítés",
        "relaod": "Visszaállít",
        "settings": "Beállítások"
      },
      "matrix": {
        "ticket": "Feladat"
      },
      "operation-service-level": {
        "service-levels": "Szolgáltatási szintek"
      },
      "solvers": {
        "parameters": "Paraméterek",
        "user-skills": "Képzettségek"
      },
      "ticket": {
        "cp": "Bizonyosság",
        "notification-data": "Bejelentés adatai",
        "other-data": "Egyéb adatok",
        "planned": "Tervezett érték",
        "time-data": "Határidők"
      },
      "unsuccess": {
        "no-planned-operation-service-level": "Nem talált az AI megfelelő szolgáltatást!",
        "no-planned-priority": "Nem talált az AI megfelelő prioritást!",
        "no-planned-ticket-type": "Nem talált az AI megfelelő típust!",
        "no-priority-or-operation-service-level-or-type": "Nem talált az AI megfelelő szolgáltatást vagy prioritást vagy típust!",
        "no-service-level": "Az adott szolgáltatási szerződáshez nem nincs megfelelő szolgáltatási szint!",
        "too-many-operation-service-level": "Túl sok lehetséges szolgáltatási szerződés!",
        "uncertain": "Bizonytalan predikció!"
      },
      "unsuccess-categorization": "Kategorizálás sikertelen!"
    },
    "aiops-contract": {
      "active": "Aktív",
      "contractAmount": "Szerződéses összeg",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Szerződés",
      "name": "Megnevezés",
      "sourceId": "Forrás ID",
      "validFrom": "Érvényesség kezdete",
      "validTo": "Érvényesség vége"
    },
    "aiops-contract-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Szerződés típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-customer": {
      "active": "Aktív",
      "address": "Cím",
      "contact": "Kapcsolat",
      "dtMode": "Adatbetöltés módja",
      "email": "E-mail",
      "modelName": "Vevő",
      "name": "Vevő neve",
      "phone": "Telefon",
      "solvers": "Dedikált megoldók",
      "sourceId": "Forrás ID",
      "tax": "Adószám"
    },
    "aiops-day-type": {
      "active": "Aktív",
      "code": "Kód",
      "modelName": "Nap típus",
      "name": "Megnevezés"
    },
    "aiops-interface": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "endpoint": "Végpont",
      "interfaceType": "Interfész típusa",
      "modelName": "Interfész",
      "name": "Megnevezés",
      "password": "Jelszó",
      "sourceId": "Forrás ID",
      "syncDate": "Utolsó szinkronizálás",
      "username": "Felhasználónév"
    },
    "aiops-interface-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Interfész típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-operation-service-level": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Szolgáltatás",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-optimization-item-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Optimalizációs paraméter típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-optimization-parameter": {
      "active": "Aktív",
      "defaultValue": "Alapértelmezett érték",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Optimalizációs paraméterek",
      "name": "Megnevezés",
      "optimizationItemType": "Optimalizációs paraméter típus",
      "parameterCategory": "Paraméter kategória",
      "parameterType": "Paraméter típus",
      "relevancyValue": "Alapértelmezett relevancia",
      "sourceId": "Forrás ID",
      "valueFrom": "Intervallum érték (-tól)",
      "valueTo": "Intervallum érték (-ig)"
    },
    "aiops-parameter-category": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Paraméter kategória",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-parameter-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Paraméter típus",
      "name": "Megnevezés",
      "parent": "Szülő típus",
      "sourceId": "Forrás ID"
    },
    "aiops-partner": {
      "active": "Aktív",
      "address": "Cím",
      "contact": "Kapcsolat",
      "deleted": "Törölt",
      "email": "E-mail",
      "modelName": "Partner",
      "name": "Partner neve",
      "partnerType": "Partner típusa",
      "phone": "Telefon",
      "region": "Régió",
      "tax": "Adószám"
    },
    "aiops-partner-type": {
      "active": "Aktív",
      "modelName": "Partner típus",
      "name": "Megnevezés"
    },
    "aiops-pm-type-customer-assoc-computed": {
      "optimizationParameter": "Optimalizációs paraméter",
      "paramValue": "Paraméter érték"
    },
    "aiops-pm-type-partner-assoc": {
      "optimizationParameter": "Optimalizációs paraméter",
      "paramValue": "Paraméter érték"
    },
    "aiops-pm-type-user-assoc-computed": {
      "optimizationParameter": "Optimalizációs paraméter",
      "paramValue": "Paraméter érték"
    },
    "aiops-service-level": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Szolgáltatási szint",
      "name": "Megnevezés",
      "reactionTime": "Reakció idő",
      "solvingTime": "Megoldási idő",
      "sourceId": "Forrás ID"
    },
    "aiops-services-contracts-x": {
      "active": "Aktív",
      "contract": "Szerződés",
      "customer": "Ügyfél",
      "dailySeverancePay": "Napi kötbér",
      "dailySeverancePayCount": "napi kötbér határérték (nap)",
      "dtMode": "Adatbetöltés módja",
      "failingSeverancePay": "Meghiúsulási kötbér",
      "failingSeverancePayCount": "Meghiúsulási kötbér határérték (nap)",
      "modelName": "Szerződéses szolgáltatás",
      "operationServiceLevel": "Szolgáltatás",
      "severancePay": "Kötbér",
      "severancePayCount": "Kötbér határérték (nap)",
      "sourceId": "Forrás ID"
    },
    "aiops-severance-pay": {
      "active": "Aktív",
      "contractType": "Szerződés típus",
      "customer": "Vevő",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Kötbér",
      "severancePayFeeMax": "Kötbér díj max.",
      "severancePayFeeMin": "Kötbér díj min.",
      "severancePayType": "Kötbér típus",
      "sourceId": "Forrás ID"
    },
    "aiops-severance-pay-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Kötbér típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-solver": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Megoldó",
      "name": "Megnevezés",
      "overheadFee": "Rezsi óradíj",
      "sourceId": "Forrás ID",
      "userGrade": "Kompetencia"
    },
    "aiops-test-ticket": {
      "description": "Leírás",
      "email": "E-mail",
      "modelName": "TESZT jegy",
      "operationServiceLevel": "Szolgáltatás",
      "priority": "Priorítás",
      "publicSpentTime": "Időráfordítás",
      "subject": "Tárgy",
      "ticketType": "Jegy típus",
      "userGrade": "Munkakör"
    },
    "aiops-ticket": {
      "active": "Aktív",
      "customer": "Vevő",
      "department": "Szervezet",
      "description": "Leírás",
      "dtMode": "Adatbetöltés módja",
      "email": "E-mail",
      "modelName": "Jegy",
      "name": "Bejelentő",
      "operationServiceLevel": "Szolgáltatás",
      "operationServiceLevelCp": "Szolgáltatás bizonyosság %",
      "plannedOperationServiceLevel": "Tervezett szolgáltatás",
      "plannedPriority": "Tervezett prioritás",
      "plannedPublicSpentTime": "Tervezett időráfordítás",
      "plannedResolver": "Tervezett megoldó",
      "plannedServiceLevel": "Tervezett szolgáltatási szint",
      "plannedTicketType": "Tervezett jegy típus",
      "plannedUserGrade": "Tervezett munkakör",
      "priority": "Priorítás",
      "priorityCp": "Prioritás bizonyosság %",
      "processStatus": "Feldolgozás státusz",
      "publicSpentTime": "Időráfordítás",
      "publicSpentTimeCp": "Időráfordítás bizonyosság %",
      "reactionTime": "Reagálási kötelezettség (perc)",
      "resolver": "Megoldó",
      "resolverCp": "Megoldó bizonyosság %",
      "serviceLevel": "Szolgáltatási szint",
      "serviceLevelCp": "Szolgáltatási szint bizonyosság %",
      "solvingTime": "Megoldási kötelezettség (perc)",
      "sourceId": "Forrás ID",
      "startTime": "Megkezdési kötelezettség (perc)",
      "subject": "Tárgy",
      "ticketReactionTime": "Jegy reagálási időpont",
      "ticketSolvingTime": "Jegy megoldási időpont",
      "ticketStartTime": "Jegy megkezdési időpont",
      "ticketStatus": "Jegy státusz",
      "ticketType": "Jegy típus",
      "ticketTypeCp": "Jegy típus bizonyosság %",
      "userGrade": "Munkakör",
      "userGradeCp": "Munkakör bizonyosság %"
    },
    "aiops-ticket-check-planned": {
      "modelName": "Jegy",
      "operationServiceLevel": "Szolgáltatás",
      "plannedOperationServiceLevel": "Tervezett szolgáltatás",
      "plannedPriority": "Tervezett prioritás",
      "plannedPublicSpentTime": "Tervezett időráfordítás",
      "plannedTicketType": "Tervezett jegy típus",
      "plannedUserGrade": "Tervezett munkakör",
      "priority": "Priorítás",
      "publicSpentTime": "Időráfordítás",
      "sourceId": "Forrás ID",
      "subject": "Tárgy",
      "ticketType": "Jegy típus",
      "userGrade": "Munkakör"
    },
    "aiops-ticket-state": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Jegy állapot",
      "name": "Megnevezés",
      "sourceId": "Forrás ID",
      "ticketStatusSystem": "Rendszer státusz"
    },
    "aiops-ticket-state-system": {
      "modelName": "Jegy állapo (rendszer)",
      "name": "Megnevezés"
    },
    "aiops-ticket-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Jegy típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-user-absence": {
      "active": "Aktív",
      "day": "Dátum",
      "dayType": "Típus",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Távollét",
      "sourceId": "Forrás ID",
      "user": "Megoldó"
    },
    "aiops-user-grade": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Kompetencia",
      "name": "Megnevezés",
      "sourceId": "Forrás ID",
      "userGradeType": "Típus"
    },
    "aiops-user-grade-type": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Kompetencia típus",
      "name": "Megnevezés",
      "sourceId": "Forrás ID"
    },
    "aiops-user-skill": {
      "active": "Aktív",
      "dtMode": "Adatbetöltés módja",
      "modelName": "Képzettség",
      "operationServiceLevel": "Szolgáltatás",
      "skillLevel": "Képzettség szint",
      "sourceId": "Forrás ID",
      "user": "Megoldó"
    },
    "appcomponentcmdb": {
      "choose-atrribute": "Válassz egy attribútumot",
      "choose-operator": "Válassz egy operátort"
    },
    "appcomponentforms": {
      "choose-notifier": "Kérem válasszon bejelentőt"
    },
    "appcomponenthrm": {
      "all-day": "Egész napos",
      "create-an-event": "Esemény létrehozása",
      "free-day": "szabadnap",
      "more": "további...",
      "or-create-new": "hozzon létre újat",
      "select-account": "Válasszon egy account!",
      "select-an-event-or": "Válasszon egy eseményt, vagy",
      "select-leader": "Válasszon vezetőt",
      "select-user": "Válasszon felhasználót"
    },
    "application-to-intervene": {
      "Inspection-carried": "Elvégzendő ellenőrzés",
      "accepted": "Elfogadta",
      "add-contact": "Kapcsolat hozzáadása",
      "add-subtask": "Alfeladat hozzáadása",
      "allocations": "Kiosztások",
      "authorizers": "Engedélyezők",
      "course-intervention": "A beavatkozás menete",
      "deadline": "Határidő",
      "downtime": "Leállások",
      "effect-system": "Milyen hatás várható az érintett rendszerekben",
      "executor": "Végrehajtója",
      "hours-available": "Még kiosztható órák",
      "inspection-description": "Ellenőrzés leírása",
      "new-allocations": "Új Kiosztások",
      "not-accept": "Nem fogadta el",
      "not-reported-back": "Még nem jelzett vissza",
      "planned-hours": "Tervezett óraszám",
      "preventive-measures": "Megelőző intézkedések",
      "recovery": "Visszaállás",
      "recovery-description": "Visszaállás leírása",
      "risk-description": "A kockázat jellege, leírása",
      "risk-intervention": "A beavatkozás kockázatai",
      "scheduled-hpurs": "Tervezett óra",
      "select-ci": "Válaszzon CI típust",
      "ssz": "Ssz.",
      "stoped-or-modified": "Leállítandó v. Módosítandó rendszerkomponens (HW,SW)",
      "system-component": "Rendszerkomponens",
      "system-intervention-request": "Rendszer beavatkozási kérelem",
      "technical": "Technikai",
      "token-valid": "Token érvényes",
      "which-effect-system": "Mely rendszernél van kockázata a beavatkozásnak",
      "which-system": "Mely rendszerekre van hatása a beavatkozásnak",
      "yes": "Igen"
    },
    "attachments": {
      "uploadDocument": "Mellékletek feltöltése",
      "uploadTitle": "Csatolmányok feltöltése",
      "wrongExtension": "Csak a {extensions} kiterjesztésű fájlok elfogadottak a {documentType} kategóriához",
      "wrongMime": "Hibás mime type"
    },
    "availability-generate": {
      "serviceAreaError": "A szolgáltatási terület nincs kitöltve!"
    },
    "bank-account": "Bankszámlaszám",
    "bank-balance": {
      "balance": "Egyenleg",
      "balanceOwnCurrency": "HUF egyenleg",
      "bankAccount": "Bankszámla",
      "bankAccountOwner": "Bankszámla tulajdonosa",
      "currency": "Pénznem",
      "date": "Dátum",
      "exchangeRate": "Árfolyam",
      "modelName": "Banki egyenleg"
    },
    "bank-name": "Bank neve",
    "bs-table": {
      "custom-filters": "Egyedi szűrők",
      "datas": "Adatok",
      "default-colon": "Alapértelmezett:",
      "filter": "Szűrő",
      "filter-name": "Szűrés neve",
      "filter-name-colon": "Szűrés név:",
      "saved-filters": "Mentett szűrők"
    },
    "building": {
      "address": "Cím",
      "clientPartner": "Partner",
      "floors": "Szint",
      "modelName": "Épület",
      "name": "Épület"
    },
    "builtin-acl": {
      "accessType2": "Access Type",
      "builtinAclActionAssocs": "Akciók",
      "model": "Model",
      "modelName": "ACL",
      "permission": "Hozzáférés",
      "property": "Property",
      "readonly": "Csak olvasható"
    },
    "business-service": {
      "entityType": "Típus",
      "entityTypeCategory": "Kategória",
      "modelName": "Üzleti szolgáltatás",
      "name": "Megnevezés",
      "siteZone": "Zóna"
    },
    "buttons": {
      "absence": "Távollét",
      "add": "Hozzáad",
      "add-critic": "Kritéria hozzáadása",
      "add-new-building": "Új épület felvétele",
      "add-new-dictionary": "Új szótár felvétele",
      "add-new-element": "Új elem hozzáadása",
      "add-new-level": "Új szint felvétele",
      "add-new-location": "Új lokáció felvétele",
      "add-new-worksheet": "Új munkalap hozzáadása",
      "add-new-zone": "Új zóna felvétele",
      "add-to-news": "Hír készítése",
      "add-value": "Érték hozzáadása",
      "blueprint": "Tervlap",
      "cancel": "Mégse",
      "capture-new-worksheet": "Új munkalap rögzítés",
      "change-status": "Státusz változtatás",
      "ci-type-relation": "CI típus reláció",
      "clone": "Duplikálás",
      "close": "Bezárás",
      "close-on-first-call": "Close on First Call",
      "close-planning": "Tervezés lezárása",
      "collapse": "Collapse",
      "completion-certificate": "Teljesítési igazolás",
      "complex-filter": "Összetett szűrő",
      "connect-to-it": "Hozzákapcsolás",
      "copying": "Másolás",
      "create": "Létrehoz",
      "create-notification": "Bejelentés létrehozása",
      "create-shedule": "Ütemezés létrehozása",
      "default": "default",
      "delete": "Törlés",
      "delete-filters": "Szűrő törlése",
      "deletetwo": "Töröl",
      "done": "Kész",
      "expand": "Expand",
      "export": "Export",
      "filtering": "Szűrés",
      "filters-dots": "Szűrők...",
      "finish": "Befejez",
      "former-reports": "Korábbi bejelentések",
      "free-word-search-dots": "Szabad szavas keresés ...",
      "generate-offer": "Ajánlat generálása",
      "generate-xml": "XML genrálása",
      "generation": "Generálás",
      "hide-condition": "Feltétel elrejtése",
      "hourly-rate": "Új óradíj",
      "hourly-rates": "Óradíjak",
      "info": "info",
      "invoices": "Számlák",
      "it-can-go": "Mehet",
      "loadingdots": "Loading...",
      "login": "Belépés",
      "mass-closure": "Tömeges lezárás",
      "mass-connection": "Tömeges hozzákapcsolás",
      "modify": "Módosít",
      "new": "Új",
      "new-application": "Új igénylés",
      "new-attribute": "Új attribútum",
      "new-bankaccount-number": "Új bankszámlaszám",
      "new-business-effect": "Új üzleti hatás",
      "new-category": "Új kategória",
      "new-checklist": "Új ellenőrző lista",
      "new-ci-category": "Új CI kategória",
      "new-ci-type": "Új CI típus",
      "new-colleague": "Új munkatárs",
      "new-connection-type": "Új kapcsolat típus",
      "new-contract": "Új szerződés",
      "new-day-type": "Új nap típus",
      "new-day-type-definition": "Új nap típus definíció",
      "new-department": "Új szervezeti egység",
      "new-document-type": "Új dokumentum típus",
      "new-file": "Új akta",
      "new-file-status": "Új fájl státusz",
      "new-incoming-offer": "Új bejövő ajánlat",
      "new-integration": "Új interáció",
      "new-item": "Új tétel",
      "new-job": "Új munkakör",
      "new-leave-application": "Új szabadság kérelem",
      "new-manufacturer": "Új gyártó",
      "new-mapper": "Új kötés",
      "new-notification": "Új bejelentés",
      "new-notification-group": "Új értesítési csoport",
      "new-notification-type": "Új bejelentés típus",
      "new-notification-without-aiops": "Új megbeszélés / oktatás",
      "new-opportunity": "Új lehetőség",
      "new-outgoing-offer": "Új kimenő ajánlat",
      "new-partner": "Új partner",
      "new-period": "Időszak hozzáadása",
      "new-priority": "Új prioritás",
      "new-profile": "Új profil",
      "new-region": "Új régió",
      "new-relation": "Új reláció felvitele",
      "new-result-product": "Új eredménytermék",
      "new-role": "Új szerepkör",
      "new-service-level": "Új szolgáltatási szint",
      "new-service-time": "Új szolgáltatási idő",
      "new-software": "Új szoftver",
      "new-solver-group": "Új megoldó csoport",
      "new-sub-task": "Új alfeladat",
      "new-task-btn": "Új projekt feladat",
      "new-title": "Új cím",
      "new-tool-type": "Új eszköz típus",
      "new-type": "Új típus",
      "new-urgency": "Új sürgősség",
      "new-user": "Új felhasználó",
      "new-vat": "Új áfa",
      "next": "Tovább",
      "note-notification": "Jegyzetből bejelentés",
      "notification-apply-to-all": "Prioritás és értesítés alkalamzása minden szolgáltatásra",
      "notification-group-apply-to-all": "Értesítési csoport alkalamzása minden szolgáltatásra",
      "open-contract": "Szerződés megnyitása",
      "organizational-units": "Szervezeti egységek",
      "otherRange": "Más időtartam",
      "pdf": "PDF",
      "performance-certificate": "Teljesítmény igazolás",
      "posting": "Kiküldés",
      "public-holiday": "Állami ünnep",
      "rbk-start": "Új RBK indítás",
      "refresh-list": "Lista frissítése",
      "register-customer": "Ügyfél regisztrálása",
      "registration": "Regisztráció",
      "remote-access-informations": "Távelérések és hozzáférések",
      "reopen-planning": "Tervezés újranyitása",
      "restart-inventory": "Leltár újraindítása",
      "save": "Mentés",
      "save-and-close": "Mentés és bezárás",
      "save-filter": "Szűrő mentése",
      "save-two": "Ment",
      "send": "Elküld",
      "send-two": "Elküldés",
      "show-condition": "Feltétel megjelenítése",
      "show-less": "Összecsukás 🠝",
      "show-more": "Több megtekintése 🠟",
      "solver-apply-to-all": "Megoldó csoport alkalamzása minden szolgáltatásra",
      "staff": "Munkatársak",
      "start-monitoring": "Monitorozás indítása",
      "stop-inentory": "Leltár leállítása",
      "stop-monitoring": "Monitorozás leállítása",
      "syncronize": "Szinkronizálás",
      "titles": "Címek",
      "unique-relation": "Egyedi reláció",
      "update": "Módosít",
      "vacation": "Nyaralás",
      "vip": "VIP",
      "workday": "Munkanap"
    },
    "character-counter": "Még {remaining} karakter van hátra",
    "character-counter-over": "{remaining} karakterrel túllépte a megengedett karakterszámot",
    "closedTickets": "Lezárt feladatok",
    "cmdb": {
      "attributes": {
        "add-dictionary": "Szótár hozzáadása",
        "device-type-fields": "Eszköztípus mezők",
        "region-fields": "Régió mezők"
      },
      "business-service": "Üzleti szolgáltatások",
      "certificate": "Tanúsítványok",
      "change-requests": {
        "affected-entities": "Érintett CI elemek"
      },
      "clone-host": "Host klónozása",
      "dashboard": "Irányítópult",
      "entities": {
        "new-business-serv": "Új üzleti szolg.",
        "new-license": "Új licenc",
        "new-software": "Új szoftver",
        "new-tech-serv": "Új technikai szolg."
      },
      "entity": {
        "assigned-contact": "Használó munkatársa",
        "change-assigned-partner-warning": "Figyelem! A CI elemhez beállított lokáció tartozik ami a használó partner megváltoztatásával törlődni fog, és az elem mentésével jár! Biztos, hogy folytatja?",
        "ci-type": "CI típus",
        "ci-type-choose": "Válasszon CI típust",
        "custom-fields": "Egyedi mezők",
        "device-type-fields": "Eszköztípus mezők",
        "handover": "Átadás",
        "owner-contact": "Tulajdonos munkatársa",
        "partner-owner": "Ügyfél / Tulajdonos"
      },
      "entity-types": {
        "adding-new-device": "Új eszköz felvétele",
        "manage-procedures": "Eljárásrend kezelése"
      },
      "hardware": "Eszközök",
      "licence": "Licencek",
      "procedures": {
        "ci-name": "CI név",
        "host": "Host",
        "notification-critical": "Notification (critical)",
        "notification-critical-hu": "Értesítés (critical)",
        "notification-down": "Notification (down)",
        "notification-down-hu": "Értesítés (down)",
        "notification-warning": "Notification (warning)",
        "notification-warning-hu": "Értesítés (warning)",
        "priority-critical": "Prioritás (critical)",
        "priority-down": "Prioritás (down)",
        "priority-warning": "Prioritás (warning)",
        "service": "Szolgáltatás",
        "solution-group": "Megoldócsoport"
      },
      "relation-manager": "Relációk hozzáadása",
      "relation-visualisation": {
        "business": "Üzleti szolg.",
        "hardware": "Eszközök",
        "license": "Licenc",
        "software": "Szoftverek",
        "technology": "Tech. szolg."
      },
      "relations": {
        "add-new-relation": "Új reláció felvétele"
      },
      "software": "Szoftverek",
      "technical-service": "Technikai szolgáltatás",
      "visualisation": {
        "icon-select": "Ikon kiválasztása",
        "visualisation": "vizualizációja"
      }
    },
    "cmdb-import": {
      "query-schemas": "Sablonok letöltése",
      "summary": {
        "category": "Kategória",
        "skipped": "Kimaradt",
        "total": "Összes",
        "with-error": "Hibás",
        "without-error": "Hiba nélküli"
      },
      "summary-error": "Sor: {row} oszlop: {column} szöveg: {text} hiba: {error}",
      "summary-error-found": "Sor: {row} oszlop: {column} szöveg: {text} találatok: {found}",
      "summary-skipped": "Sor: {row} nem került betöltésre. Részletes hibaüzenet az excelben",
      "title": "CMDB eszközök importálása"
    },
    "cmdb-visualisation": {
      "accountable": "Felelős",
      "available": "Elérhető",
      "category": "Kategória",
      "categoryName": "Kategória neve",
      "checks": "Checkek",
      "comment": "Komment",
      "downtimes": "Downtimes",
      "entityType": "CI típus",
      "entityTypeName": "CI típus neve",
      "expirationDate": "Lejárati dátum",
      "license": "Licenc",
      "location": "Lokáció",
      "manufacturer": "Gyártó",
      "monitoring": "Monitorozva",
      "name": "Azonosító",
      "noChanges": "A szolgáltatásokban nincs változás.",
      "noChangesTitle": "Nincs változás!",
      "others": "Egyéb",
      "partner": "Ügyfél",
      "partnerName": "Ügyfél neve",
      "procedure": "Eljárásrend",
      "productKey": "Termékkulcs",
      "productType": "Típus",
      "quantity": "Darabszám",
      "relation": "Kapcsolódik",
      "relation-master": "Kapcsolódik (master)",
      "relation-slave": "Kapcsolódik (slave)",
      "remote-access": "Távelérés",
      "serialNumber": "Gyáriszám",
      "service": "Szolgálatás",
      "status": "Státusz",
      "version": "Verzió"
    },
    "code-store": {
      "modelName": "Szótár",
      "models": "Szótárak"
    },
    "comment": {
      "socket": {
        "success": "Új hozzászólás érkezett",
        "title": "Hozzászólás érkezett"
      }
    },
    "competency": {
      "accounts": "Felhasználók",
      "modelName": "Szerepkör",
      "name": "Név"
    },
    "completionCertificate": {
      "steps": {
        "fulfil": "Teljesítés",
        "invoiceItems": "Számla tételek",
        "project": "Projekt azonosítása"
      }
    },
    "components": {
      "file-tariff": {
        "actual-tariff-period": "Aktuális tarifa érvényessége:",
        "no-actual-tariff": "Nincs aktuális tarifa"
      },
      "fullcalendar": {
        "day": "Nap",
        "listWeek": "Heti lista",
        "month": "Hónap",
        "today": "Mai nap",
        "week": "Hét"
      },
      "shift-picker": {
        "sub-title": "A {solverGroupName} megoldócsoporthoz több lehetséges műszakkezdet tartozik, kérem válasszon egyet",
        "title": "Műszakkezdet kiválasztása"
      }
    },
    "components-main": {
      "AAM": "AAM",
      "ATK": "ÁTK",
      "EAM": "EAM",
      "EUE": "EUE",
      "EUFAD37": "EUFAD37",
      "EUFADE": "EUFADE",
      "HO": "HO",
      "KBA": "KBA",
      "NAM": "NAM",
      "OK": "OK",
      "TAM": "TAM",
      "actions": "Akciók",
      "active-expenditure": "Aktív ráfordítás",
      "advance-invoice": "Előszámla",
      "amount-units": "Mennyiségi egység",
      "anonymus": "Anonymus",
      "comment": "Hozzászólás",
      "complete": "Complete",
      "complex-filter": "Összetett szűrő",
      "contract-number-colon": "Szerződés száma:",
      "costumer": "Megrendelő",
      "date-of-minutes-colon": "Jegyzőkönyv dátuma:",
      "distance": "Távolság",
      "dokumentation": "Dokumentációk",
      "duty-free-export-product": " - adómentes termékexport harmadik országba",
      "ending-colon": "Befejezés:",
      "exempt-taxpayer": "- Alanyi adómentes",
      "filters": "Szűrők ...",
      "finished-tasks": "Elvégzett feladatok",
      "forbidden": "Tiltott",
      "general-vat-rates": "Általános ÁFA kulcsok",
      "gross-interpol": "Bruttó",
      "help": "Súgó",
      "inactive-active-expenditure": "Inaktív / Aktív ráfordítás",
      "inactive-expenditure": "Inaktív ráfordítás",
      "intra-community-product": " - Közösségen belüli termékértékesítés",
      "km-implemented": "{km} km",
      "licenses": "Licencek",
      "material-duty-free": " - Tárgyi adómentes",
      "nav-outscope": "A NAV felé az outOfScope érték igaz lesz azoknál a tételeknél, ahol ez a típusú adókulcs szerepel",
      "nav-towards-vat-code": "A NAV felé az Áfa kódban megadott érték fog szerepleni, mint indok",
      "net-interpol": "Nettó",
      "net-unit-price": "Nettó egységár",
      "other-member-state": " - Másik tagállamban teljesített, nem fordítottan adózó ügylet",
      "other-vat-not-37": "- Másik tagállamban teljesített, nem az Áfa tv. 37. §-a alá tartozó, fordítottan adózó ügylet",
      "page-break-colon": "Oldaltörés:",
      "percent": "{percent}%",
      "performance-amount": "Teljesítési összeg",
      "period-hour": "Kérem válasszon órát",
      "period-minute": "Kérem válasszon percet",
      "priority-colon": "Prioritás:",
      "private": "Privát",
      "project-manager-colon": "Projektvezető:",
      "project-name-colon": "Projekt elnevezése:",
      "public-holidays": "Állami ünnepek",
      "rows-per-page": "sor oldalanként",
      "searching": "Keresés ...",
      "service-provider": "Szolgáltató",
      "signatory-colon": "Aláíró:",
      "status-colon": "Státusz:",
      "subject-of-performance": "Teljesítés",
      "system-codes": "Rendszer kódok",
      "system-codes-one-vat-key": "Rendszer kód 1 jelöli az alapértelmezett Áfa kulcsot",
      "tax-free-other-transactions": "- adómentesség egyéb nemzetközi ügyletekhez",
      "tax-free-vat-rates": "Adómentes ÁFA kulcsok",
      "third-country-tehk": "- Harmadik országban teljesített ügylet (TEHK)",
      "ticketOfDate": "Ajánlatadás feladat befejezés dátuma",
      "title-colon": "Cím:",
      "transaction-id-colon": "Tranzakció azonosító:",
      "unknown": "Ismeretlen",
      "vat-law-repealed-keys": "ÁFA törvény hatályán kívüli kulcsok",
      "vat-material-repeal": " - ÁFA tárgyi hatályán kívüli",
      "vat-tv-37": "- Áfa tv. 37. §-a alapján másik tagállamban teljesített, fordítottan adózó ügylet",
      "workdays": "Munkanapok",
      "xwiki": {
        "choose-path": "Útvonal kiválasztása",
        "error": {
          "error-title": "Nem lehet Wiki cikket hozzáadni",
          "page-name": "Oldal neve kitöltése kötelező",
          "path": "Útvonal kiválasztása kötelező",
          "tag": "Minimum egy TAG megadása kötelező"
        },
        "page-name": "Oldal neve",
        "route": "Útvonal",
        "route-select": "Útvonal kiválasztása",
        "tags": "TAG-ek"
      }
    },
    "contact": {
      "active": "Aktív",
      "applicant-choosing": "Bejelentő választása",
      "applicant-details": "Bejelentő adatai",
      "applicant-email": "EMAIL",
      "applicant-location": "LOKÁCIÓ",
      "applicant-name": "BEJELENTŐ NEVE",
      "applicant-new": "Új bejelentő",
      "applicant-phone": "TELEFON",
      "code": "Kód",
      "contractName": "Szerződés neve",
      "dateEnd": "Hátralévő idő",
      "defaultReporter": "Alapértelmezett bejelentő",
      "department": "Szervezeti egység",
      "email": "E-mail",
      "entities": "Eszközök",
      "event-log": "Eseménynapló",
      "i2user": "I2 felhasználó",
      "inventoryManager": "Leltár menedzser",
      "isVip": "VIP",
      "messages": {
        "defaultReporterCheck": {
          "message": "A megadott partnerhez már van felvéve alapértelmezett bejelentő.",
          "title": "Alapértelmezett bejelentő már létezik!"
        },
        "defaultReporterCheckCMDB": {
          "message": "A megadott partnerhez nem létezik alapértelmezett bejelentő.",
          "title": "Alapértelmezett bejelentő nem létezik!"
        }
      },
      "modelName": "Munkatárs",
      "moves": "Mozgások",
      "name": "Név",
      "new-contact": "Új kapcsolat",
      "notPaid": "Kintlévőség",
      "notificationGroups": "Értesítési csoportok",
      "partner": "Szervezet",
      "partnerShortName": "Ügyfél",
      "phone": "Telefon",
      "portal-user-tab": "Portal User",
      "position": "Beosztás",
      "privateOperationServices": "Egyéni láthatóság",
      "states": "Értesítési státuszok",
      "subcontractor": "Alvállalkozó",
      "types": "Értesítési típusok",
      "user": "Felhasználó"
    },
    "contract": {
      "fixed": "Határozott",
      "modelName": "Szerződés",
      "onlyTandm": "Csak T&M",
      "permanent": "Határozatlan",
      "tandm": "T&M"
    },
    "country": {
      "code": "Kód",
      "modelName": "Ország",
      "name": "Név"
    },
    "credit-value-day": {
      "button": "Beállítás",
      "info": "A kiválasztott napon történik a havi keret jóváírása az egyenleghez. Ha a kiválasztott érték nagyobb, mint a hónap utolsó dátuma, akkor a kisebb érték érvényesül."
    },
    "crm": {
      "contrats": {
        "edit-contract": "Szerződés szerkesztése",
        "new-contract": "Új szerződés"
      },
      "main": {
        "add-coworker": "Munkatárs hozzáadása",
        "domestic": "Hazai",
        "edit-coworker": "Munkatárs szerkesztése",
        "export": "Export",
        "foreign": "Külföldi",
        "how-many-day-unpaid-invoice": "Hány napja nem fizetett számla",
        "inland": "Belföld",
        "maincontracting": "Fővállalkozás",
        "market": "Piaci",
        "payment-deadline-left-behind": "Fizetési határidőt átlagban mennyivel lépte túl",
        "payment-discipline": "Fizetési fegyelem",
        "state": "Állami",
        "subcontracting": "Allvállalkozás"
      },
      "opportunities": {
        "add-item": "Tétel hozzáadása",
        "add-offer": "Ajánlat hozzáadása",
        "add-result-product": "Eredménytermék hozzáadása",
        "add-task": "Feladat hozzáadása",
        "edit-item": "Tétel szerkesztése",
        "edit-offer": "Ajánlat szerkesztése",
        "edit-oport-space": " Lehetőség szerkesztése",
        "edit-result-product": "Eredménytermék szerkesztése",
        "edit-task": "Feladat szerkesztése",
        "import-items": "Tételek importálása",
        "import-tasks": "Feladatok importálása",
        "new-task": "Új feladat",
        "orde": "Megrendelés",
        "order": "Megrendelés"
      },
      "partners": {
        "acl": "Jogosultságok",
        "add-bank-account-number": "Bankszámlaszám hozzáadása",
        "add-contact": "Kontakt hozzáadása",
        "add-title": "Cím hozzáadása",
        "addresses": "Címek",
        "bank-accounts": "Bankszámlaszámok",
        "contact-new-partner": "⚠️ Munkatársat létrehozni csak mentett partnerrel lehet!",
        "contacts": "Munkatársak",
        "default-data": "Alap adatok",
        "departments": "Szervezeti egységek",
        "edit-bank-account-number": "Bankszámlaszám szerkesztése",
        "edit-contact": "Kontakt szerkesztése",
        "edit-organizational-unit": "Szervezeti egység szerkesztése",
        "edit-partner": "Partner szerkesztése",
        "edit-title": "Cím szerkesztése",
        "new-department": "Új szervezeti egység"
      }
    },
    "cron": {
      "button": "Cron beállítása",
      "fieldName": "Időzítés"
    },
    "cron-job": {
      "active": "Aktív",
      "cron": "Cron",
      "cronJobResults": "Futások eredményei",
      "lastSuccessfulRun": "Utolsó sikeres futtatás",
      "modelName": "Cron",
      "name": "Név",
      "nextRun": "Következő futás"
    },
    "cron-job-result": {
      "createDate": "Futás ideje",
      "creator": "Létrehozó",
      "modelName": "Cron job eredmény",
      "success": "Sikeres"
    },
    "currency": {
      "code": "Kód",
      "modelName": "Pénznem",
      "name": "Pénznem",
      "round": "Kerekítés tizedesjegyre"
    },
    "dashboard": {
      "done": "Heti elkészült",
      "file-colon": "Akta:",
      "hour": "óra",
      "own-project-tasks": "Saját projekt feladatok",
      "project-readiness": "Projektek készültsége",
      "task-colon": "Feladat:",
      "ticket": {
        "inProgress": "Folyamatban",
        "incoming": "Közelgő",
        "late": "Késésben"
      },
      "today": "Mai feladataim",
      "unfinished": "Fennmaradó feladatok",
      "weekly": "Heti feladataim",
      "weekly-statement": "heti kimutatás",
      "weekly-statement-two": "Heti kimutatás"
    },
    "date-type": {
      "excludes": "Elvesz",
      "includes": "Hozzáad",
      "modelName": "Nap típus",
      "name": "Megnevezés"
    },
    "date-type-definition": {
      "dates": "Napok",
      "days": "Napok / Dátumok",
      "modelName": "Nap típus definíció",
      "name": "Megnevezés"
    },
    "date-type-file": {
      "excludes": "Elvesz",
      "includes": "Hozzáad",
      "modelName": "Nap típus",
      "name": "Megnevezés"
    },
    "dateRangePicker": {
      "lastSevenDays": "Elmúlt 7 nap",
      "lastThirtyDays": "Elmúlt 30 nap",
      "previousMonth": "Előző hónap",
      "thisMonth": "Ez a hónap",
      "today": "Mai nap",
      "yesterday": "Tegnap"
    },
    "department": {
      "modelName": "Szervezeti egység",
      "name": "Név",
      "partner": "Partner"
    },
    "description": "Megnevezés",
    "document-type": {
      "competencies": "Szerepkörök",
      "extensions": "Kiterjesztések",
      "modelName": "Dokumentum típus",
      "name": "Név",
      "shortName": "Rövid név"
    },
    "dueTickets": "Hamarosan lejáró feladatok",
    "duty": {
      "beginDate": "Kezdete",
      "copy-monday": "Hétfő másolása",
      "endDate": "Befejezése",
      "frDutyDay": "Péntek",
      "moDutyDay": "Hétfő",
      "modelName": "A nap hőse",
      "name": "Név",
      "only-top-can-be-deleted": "Csak a legelső rekord törölhető",
      "saDutyDay": "Szombat",
      "solverGroup": "Megoldó csoport",
      "solverGroupId": "Megoldó csoport ID",
      "suDutyDay": "Vasárnap",
      "thDutyDay": "Csütörtök",
      "tuDutyDay": "Kedd",
      "weDutyDay": "Szerda"
    },
    "entity": {
      "contact": "Munkatárs",
      "description": "Leírás",
      "entityType": "CI típus",
      "entityTypeCategory": "Kategória",
      "location": "Lokáció",
      "modelName": "CI elem",
      "monitoringWarning": "Az eszköz zónájának változtatásakor az eszközhöz tartozó monitoring acknowledgementek elvesznek!",
      "name": "Azonosító",
      "ownershipType": "Kapcsolat típusa",
      "partner": "Partner",
      "productKey": "Gyári szám",
      "remark": "Megjegyzés",
      "serialNumber": "Gyári szám",
      "siteZone": "Zóna",
      "status": "Státusz",
      "template": "Sablon",
      "worksite": "Telephely"
    },
    "entity-attribute": {
      "dependent": "A mező csak akkor írható, ha a {dependentAttribute} mezőnek van értéke.",
      "listable": "Listázandó",
      "modelName": "Attribútum",
      "name": "Név",
      "orderIndex": "Sorrend",
      "parent": "Szülő",
      "property": "Mezőnév",
      "required": "Kötelező",
      "searchable": "Kereshető",
      "type": "Típus",
      "types": {
        "array": "Tömb",
        "belongsTo": "Lenyíló lista",
        "checkbox": "Jelölőnégyzet",
        "date": "Dátum",
        "dateTime": "Dátum idő",
        "hasMany": "Lenyíló lista (multi)",
        "number": "Szám",
        "string": "Szöveges beviteli mező",
        "text": "Többsoros szövegmező"
      }
    },
    "entity-contact-type": {
      "modelName": "Kapcsolat típus",
      "name": "Név"
    },
    "entity-license": {
      "address": "Cím",
      "contact": "CI Tulajdonos",
      "contactName": "Felelős",
      "department": "Szervezeti egység",
      "entityName": "CI elem",
      "expirationDate": "Lejárati dátum",
      "licenseName": "Licenc",
      "licenseTypeName": "Licenc típus",
      "location": "Lokáció",
      "modelName": "Licenc",
      "partner": "Ügyfél"
    },
    "entity-licensed-entity-assoc": {
      "entity": "Kapcsolt CI",
      "modelName": "Kapcsolt licencek",
      "new-button": "Tétel hozzáadása",
      "parentEntity": "License"
    },
    "entity-manufacturer": {
      "modelName": "Gyártó",
      "name": "Név"
    },
    "entity-move": {
      "date": "Dátum",
      "description": "Leírás",
      "destination": "Célállomás",
      "entity": "Eszköz",
      "entityName": "Azonosító",
      "entityType": "Eszköz típus",
      "modelName": "Eszköz mozgások",
      "name": "Név",
      "owner": "Végrehajtó",
      "source": "Forrás",
      "type": "Típus",
      "type-options": {
        "owner": "Tulajdonos",
        "user": "Használó"
      }
    },
    "entity-procedure": {
      "changes": "Észlelt különbségek",
      "entityName": "Eszköz",
      "modelName": "Eljárásrend",
      "name": "Szolgáltatás",
      "notificationCritical": "Értesítés (critical)",
      "notificationWarning": "Értesítés (warning)",
      "priorityCritical": "Prioritás (critical)",
      "priorityWarning": "Prioritás (warning)",
      "procedures": "Eljárásrendek",
      "saveSuccess": "Az eljárásrend mentése sikeresen megtörtént.",
      "solvingGroup": "Megoldócsoport"
    },
    "entity-product-type": {
      "manufacturer": "Gyártó",
      "modelName": "Eszköz típus",
      "name": "Típus"
    },
    "entity-status": {
      "modelName": "Eszköz státusz",
      "name": "Megnevezés"
    },
    "entity-type": {
      "approvals": "Jóváhagyók",
      "code": "Kód",
      "codeStore": "Szótár",
      "contacts": "Kapcsolat",
      "entities": "CI elemek",
      "entityAttributes": "Attribútumok",
      "entityRelationTemplates": "Reláció sablonok",
      "entityTypeCategory": "Kategória",
      "generateName": "Név generátor",
      "generator": "Generátor",
      "icingaName": "Icinga",
      "icon": "Ikon",
      "licensedEntity": "Kapcsolt tételek",
      "locations": "Lokációk",
      "longName": "Teljes név",
      "missingData": "A szolgáltatásokban változás keletkezett. Kérem töltse ki a hiányzó adatokat.",
      "modelName": "CI típus",
      "moves": "Mozgások",
      "name": "Név",
      "otherData": "Egyéb adatok",
      "parent": "Szülő",
      "relations": "Relációk",
      "software": "Szoftverek",
      "template": "Sablon",
      "tickets": "Jegyek"
    },
    "entityTypeVisualisation": {
      "themeSwitcher": "Téma választó"
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} nem lehet üres",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "Ez a mező",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} értéke nagyobb kell legyen mint {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "has-many": "Válasszon ki legalább {min} elemet",
      "has-many-add": "Adjon hozzá legalább {min} elemet",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} csak számot tartalmazhat",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "passwordNotMatch": "A jelszó nem egyezik",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "service-period-interval": {
        "wrong-format": "Nem megfelelő formátum (Pl.: 21:12)"
      },
      "singular": "{description} can't be a collection",
      "tooLong": "{description} értéke túl hosszú (maximum {max} karakter)",
      "tooShort": "A kitöltött szöveg túl rövid (legalább {min} karakter)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "expiredTickets": "Lejárt feladatok",
    "fetchmail": {
      "active": "Aktív",
      "authenticationType": "Hitelesítés típusa",
      "authenticationTypeBasic": "Alap",
      "authority": "Hitelesítés címe (URL)",
      "clientId": "Kliens azonosító (clientId)",
      "clientSecret": "Titkos ügyfélkód (clientSecret)",
      "connect": "Szerver & Bejelentkezés",
      "error-logs": "Hibanapló",
      "fetchmailPassword": "Jelszó",
      "fetchmailType": "Típus",
      "fetchmailUser": "Felhasználó név",
      "host": "Kiszolgálónév",
      "isSsl": "SSL/TLS",
      "lastSuccessfulRun": "Utolsó sikeres futtatás",
      "modelName": "Fetchmail",
      "name": "Megnevezés",
      "original": "Eredeti megtartása",
      "port": "Port",
      "user": "Felelős"
    },
    "fetchmail-log": {
      "createDate": "Dátum",
      "description": "Leírás",
      "email": "E-mail",
      "fetchmail": "Fetchmail",
      "log": "Napló bejegyzés",
      "modelName": "Fetchmail napló",
      "subject": "Tárgy"
    },
    "fetchmail-type": {
      "modelName": "Fetchmail típus",
      "name": "Megnevezés"
    },
    "file-business-type": {
      "modelName": "Üzletág",
      "name": "Név",
      "profitCenter": "Profit Center"
    },
    "file-operational": {
      "overview-operational": "Overview operational",
      "statistics": {
        "incomings": "Bevételek",
        "outgoings": "Költségek"
      },
      "ticket-not-deletable": "A jegyre már könyveltek, ezért nem törölhető"
    },
    "file-outgoing-proposal": {
      "amount": "Bruttó összeg",
      "amountUntaxed": "Nettó összeg",
      "createDate": "Létrehozva",
      "currency": "Pénznem",
      "cycle": "Ciklus",
      "defaultAmount": "Bruttó összeg (default)",
      "defaultAmountUntaxed": "Nettó összeg (default)",
      "defaultDescription": "Eszköz szállítás esetén, az ajánlattevő az ajánlat elfogadása és a termékek megrendelése esetén a megrendelt termékek vonatkozásában tulajdonjogát a vételár teljes kiegyenlítéséig fenntartja. Szolgáltatás megrendelés esetén, az ajánlattevő az ajánlat elfogadása és megrendelése esetén a teljesítési határidő külön írásos dokumentumban kerül meghatározásra. Fizetési határidő:  8 munkanap (a számla kiállításától számítva) A számlázás alapja a mindkét fél részéről aláírt szállító levél - eszköz szállítás esetén, vagy a teljesítés Igazolás (TIG) - szolgáltatás esetén. Árfolyam feltételek: Árajánlatunk napi MNB deviza EUR/HUF illetve USD/HUF árfolyamon készült. Az árfolyam emelkedéseket és a gyártói listaár változásokat árainkban érvényesítjük. Amennyiben ezen árfolyam 1,5%-ot meghaladó mértékben emelkedik a számlázás időpontjáig, az Ajánlatadó a teljes vételár kifizetéséig jogosult a szerződéses árat az ennek megfelelő mértékben megváltoztatni.\n",
      "description": "Megjegyzés",
      "discount": "Kedvezmény",
      "discountAmount": "Árengedmény",
      "endDate": "Érvényesség vége",
      "exchangeRateContract": "Szerződéskori árfolyam",
      "fileProductResults": "Eredménytermékek",
      "fromDate": "Érvényesség kezdete",
      "isOrdered": "Megrendelve",
      "modelName": "Kimenő ajánlat",
      "name": "Megnevezés",
      "quotationNumber": "Azonosító",
      "tandm": "T&M",
      "type": "Típus",
      "type_base": "Üzemeltetés",
      "type_project": "Projekt",
      "type_tandm": "T&M",
      "type_tandm_overtime": "T&M túlkönyvelés",
      "valid": "Érvényes"
    },
    "file-outgoing-proposal-item": {
      "amount": "Bruttó összeg",
      "amountUntaxed": "Nettó összeg",
      "defaultAmount": "Bruttó összeg (default)",
      "description": "Leírás",
      "discount": "Árrés / Árengedmény (%)",
      "modelName": "Kimenő ajánlat tétel",
      "name": "Megnevezés",
      "price": "Egységár",
      "quantity": "Mennyiség",
      "uom": "Mértékegység",
      "vat": "Adó"
    },
    "file-outgoing-proposal-user-grade-cost": {
      "cost": "Óradíj",
      "fileOutgoingProposal": "Kimenő ajánlat",
      "modelName": "Óradíj",
      "userGrade": "Besorolás"
    },
    "file-product-result": {
      "billable": "Számlázandó",
      "comment": "Leírás",
      "modelName": "Eredménytermék",
      "name": "Megnevezés",
      "plannedEndDate": "Befejezése",
      "plannedStartDate": "Kezdete"
    },
    "file-proposal": {
      "amount": "Bruttó összeg",
      "amountUntaxed": "Nettó összeg",
      "currency": "Pénznem",
      "description": "Leírás",
      "exchangeRate": "Árfolyam",
      "modelName": "Bejövő ajánlat",
      "name": "Azonosító",
      "paid": "Kifizetett",
      "partnerRef": "Beszállítói hivatkozás",
      "supplier": "Beszállító",
      "valid": "Érvényes"
    },
    "file-proposal-item": {
      "amount": "Bruttó összeg",
      "amountUntaxed": "Nettó összeg",
      "arrivalDate": "Beérkezési dátum",
      "changeExchangeRate": "Árfolyam felülbírálása",
      "currency": "Pénznem",
      "defaultAmount": "Bruttó összeg (default)",
      "defaultAmountUntaxed": "Nettó összeg (default)",
      "description": "Leírás",
      "exchangeRate": "Árfolyam",
      "exchangeRateContract": "Szerződéskori árfolyam",
      "exchangeRateDate": "Árfolyam napja",
      "modelName": "Bejövő ajánlat tétel",
      "name": "Megnevezés",
      "partNumber": "P/N",
      "price": "Egységár",
      "productResult": "Eredménytermék",
      "qty": "Mennyiség",
      "uom": "Mértékegység",
      "vat": "Adó"
    },
    "file-state": {
      "allocation": "Kiosztás",
      "contract": "Szerződés",
      "modelName": "Akta státusz",
      "name": "Név",
      "navName": "NAV megnevezés",
      "observation": "Monitoring",
      "opportunity": "Lehetőség",
      "orderOk": "Megrendelés rendben",
      "outgoingProposal": "Kimenő ajánlat",
      "planning": "Tervezés",
      "probabilityMax": "Maximum valószínűség",
      "probabilityMin": "Minimum valószínűség",
      "proposal": "Ajánlat"
    },
    "file-states": {
      "title": "Fájl státuszok"
    },
    "file-tariff": {
      "amount": "Tarifa értéke",
      "dateEnd": "Befejezése",
      "dateStart": "Kezdete",
      "modelName": "Tarifa"
    },
    "file-type": {
      "code": "Kód",
      "contract": "Szerződés",
      "export": "Export",
      "fileBusinessType": "Üzletág",
      "modelName": "Akta típus",
      "name": "Név",
      "orderOk": "Érvényes ajánlat",
      "outgoingProposal": "Kimenő ajánlat",
      "planning": "Tervezés",
      "projectType": "Akta jellege",
      "proposal": "Bejövő ajánlat"
    },
    "file-types": {
      "title": "Fájl típusok"
    },
    "file-user-grade-cost": {
      "cost": "Óradíj",
      "file": "Akta",
      "modelName": "Óradíj",
      "plannedCost": "Tervezett óradíj",
      "resetUserGrades": "Óradíjak visszaállítása",
      "resetUserGradesConfimation": "Biztos benne, hogy vissza akarja állítani a terv óradíjakat?",
      "userGrade": "Besorolás"
    },
    "filters": {
      "attributeTypes": {
        "array": "Lista",
        "belongsTo": "Kapcsolódó",
        "checkbox": "Igaz/Hamis",
        "date": "Dátum",
        "hasMany": "Kapcsolódóak",
        "number": "Szám",
        "string": "Szöveg"
      },
      "closed": "Lezárva",
      "export": "Exportálás",
      "fuzzy-search-placeholder": "Szöveges keresés...",
      "new-filter": "Új szűrő",
      "operators": {
        "Boolean": "Igen/Nem",
        "Contains": "Tartalmazza",
        "Equal": "Pontosan",
        "GreaterThan": "Nagyobb mint",
        "LessThan": "Kisebb mints",
        "Null": "Nincs kitöltve"
      },
      "remove-filter": "Szűrő eltávolítása",
      "reset-filters": "Visszaállítás alapértelmezettre",
      "save-tab": "Szűrő mentése",
      "save-tab-instruction": "Kérem adja meg a szűrő nevét",
      "show-inactive": "Látszik az inaktív"
    },
    "finances": {
      "account-number": "Számlasorszám",
      "amount": "Összeg",
      "amount-paid": "Fizetett összeg",
      "balance": "Egyenleg",
      "contracts": "Szerződések",
      "creation": "Létrehozás",
      "current-balance": "Jelenlegi egyenleg",
      "demand-payment": "Fizetési felszólítás",
      "device-service": "Eszöz/Szolg",
      "disabled": {
        "correcting": "Erről a számláról nem állíthaó ki helyesbítő számla",
        "delete-invoice": "Csak előszámlát lehet törölni",
        "final-invoice": "Nem véglegesítő számla",
        "nav": "Nem kérdezhető le NAV státusz",
        "nav-send": "Nem küldhető be a NAV-nak",
        "normal-pre-invoice": "Csak normál és előszámlát lehet másolni",
        "pre-invoice": "Csak előszámlát lehet szerkeszteni",
        "storno": "Nem sztornózható"
      },
      "final-invoice": "Véglegesítő számla",
      "floor": {
        "address": "Cím",
        "building": "Épület",
        "clientPartner": "Partner",
        "modelName": "Szint",
        "name": "Szint"
      },
      "given": "Átadva",
      "gross": "Szlabruttó",
      "incoming-invoices": {
        "data-source": "Adatforrás",
        "delivery-date": "Átadás dátuma",
        "invoice-pdf-size": "Számla PDF nézete",
        "new": "Új bejövő számla",
        "transfer-to-accounting": "Átadás a könyvelésnek"
      },
      "industry": "Üzletág",
      "invoice-number": "Számlasorszám",
      "main": {
        "accepted-by-them": "Befogadták",
        "account-number": "Számlasorszám",
        "accounting-date": "Könyvelés dátuma",
        "add-days-info": "A kiválasztott partnernél beállított fizetési napok számát adja hozzá a mai dátumhoz",
        "advance-invoice": "Előleg számla",
        "approved-by-them": "Elfogadták",
        "create-invoice-error": "Hiba a számla készítése közben",
        "currency-rate": "Árfolyam",
        "mixed": "Vegyes",
        "nav": "NAV",
        "new-invoice": "Új számla",
        "not-available": "N/A",
        "one-time": "Egyszeri",
        "partner-address-error": "A kiválasztott partnernél nincs beállítva számlázási cím",
        "payment-amount": "Kifizetés összege",
        "payment-mode": "Kifizetés módja",
        "services": "Szolgáltatások",
        "submission-error": "Hiba a beküldésnél",
        "unique": "Egyedi",
        "warning": "Figyelmeztetés",
        "word": "{word}"
      },
      "nav": {
        "check-state": "Státusz lekérése",
        "check-state-error": "Hiba a státusz lekérdezése közben",
        "check-state-response": "A visszakapott státusz: {status}",
        "send": "Számla beküldése a NAV-nak",
        "send-error": "Hiba a számla beküldése közben",
        "state": "NAV státusz"
      },
      "nav-connection": "NAV kapcsolat",
      "nav-connection-live": "NAV éles API",
      "nav-connection-test": "NAV teszt API",
      "nav-instruction": "Válassza ki, hogy melyik kapcsolatot használja az adatszolgáltatáshoz",
      "nav-warning": "FIGYELMEZTETÉS: A számla automatikusan továbbítódik a NAV felé.",
      "opportunities": "Lehetőségek",
      "overdue": "Overdue",
      "overhead-cost": "Rezsi",
      "own-re-invoicing": "Saját/Tovább",
      "paid": "Kifizetve",
      "paid-out": "Kifizetett",
      "partner": "Partner",
      "payment": "Kifizetés",
      "payment-method": "Kifizetési mód",
      "payments": "Kifizetések",
      "payments-date": "Kifizetés dátuma",
      "pre-invoice": "Előszámla",
      "pre-invoices": "Előszámlák",
      "prepayment-save": "Előszámlaként mentés",
      "preview": "Előkép megtekintése",
      "profit-center": {
        "account-number": "Account number",
        "choose-file-business-type": "Válasszon egy Üzletágat",
        "choose-file-type": "Válasszon egy Project típust",
        "choose-profit-center": "Válasszon egy Profit centert",
        "profit-center": "Profit center",
        "profit-center-fields": "Profit center mezők"
      },
      "quotation-generation": "Ajánlat generálása",
      "sector": "Szektor",
      "tig-generation": "Teljesítési igazolás generálása",
      "time-interval": "Idő intervallum",
      "unpaid": "Kifizetetlen",
      "vat-paid": "Áfa telj.",
      "weighted": "Súlyozott",
      "xml-generation": "XML generálása"
    },
    "floor": {
      "address": "Cím",
      "building": "Épület",
      "clientPartner": "Partner",
      "locations": "Lokációk",
      "modelName": "Szint",
      "name": "Szint"
    },
    "forms": {
      "access-type": "Access Type",
      "account-category-properties": "Számla kategória tulajdonságai",
      "account-details": "Számla adatai",
      "account-serial-number": "Számla sorszám",
      "account-type": "Számla típusa",
      "account-type-properties": "Számla típus tulajdonságai",
      "accountable": "Felelős",
      "accountcredit": "Főkönyvi szám: tartozik",
      "accountdebit": "Főkönyvi szám: követel",
      "action": "Akció",
      "active": "Aktív",
      "add": "Hozzáad",
      "add-allocation": "Kiosztás hozzáadása",
      "add-manufacturer": "Gyártó hozzáadása",
      "add-new-exception": "Új kivétel megadása",
      "add-new-requirement": "Új feltétel megadása",
      "add-notification-group": "Értesítési csoport hozzáadása",
      "add-type": "Típus hozzáadása",
      "additional-disclosure": "További közlendő",
      "agile": "Agilis",
      "all-day-question": "Egész napos?",
      "allocable-hours": "Kiosztható órák",
      "application-description": "Bejelentés leírása",
      "area-of-service": "Szolgáltatás területe",
      "assets-to-be-transferred": "Áthelyezendő eszközök",
      "attachments-colon": "Csatolmányok:",
      "automatic-name-generation": "Automatikus név generálás",
      "available-qty": "Elérhető mennyiség",
      "bank": "Bank",
      "barcode": "Vonalkód",
      "base-url": "URL",
      "billingadress": "Számlázási cím",
      "billingcycle": "Számlázás ciklusa",
      "booking-for-tickets": "Az ezen az aktán létrejövő jegyekre történő könyvelés hány napra nyúlhat vissza",
      "building": "Épület",
      "built-in-device": "Beépített eszköz",
      "cash": "Cash",
      "cashbookid": "Pénztárkönyv azonosítója",
      "change-reason": "A változás oka",
      "change-type": "A változás típusa",
      "checkip": "IP cím ellenőrzése: {mask}",
      "chmlvl": "Kritikus, Magas, Közepes, Alacsony, Csekély",
      "choose-manager": "Válasszon Menedzsert",
      "choose-option-fixing": "Válasszon lehetőség rögzítőt",
      "choose-product-results": "Válassza ki a számlázni kívánt eredményterméke(ke)t",
      "choose-sponsor": "Válasszon Szponzort",
      "choose-technicalmanageruser": "Válasszon technológiai ügyfél menedzsert",
      "ci": "CI",
      "ci-name": "CI név",
      "ci-s": "CI-k",
      "ci-type": "CI típus",
      "classification": "Besorolás",
      "client-partner": "Partner",
      "close-file": "Akta lezárása",
      "code": "Kód",
      "code-colon": "Kód:",
      "code1": "Kód 1",
      "code2": "Kód 2",
      "comments": "Megjegyzések",
      "communication": "Kommunikáció",
      "company-name": "Cégnév",
      "companyname": "CÉGNÉV",
      "companynamecolon": "CÉGNÉV:",
      "connected-to-it": "Hozzákapcsolva",
      "connection-type": "Kapcsolat típusa",
      "connections": "Kapcsolatok",
      "consolidated-notifications": "Összevont bejelentések",
      "content": "Tartalom",
      "continous": "Folyamatos",
      "contract": "Szerződés",
      "contract-colon": "Szerződés:",
      "copied-to-clipboard": "Útvonal a vágólapra másolva!",
      "copy-success": "Másolás sikeres",
      "cost-price": "Beszerzési ár",
      "cost-price-two": "Beszer.Ár",
      "costumer-buyer-colon": "Megrendelő/Vevő:",
      "country": "Ország",
      "coworker": "Munkatárs",
      "currency": "Valuta",
      "currency-code": "Valuta kód",
      "currency-name": "Valuta neve",
      "currently-no-announcements": "Nincs összevont bejelentés",
      "custom-attributes": "Egyedi mezők",
      "customer-it-connection": "Ügyfél IT kapcsolat",
      "customer-manager": "Ügyfél menedzser",
      "customer-sat-ques": "Ügyfél Elégedettségi Kérdőív",
      "customercolon": "Ügyfél:",
      "customersponsor": "Ügyfél szponzor",
      "date": "Dátum",
      "date-end": "Befejezés dátum",
      "date-of-entry": "Bekerülés Dátuma",
      "date-start": "Kezdés dátum",
      "datePerRange": "Dátum/Időszak",
      "dated": "Kelt",
      "dates": "Dátumok",
      "datetype": "Nap típus",
      "day-dates": "Napok / Dátumok",
      "dayhourminute": "Nap / Óra / Perc",
      "days": "Napok",
      "deadlines": "Határidők",
      "debit": "Tartozik",
      "default": "Alapértelmezett",
      "default-business-service": "Alapértelmezett üzleti szolgáltatás",
      "default-service-level": "Alapértelmezett szerződéses szolgáltatás",
      "default-working-address": "Alapértelmezett munkavégzés helye",
      "defenetly-no": "Egyáltalán nem",
      "degree-readiness": "Készültségi fok",
      "degree-rounding": "Kerekítés mértéke",
      "delegations": "Delegálások",
      "deleted-entry": "Ez a bejegyzés törlésre kerül!",
      "demand": "Követel",
      "dependencies": "Függőségek",
      "descripction-colon": "Leírás:",
      "description": "Leírás",
      "designers": "Tervezők",
      "detract": "Elvesz",
      "device": "Eszköz",
      "device-designed-to-control-service": "Szolgáltatás ellenőrzésére kijelölt eszköz",
      "device-type": "Eszköz típus",
      "distancekm": "Távolság (km)",
      "edit-notification-group": "Értesítési csoport szerkesztése",
      "emailcolon": "EMAIL:",
      "emailcolonlower": "E-mail:",
      "end-of-validity": "Érvényesség vége",
      "ending-time": "Befejezés időpont",
      "entity-relation-type": "Reláció típusa",
      "entityTypeCategory": "Kategória",
      "eu-taxnumber": "EU Adószám",
      "exception-colon": "Kivétel:",
      "exchangerate": "Váltás aránya",
      "exchangeround": "Váltási arány tizedes jegyeinek száma",
      "exchangeunit": "Váltási arány egysége",
      "expiration": "Lejárat",
      "expiration-date": "Lejárati idő",
      "export-colon": "Export:",
      "extencions": "Kiterjesztések",
      "external-integration": "Integráció",
      "external-key": "Külső key",
      "external-value": "Külső érték",
      "fastener": "Rögzítő",
      "feedback-deadline": "Visszajelzés határideje",
      "fieldname": "Mezőnév",
      "file-code-format": "Fájl kód forma",
      "filter-params": "Alap lekérés",
      "financial-number": "Pénzügyi iktatószám",
      "financial-relationship": "Pénzügyi kapcsolat",
      "finish-crop": "Kép szerkesztésének befejezése",
      "fix-worksheet": "Munkalap rögzítése",
      "fquantity": "Darabszám",
      "friday": "Péntek",
      "from": "Dátum tól",
      "fulladdress": "Cím",
      "generate-name": "Név generálása",
      "gimuy": "g,i,m,u,y",
      "goal-fulfillment": "Cél teljesülés",
      "gross-colon": "Bruttó: ",
      "gross-price": "Bruttó ár",
      "group-taxnumber": "Csoportos Adószám",
      "hourly-rate": "Óradíj",
      "hours": "{hours} óra",
      "i2user": "I2 user",
      "iban": "IBAN",
      "ichigatwo": "Icinga2",
      "icingaName": "Icinga",
      "identifier": "Azonosító",
      "imac-limit-colon": "IMAC limitje:",
      "imac-question": "IMAC?",
      "imacd": "IMACD",
      "imacd-lower": "IMACD keretet csökkent",
      "immediacy": "Sűrgösség",
      "impact-change": "Változtatás hatása",
      "inactive": "Inaktív",
      "incoming-offer-colon": "Bejövő ajánlat:",
      "industry": "Üzletág",
      "informations": "Információk",
      "inprogress": "folyamatban",
      "inside-job": "Belső munka",
      "inside_the_zone": "Zónán belüli értesítés",
      "internal-accounting": "Belső elszámolás",
      "internally-accounted": "Belső elszámolású",
      "inventory-organization": "Leltározó szervezet",
      "inverse": "Fordított",
      "invoiceitems": "Számla tételek",
      "invoicename": "Tétel neve",
      "iris-key": "Iris key",
      "iris-value": "Iris érték",
      "islisted": "Listában szerepel",
      "it-connection": "IT kapcsolat",
      "its-ending": "Befejezése",
      "itsname": "Neve",
      "job-opportunity": "Munkavégzési lehetőség",
      "justification-upper": "INDOKLÁS",
      "key": "Kulcs",
      "level": "Szint",
      "location": "Lokáció",
      "locationcolon": "LOKÁCIÓ:",
      "main-notification-id": "Fő bejelentés azonosító",
      "main-paymentmethod": "Fő fizetési mód",
      "max-duration": "Max: {duration} perc",
      "max-recorded": "Max rögzíthető (óra)",
      "maxdot": "Max.",
      "menu": "Menü",
      "min-duration": "Min: 0 perc",
      "mindot": "Min.",
      "model": "Model",
      "monday": "Hétfő",
      "monitor-device": "Eszköz monitorozása",
      "monitoring": "Monitoring",
      "name-colon": "Név:",
      "name-of-notifier-colon": "BEJELENTŐ NEVE:",
      "name-of-service": "Szolgáltatás neve",
      "name-status": "Státusz megnevezése",
      "namecolon": "Elnevezés:",
      "nature-of-the-file": "Akta jellege",
      "nav-name": "NAV megnevezés",
      "net-amount-colon": "Nettó összeg: ",
      "net-colon": "Nettó: ",
      "netprice": "Nettó ár",
      "new-ticket-booking": "Az újjonan létrejövő jegyekre könyvelhető maximális óraszám / nap",
      "no-more-to-do": "Nincs több teendője.",
      "no-result-product": "Nincs eredménytermék",
      "normal": "Normal",
      "not-billable-elements": "Nem számlázandó elemek",
      "note": "Megjegyzés",
      "noticeperioddays": "Felmodási idő (nap)",
      "notification-groups": "Értesítési csoportok",
      "notification-statuses": "Értesítési státuszok",
      "notification-types": "Értesítési típusok",
      "notificationGroupType": "Értesítés típusa",
      "notifier-id": "Bejelentő azonosítása",
      "obligatory": "Kötelező",
      "open-file": "Akta újranyitása",
      "operation": "Üzemeltetés",
      "ordered": "Megrendelve",
      "organization": "Szervezet",
      "organizational-unit": "Szervezeti egység",
      "other": "Egyéb",
      "outbound-offer-colon": "Kimenő ajánlat:",
      "outsider-id": "Külső azonosító",
      "overhead": "Overhead",
      "owner": "Tulajdonos",
      "parameters": "Paraméterek",
      "parent": "Szülő",
      "part-number": "P/N",
      "partner-inbound-accounts": "Partner bejövő számlái",
      "partner-it-connection": "Partner IT kapcsolat",
      "partner-owner": "Partner",
      "partnersponsor": "Képviselője",
      "payment-deadline": "Fizetési határidő",
      "payment-methodproperties": "Fizetési mód tulajdonságai",
      "payment-type": "Fizetés típusa",
      "paymentdeadlinecolon": "Fizetési határidő: ",
      "paymentmethod": "Fizetési mód",
      "performance": "Teljesítés",
      "performance-period": "Teljesítési időszak",
      "periods": "Időszakok",
      "permission": "Permission",
      "personal-conditions": "Személyi feltételek",
      "phase-name": "Fázis neve",
      "phone": "Telefon",
      "phone-number": "Telefonszám",
      "phonecolon": "TELEFON:",
      "place-of-work": "Munkavégzés helye",
      "plan-spent-hours": "Terv ráfordítás órában",
      "planned-end": "Tervezett befejezés",
      "planned-start": "Tervezett kezdet",
      "planned-task-hours": "Feladatra tervezett óraszám",
      "planning-colon": "Tervezés:",
      "portion": "Eloszt",
      "possible-solvers": "Lehetséges megoldók",
      "prfapproval": "Jóváhagyásért felelős személy",
      "price": "Ár",
      "pricepercycle": "Ciklusonkénti ár",
      "primary-duty-officer": "Elsődleges ügyeletes",
      "prime-contractor": "Ügyfél",
      "priority": "Priorítás",
      "prioritycolon": "Prioritás:",
      "probability": "Valószínűség",
      "probabilitymax": "Probability Max",
      "probablilitymin": "Probability Min",
      "procedure-in-sync": "Gratulálunk! Az eljárásrend szinkronban van.",
      "product-key": "Termékkulcs",
      "project": "Akta",
      "project-data-copied-to-clipboard": "Projekt adatok a vágólapra másolva!",
      "project-manager": "Projekt menedzser",
      "projectcolon": "Projekt:",
      "projecttwo": "Projekt",
      "property": "Property",
      "proposal-upper": "JAVASLAT",
      "qty": "Mennyiség",
      "rbk": "RBK",
      "reaction-time-colon": "Reakció idő:",
      "reapeater-question": "Ismétlédő?",
      "reason-for-change": "Változás oka",
      "reasoning": "Indkolás",
      "recommended-hourly-rate": "Ajánlott óradíj",
      "regexp": "RegExp",
      "region-properties": "Régió tulajdonságai",
      "related-device": "Kapcsolódó eszköz",
      "relation": "Reláció",
      "remaining-time": "Hátralévő idő (ó:p)\"",
      "remote-access": "Távoli elérések és hozzáférések",
      "repeating-colon": "Ismétlődés:",
      "result-product": "Eredmény termék",
      "result-product-colon": "Eredménytermék:",
      "resync-cron": "Újraszinkronizálás időzítés",
      "sataille": "Satellite",
      "saturday": "Szombat",
      "save-procedure": "Eljárásrend mentése",
      "save-procedure-success": "Az eljárásrend(ek) mentése sikeresen megtörtént.",
      "sd": "SD",
      "searcheable": "Kereshető",
      "secondary-duty-officer": "Másodlagos ügyeletes",
      "secondcurrency": "Pénznem",
      "select-address": "Válasszon címet",
      "select-building": "Válasszon épületet",
      "select-building-first": "Előbb válasszon címet",
      "select-customer-manager": "Válasszon ügyfél menedzsert",
      "select-designers": "Válasszon Tervezőket",
      "select-level": "Válasszon szintet",
      "select-level-first": "Előbb válasszon épületet",
      "select-location": "Válasszon lokációt",
      "select-location-first": "Előbb válasszon szintet",
      "select-partner-first": "Előbb válasszon partnert",
      "select-solvers": "Válasszon Megoldókat",
      "serial-number": "Gyári szám",
      "service-desk": "Service Desk",
      "service-period": {
        "dateError": "A tól ({from}) időnek kisebbnek kell lennie az ig ({to}) időnél"
      },
      "service-provider-supplier-colon": "Szolgáltató/Szállító:",
      "service-sat": "MENNYIRE ELÉGEDETT A SZOLGÁLTATÁSSAL?",
      "service-time": "Szolgáltatási időben",
      "serviceperiodcolon": "Szolgáltatási időszak:",
      "settlement": "Település",
      "settlementdate": "Elszámolás napja",
      "severity-levels": "Súlyossági szintek",
      "si-minimum-fulfillment-colon": "Sl teljesülés minimuma:",
      "skip-procedure": "Eljárásrend kihagyása",
      "sla-covered": "SLA fedett:",
      "sla-hour-colon": "óravál:",
      "sla-times-colon": "SLA idők:",
      "smb": "Kis,Közepes,Nagy",
      "solution-time-colon": "Megoldási idő:",
      "solution-time-two-colon": "Megoldás idő:",
      "solutions": "Megoldások",
      "solver": "Megoldó",
      "solvercolon": "Megoldó:",
      "solvers": "Megoldók",
      "start-of-contract": "Szerződés kezdete",
      "start-of-validity": "Érvényesség kezdete",
      "start-of-warranty": "Garancia kezdete",
      "start-time-colon": "Megkezdési idő:",
      "startendcycles": "Kezdés és Lejárat/Felmondhatósági idő különbsége (hónapban) / Számlázási ciklus (hónapban) * Ciklusonkénti ár",
      "starting-time": "Kezdő időpont",
      "state-of-service": "Szolgáltatás státusza",
      "statusesdots": "Státuszok ...",
      "subject": "Tárgy",
      "subject-of-application": "Igénylés tárgya",
      "subjectcolon": "Tárgy:",
      "sum-colon": "Összesen: ",
      "sunday": "Vasárnap",
      "supplier-deliver-notes": "Szállítólevél",
      "supplier-project-number": "Projekt azonosító",
      "supplier-reference": "Beszállítói hivatkozás",
      "suppliers": "Beszállítók",
      "swift": "SWIFT",
      "switches": "Kapcsolók",
      "syncronise-attributume": "Az attribútumot szinkronizáljuk a monitorozó rendszerrel",
      "system-code": "Rendszer kód",
      "szolg-eszk": "Szolg./Eszk.",
      "tariff": "Becsült érték. A ciklusonként ráfordított tervezett költség.",
      "task": "Feladat",
      "task-identifier": "Feladat azonosító",
      "task-state": "Feladat állapota",
      "task-status": "Feladat állapota",
      "tax": "Adó",
      "taxnumbercolon": "Adószám: ",
      "taxnumbereucolon": "Adószám Eu: ",
      "technical-contact": "Technikai bejelentő",
      "technicalmanageruser": "Technológiai ügyfél menedzser",
      "template": "Sablon",
      "templatecolon": "Sablon:",
      "templates": "Sablonok",
      "thursday": "Csütörtök",
      "ticket-code-format": "Jegy kód forma",
      "timed": "Időzíthető",
      "timing": "Időzítés",
      "title-of-work": "Munkavégzés címe",
      "tmbased-colon": "T&M alapú:",
      "to": "Dátum ig",
      "to-be-billed-outwards": "Kifelé kiszámlázandó",
      "totaly": "Teljes mértékben",
      "tuesday": "Kedd",
      "type": "Típus",
      "type-rounding": "Kerekítés típusa",
      "typecolon": "Típus:",
      "types": "Típusok",
      "unit-of-measure": "Mértékegység",
      "unit-price": "Egységár",
      "unitprice": "Egység ár",
      "uns": "Sürgős, Normál, Utólagos",
      "upload-items": "Tételek feltöltése",
      "upload-task": "Feladat feltöltése",
      "uploaded-file": "Feltöltött file",
      "urgencylevel": "Sürgősségi szint",
      "used": "Felhasznált",
      "user-it": "Használó",
      "user-name": "Felhasználónév",
      "user-pass": "Jelszó",
      "valid-offer-colon": "Érvényes ajánlat:",
      "valid-steps": "Érvényes lépések",
      "value": "Érték",
      "valuecurrency": "Érték pénzneme",
      "vat": "ÁFA",
      "vat-code": "Áfa kód",
      "vat-properties": "Áfa tulajdonságai",
      "vat-type": "Áfa típusa",
      "vat-value": "Áfa értéke",
      "vatrate": "Áfa kulcs",
      "version": "Verzió",
      "vip": "VIP",
      "vip-involvement": "Vip érintettség",
      "virtual": "Virtuális",
      "volumen": "Volumen",
      "warning-capital": "Warning",
      "warranty-expiration": "Garancia lejárat",
      "waterfall": "Waterfall",
      "wazuh": "Wazuh",
      "wednesday": "Szerda",
      "wiki-knowledge-base": "Wiki/Tudásbázis",
      "work-in-field": "Munkavégzés terepen?",
      "worklog-format": "Munkanapló forma",
      "worksheet-fixing-day": "Munkalap rögzítés (nap)",
      "worksheet-number": "Munkalap száma",
      "zip-code": "Irányítószám",
      "zone": "Zóna"
    },
    "general": {
      "abort": "Megszakítás",
      "activate": "Aktiválás",
      "active": "Aktív",
      "all": "Összes",
      "allowed-extensions": "Megengedett kiterjesztések:",
      "approval": "Jóváhagy",
      "approve": {
        "error": "Hiba a jóváhagyás közben",
        "success": "A jóváhagyás sikeresen megtörtént",
        "success-title": "Sikeres jóváhagyás"
      },
      "assign": "Kiosztás",
      "attribute": "Attribútum",
      "back": "Vissza",
      "back-to-top": "Lista tetejére",
      "beginning": "Kezdés",
      "clone": {
        "clone": "Duplikálás",
        "error": "Hiba a duplikálás közben!",
        "success": "Sikeres duplikálás!",
        "title": "Az új duplikált rekord létrejött"
      },
      "close": "Bezár",
      "close-down": "Lezárás",
      "complete": "Teljes",
      "completion": "Befejezés",
      "confirmation": "Rendben",
      "confirms": "Megerősít",
      "continue": "Folytatás",
      "create-entry": "Hozzon létre egyet",
      "creation": "Létrehozás",
      "delete": {
        "cant-delete": "Ez a rekord nem törölhető!",
        "cant-delete-constraint": "A rekordhoz tartozik {relation} kapcsolat {qty} elemmel",
        "cant-delete-constraint-warning": "⚠ Figyelem! A kiválasztott {nrOfSelection} elem közül {nrNotDeletable} nem lesz törölve, mert kapcsolódik hozzá(juk) aktív rekord",
        "error": "Hiba a törlés közben!",
        "success": "Sikeres törlés!",
        "success-title": "Törlés sikeresen megtörtént",
        "system-record": "System rekord nem törölhető!"
      },
      "deletion": "Törlés",
      "denomination": "Megnevezés",
      "download": "Letöltés",
      "enabled": "Engedélyezett",
      "error": "Hiba történt!",
      "etc": "stb",
      "examples": "Példák",
      "first": "Első",
      "generation": {
        "error": "Hiba a generáláskor!"
      },
      "goToDate": "Ugrás a dátumhoz",
      "here": "itt",
      "import": "Importálás",
      "inactive": "Inaktív",
      "inactive-doesnt-show": "Inaktív nem látszik",
      "inactive-show": "Inaktív látszik",
      "info": "Információ",
      "last": "Utolsó",
      "list-refreshed": "A lista frissült!",
      "loading": "Betöltés...",
      "loading-error": "Hiba a betöltésnél!",
      "maintenance": "Karbantartás",
      "modification": "Módosítás",
      "modify": "Módosít",
      "move": "Áthelyezés",
      "name": "Név",
      "new": "Új",
      "new-data": "Új adat",
      "no": "Nem",
      "no-data-available": "Nincs megjeleníthető adat",
      "no-entries": "Nincsenek bejegyzések",
      "open": "Nyitott",
      "over": "Vége",
      "pause": "Szüneteltetés",
      "pc": "{pc} db",
      "pdf-generate": "PDF generálása",
      "pdf-generate-error": "Hiba a PDF generálása közben",
      "pdf-generate-success": "A PDF generálása sikeresen megtörtént",
      "reactivate": "Reaktiválás",
      "reading": "Olvasás",
      "regex": {
        "wrong": "A megadott érték nem felel meg a regexnek {regex}"
      },
      "run-manually": "Futtatás kézzel",
      "save": {
        "error": "Hiba a mentés közben",
        "success": "A mentés sikeresen megtörtént",
        "success-title": "Sikeres mentés"
      },
      "save-and-sync": "Mentés és szinkronizálás",
      "select": "Kiválaszt",
      "selection": "Kiválasztás",
      "show-less": "Mutass kevesebbet",
      "show-more": "Mutass többet",
      "since": "{date} óta",
      "skip": "Kihagyás",
      "socket": {
        "id": "Socket azonosítója",
        "reconnected": "A websocket kapcsolat újra helyreállt!"
      },
      "summary": "Összefoglaló",
      "to": "-ig",
      "translation": "Fordítás",
      "unknown-error": "Ismeretlen hiba",
      "unsaved-changes": {
        "body": "Az űrlapon mentetlen adatok vannak. Biztos el akarja hagyni az űrlapot?",
        "title": "Mentetlen adatok"
      },
      "upload-error": "Hiba a {fileName} fájl feltöltése közben",
      "upload-image-instruction": "Tallózon be egy képet. Megengedett kiterjesztések:",
      "upload-instruction": "Húzza be a file-t a keretbe, vagy nyomjon rá és tallózza be!",
      "upload-queue": "A fájl a record mentése után töltődik fel majd",
      "warning": "Figyelem!",
      "yes": "Igen"
    },
    "hr": {
      "description": "Leírás: ",
      "description-event": "Az esemény leírása",
      "end": "Esemény vége",
      "event": "Esemény",
      "event-name": "Az esemény neve",
      "justification": "Indoklás",
      "name": "Név: ",
      "rejection": "Elutasítás indoklása: ",
      "start": "Esemény kezdete: ",
      "start2": "Esemény kezdete",
      "type": "Típus: ",
      "type2": "Típus",
      "urgent": "Sürgős: ",
      "user-name": "Felhasználónév: "
    },
    "hrm": {
      "absence": "távollét",
      "accept": "Elfogad",
      "already-accepted-holiday": "A megadott időszak valamelyik napjára már van elfogadott szabadságkérelem!",
      "createEvent": "Esemény létrehozása",
      "days": "Napok",
      "denied": "Elutasítva",
      "example": "Például",
      "few-words": "Elég pár szóban, de nem kötelező...",
      "grounds-refusal": "Elutasítás indoklása",
      "holidayRequestSent": "Szabadság kérelem elküldve!",
      "notEnoughHoliday": "Nincs elegendő szabadsága az időszakra!",
      "notEnoughHolidayTitle": "Kevés szabadság",
      "ownEvents": "Saját események",
      "rejects": "Elutasít",
      "resource-period": {
        "choose-period": "Válasszon egy időszakot",
        "close-planning": "Tervezés lezárása",
        "close-planning-confirmation": "Biztos benne, hogy le akarja zárni a tervezést?",
        "close-planning-succes": "A tervezés lezárása sikeresen megtörtént!",
        "openPlanning": "Tervezés megnyitása",
        "reopen-planning": "Tervezés újranyitása",
        "reopen-planning-confirmation": "Biztos benne, hogy újra akarja nyitni a tervezést?"
      },
      "resource-plan": {
        "overdue": "A felhasználónál túltervezés van!",
        "period-planned-hours": "Időszakra tervezett idő:",
        "tua-planned-hours": "Jegyre tervezett idő:"
      },
      "select-days": "Válasszon napokat a naptárból esemény létrehozásához!",
      "types": "Típus jelölések",
      "urgent": "Sürgős",
      "vacation": "nyaralás"
    },
    "human-resource-management": {
      "main": {
        "remaining-freedoms-colon": "Megmaradt szabadságok: ",
        "type-designations": "Típusjelölések"
      }
    },
    "import-incoming-invoice": {
      "abort-import": "Importálás megszakítása",
      "add-partner": "Partner hozzáadás",
      "add-partner-info": "A számlához tartozó partner nem létezik még az adatbázisban.",
      "checklist": {
        "automata": "A számla automatán keresztül lett importálva",
        "automata-info": "Töltse ki a hiányzó mezőket",
        "invoice": {
          "payment-method": "A számlához nem volt fizetési mód feltüntetve, Átutalás került beállításra"
        },
        "uom": "A mértékegység a NAV rendszerében {uom}.",
        "uom-own": "A szállító saját mértékegysége {uom}.",
        "vat-exemption": {
          "case": "Az adómentesség jelölés kódja {case}.",
          "reason": "Az adómentesség jelölés leírása {reason}."
        },
        "vat-out-of-scope": {
          "case": "Az Áfa tv. hatályán kívüliség kódja {case}.",
          "reason": "Az Áfa tv.hatályán kívüliség leírása {reason}"
        }
      },
      "checklist-info": "Az importálás során nem minden adat volt egyértelműen megfeleltethető. A számlát ellenőrizni kell. Pipálja ki a listát.",
      "checklist-title": "Ellenőrző lista",
      "import-invoice": "Számlák importálása",
      "import-invoice-subtitle": "Bejövő számlák importálása a NAV rendszeréből",
      "inDatabase": "Adatbázisban",
      "invoice-in-db": "A számla már létezik az adatbázisban",
      "missing-data": "Hiányzó adatok",
      "missing-data-info": "A NAV rendszerében nem tárolt adatok megadása",
      "partner-exists-info": "A számlához tartozó partner már létezik az adatbázisban.",
      "partner-in-db": "Partner már létezik az adatbázisban",
      "partner-not-in-db": "Partner még nem létezik az adatbázisban",
      "query": "Lekérés",
      "skip-invoice": "Számla kihagyása"
    },
    "incident-form": {
      "allocation": "Kiosztás",
      "attachments": "Csatolmányok",
      "business-impact": "ÜZLETI HATÁS",
      "business-service": "Üzleti szolgáltatás",
      "closed-ticket-comment": "Lezárt jegyen a kommunikáció nem lehetséges!",
      "cofc": "Close on First Call",
      "create-issue": "Bejelentés létrehozása",
      "enter-corn-rule": "Kérem adjon meg egy cron szabályt!",
      "getting-started": "MEGKEZDÉS",
      "getting-started-lc": "Megkezdés",
      "group": "Csoport",
      "individual": "Egyén",
      "me": "Én",
      "merged-tickets": "Összevont jegyek",
      "mode-notification": "Bejelentés módja",
      "news": "Aktualitások",
      "next-step": "Tovább",
      "operation-service-level": "Szerződéses szolgáltatás",
      "parent-ticket": "Szülő jegy",
      "planned-cost": "Becsült kötlség",
      "planned-grade": "Becsült munkakör",
      "planned-hours": "Becsült óra",
      "planned-resources": "Becsült erőforrások",
      "planned-resources-error": "Nem sikerült a becsült erőforrások meghatározása, mert az óradíj nem található",
      "priority-new-info-1": "Kérem válasszon ki sürgősséget és üzleti hatást!",
      "priority-new-info-2": "A kiválasztott sürgősség és üzleti hatás kombinációhoz nem tartozik szolgáltatási szint!",
      "reaction": "REAKCIÓ",
      "reaction-lc": "Reakció",
      "resolved-before-changing-status": "Megoldva állapotra váltás előtt személyre kell osztani az Incidenst!",
      "service-manager": "Szolgáltatás menedzser",
      "similar-tickets": "Hasonló jegyek",
      "solution": "MEGOLDÁS",
      "solution-lc": "Megoldás",
      "status": "Státusz",
      "subject-notification": "Bejelentés tárgya",
      "subject-notification-ci": "Bejelentés tárgyának a CI-je",
      "subtask": "Alfeladat",
      "urgency": "SÜRGŐSSÉG",
      "visualization": "Vizualizáció",
      "warning-before-closing-ticket": "Biztos benne, hogy le szeretné zárni a jegyet?",
      "work-log-save": "Munkalap mentés",
      "work-log-save-info": "Munkalap mentése sikeresen megtörtént"
    },
    "incoming-invoice": {
      "accountNumber": "Számlasorszám",
      "balance": "Egyenleg",
      "brutto": "Bruttó",
      "currency": "Pénznem",
      "dateFulfilled": "Teljesítés",
      "dateIssued": "Kelt",
      "datePaid": "Kifizetve",
      "datePaymentDue": "Fizetési határidő",
      "exchangeRate": "Árfolyam",
      "invoiceNetAmount": "A számla nettó összege a számla pénznemében",
      "invoiceOwner": "Számla tulajdonosa",
      "invoiceVatAmount": "A számla áfa összege a számla pénznemében",
      "modelName": "Bejövő számla",
      "navBrutto": "NAV bruttó",
      "navDifference": "Kerekítési különbözet",
      "navNetto": "NAV nettó",
      "needsAction": "Jóváhagyás szükséges",
      "netto": "Nettó",
      "partner": "Partner",
      "setExchangeRate": "Árfolyam alkalmazása",
      "simplified-invoice": "Egyszerűsített számla",
      "subject": "Tárgy",
      "supplierName": "Kiállító neve",
      "supplierTaxNumber": "Kiállító adószáma",
      "vat": "ÁFA"
    },
    "intervene": {
      "authorizers": "Engedélyezők",
      "authorizers-two": "Engedélyező(k)",
      "basic-data": "Alap adatok",
      "billed": "Számlázandó",
      "contacts": "Elérhetőségek",
      "control-subtasks": "Ellenőrzés alfeladatok",
      "control-task": "Ellenőrző feladatok",
      "cost": "Költség",
      "customer": "Ügyfél",
      "descriptions": "Leírások",
      "duration": "Időtartama (óra)",
      "file": "Akta",
      "imacd": "IMACD keretet csökkent {sum} óravál",
      "name": "Megnevezés",
      "new-stop": "Új leállási intervallum",
      "no-data-available": "Nincs megjeleníthető adat",
      "not-billed": "Nem számlázandó",
      "notifiers": "Értesítendők listája",
      "purpose": "Beavatkozás célja",
      "sla": "SLA fedett",
      "status": "Státusz",
      "subtasks": "Alfeladatok",
      "support-requested": "Igényelt helyszíni támogatás",
      "tandm": "T&M alapú",
      "task-name": "Feladat neve",
      "thread": "Menete",
      "time": "A beavatkozás ideje"
    },
    "inventory": {
      "chooseContacts": "Azonosító",
      "contact": "Munkatárs",
      "createDate": "Dátum",
      "inventoryContacts": "Munkatársak",
      "modelName": "Leltár",
      "partner": "Partner",
      "partnerNames": "Partnerek / Munkatársak",
      "resend": "Leltár újraküldése",
      "status": "Státusz",
      "stop": "Leállítva",
      "valid": "Érvényes"
    },
    "invoice": {
      "accountNumber": "Számlasorszám",
      "balance": "Egyenleg",
      "brutto": "Bruttó",
      "correcting-invoice": "{accountNumber} módosító számlája",
      "currency": "Pénznem",
      "dateExchangeRate": "Árfolyam napja",
      "dateFulfilled": "Teljesítés",
      "dateIssued": "Kelt",
      "datePaymentDue": "Fizetési határidő",
      "deleting-invoice": "{accountNumber} sztornó számlája",
      "editPreInvoice": "{id} előszámla szerkesztése",
      "endInvoice": "Végszámla",
      "info": "",
      "modelName": "Számla",
      "netto": "Nettó",
      "paidDays": "Kifizetés",
      "partner": "Partner",
      "preInvoice": "Előszámla",
      "saveItems": "Számla tételek mentése",
      "saveItemsError": "Hiba a tételek mentésekor",
      "sendToNAVError": "Hiba a számla beküldésekor",
      "sendToNAVSuccess": "A számla sikeresen beküldésre került a NAV-hoz. A tranzakció azonosítója: {response}",
      "sendingToNAV": "Számla beküldése a NAV-hoz",
      "subject": "Tárgy"
    },
    "invoice-type": {
      "code": "Kód",
      "formatCode": "Sablon",
      "modelName": "Számla típus",
      "name": "Név"
    },
    "labels": {
      "total": "Összesen"
    },
    "location": {
      "address": "Cím",
      "building": "Épület",
      "clientPartner": "Partner",
      "entities": "CI elemek",
      "floor": "Szint",
      "modelName": "Lokáció",
      "name": "Lokáció"
    },
    "matrix": {
      "first": "Első",
      "last": "Utolsó"
    },
    "menus": {
      "account": "Account",
      "add-to-news": "Hír készítése",
      "aiops": {
        "customers": {
          "customers": "Ügyfelek",
          "root": "Ügyfél adatok",
          "severance-pay-types": "Kötbér típusok"
        },
        "dashboards": {
          "aiops": "Rendszer",
          "competencies": "Kompetenciák",
          "customers": "Ügyfél adatok",
          "root": "Kimutatások",
          "solvers": "Megoldó adatok",
          "ticket-tracking": "Jegyek követése"
        },
        "data": {
          "api": "API végpontok",
          "excel": "Excel import",
          "interface": "Interfészek",
          "root": "Adatbetöltések"
        },
        "optimalizations": {
          "optimalization-defaults": "Alapértelmezett értékek",
          "optimalizations": "Optimalizáció",
          "root": "Optimalizáció"
        },
        "root": "AIOps",
        "source-data": {
          "customers": {
            "contract-types": "Szerződés típusok",
            "customers": "Ügyfelek",
            "root": "Ügyfelek",
            "severance-pay-types": "Kötbér típusok"
          },
          "root": "Forrás adatok",
          "solvers": {
            "root": "Megoldók",
            "solvers": "Megoldók",
            "user-absences": "Távolétek",
            "user-grades": "Kompetenciák"
          },
          "tickets": {
            "operation-service-levels": "Szolgáltatások",
            "root": "Jegyek",
            "ticket-status": "Jegy állapotok",
            "ticket-types": "Jegy típusok",
            "tickets": "Jegyek"
          }
        }
      },
      "api": "API végpontok",
      "availability-generate": "Rendelkezésre állási idők generálása",
      "beta": "Béta",
      "board": "Fedélzet",
      "change-log": "Fejlesztési napló",
      "change-requests": "Változás kezelés",
      "changes": "Változás kezelés",
      "close-report": "Lezárás",
      "closed-reports": "Lezárt jegyek",
      "cmdb": {
        "dashboard": "Irányítópult",
        "entity-types": "CMDB",
        "root": "CMDB"
      },
      "contracted": "Szerződött állomány",
      "contracts": "Szerződések",
      "correcting-invoice": "Helyesbítő számla",
      "crm": {
        "contacts": "Munkatársak",
        "departments": "Szervezeti egységek",
        "notification-groups": "Értesítési csoportok",
        "operational-file": "Üzemeltetési lehetőségek",
        "opportunities": "Projekt lehetőségek",
        "partners": "Partnerek",
        "root": "Partneri kapcsolatok",
        "sites": {
          "buildings": "Épületek",
          "floors": "Szintek",
          "locations": "Lokációk",
          "root": "Helyek",
          "site-zones": "Zónák"
        }
      },
      "cron-jobs": "Időzített munkák",
      "dashboard": "Áttekintés",
      "data": "Adatbetöltések",
      "day-types": "Nap típusok",
      "delegates": "Delegálások",
      "documents": "Dokumentumok",
      "edit": "Szerkesztés",
      "edit-integration": "Interáció szerkesztés",
      "edit-mapper": "Kötés szerkesztés",
      "edit-preticket": "Előbejelentés szerkesztése",
      "engineer-utility-report": "Mérnök utilizációs riport",
      "entities": "Eszközök",
      "excel": "Excel import",
      "fetchmail": "Fetchmail",
      "finances": {
        "bank-balances": "Banki egyenleg",
        "incoming-invoices": "Bejövő számlák",
        "invoices": "Számlák",
        "pipelines": "Pipeline",
        "root": "Pénzügy"
      },
      "forbidden": "Elutasítva",
      "former-reports": "Korábbi bejelentések",
      "forward-to-accounting": "Továbbítás a könyveléshez",
      "guarantees": "Garanciák",
      "human-resource-management": {
        "event-approvement": "Szabadságok jóváhagyása",
        "event-manager": "Események kezelése",
        "my-events": "Szabadságaim, távolléteim",
        "resource-periods": "Tervezési időszakok",
        "root": "HRM"
      },
      "impact-study": "Hatástanulmány",
      "index": "Irányítópult",
      "interface": "Interfészek",
      "inventories": "Leltárak",
      "invoice-payments": "Kifizetések",
      "licenses": "Licencek irányítópult",
      "list": "Lista",
      "manage-time-offs": "Szabadságok áttekintése",
      "moves": "Eszköz mozgások",
      "new": "Új",
      "new-aiops": "Új bejelentés",
      "new-contact": "Új kapcsolat",
      "new-entity": "Új eszköz",
      "new-incident": "Új bejelentés",
      "new-integration": "Új interáció",
      "new-mapper": "Új kötés",
      "new-task": "Új projekt feladat",
      "news-feed": "Hírfolyam",
      "not-found": "A keresett oldal nem található",
      "operation": {
        "access-requests": "Jogosultságigénylés",
        "application-to-intervene": "RBK kezelés",
        "change-requests": "Változás kezelés",
        "duty": "A nap hőse",
        "duty-calendar": "Naptár",
        "operational-file": "Üzemeltetés akta",
        "portal-dashboard": "Bejelentő",
        "root": "Üzemeltetés",
        "scheduled-tasks": "Ütemezett feladatok",
        "sd-dashboard": "Áttekintés"
      },
      "operation-service-level-type": "Szerződéses szolgáltatás típus",
      "operation-service-level-type-manager": "Szerződéses szolgáltatás karbantarása",
      "operations": "Üzemeltetés",
      "opportunities": "Lehetőségek",
      "optimization-item-types": "Optimalizációs paraméter típusok",
      "optimization-parameters": "Optimalizációs paraméterek",
      "organizations": {
        "root": "Helyek",
        "sites": {
          "buildings": "Épületek",
          "floors": "Szintek",
          "locations": "Lokációk",
          "root": "Helyek"
        }
      },
      "other-information": "Egyéb információk a bejelentőről",
      "overview": "Áttekintő",
      "parameter-categories": "Paraméter kategóriák",
      "parameter-types": "Paraméter típusok",
      "partner-bankcodes": "Bankszámlák",
      "partner-sectors": "Szektorok",
      "pipelines": "Pipeline",
      "plans": "Terveim",
      "pre-reports": "Előbejelentések",
      "procedures": "Eljárásrendek",
      "processed-reports": "Megoldás alatt",
      "projects": {
        "files": "Akták",
        "root": "Projekt monitoring",
        "statements": "Kimutatások"
      },
      "prompt": "Fizetési felszólítás",
      "remote-access-informations": "Távelérések és hozzáférések",
      "report": "Kimutatások",
      "report-as-another-user": "Bejelentés más nevében",
      "report-submit": "Új bejelentés",
      "reports": {
        "inventories": "Leltárak",
        "root": "Jelentések"
      },
      "sectors": "Szektorok",
      "settings": {
        "acl": {
          "acl": "ACL",
          "actions": "Tevékenységek",
          "finance-actions": "Pénzügyi jogok",
          "menus": "Menürendszer",
          "roles": "Szerepkörök",
          "root": "Jogosultságok",
          "users": "Felhasználók"
        },
        "aiops": {
          "day-types": "Nap típusok",
          "optimization-item-types": "Optimalizációs paraméter típusok",
          "optimization-parameters": "Optimalizációs paraméterek",
          "parameter-categories": "Paraméter kategóriák",
          "parameter-types": "Paraméter típusok",
          "partner-types": "Partner típusok",
          "partners": "Partner adatok",
          "root": "Beállítások – AIOps"
        },
        "cmdb": {
          "code-stores": "Szótárak",
          "contact-types": "Kapcsolat típusok",
          "entity-categories": "CI Kategóriák",
          "entity-statuses": "Eszköz státuszok",
          "entity-types": "CI típusok",
          "manufacturers": "Gyártók",
          "root": "CMDB",
          "types": "Eszköz típusok"
        },
        "cron-jobs": "Időzítések",
        "date-type-definitions": "Nap típus definíciók",
        "date-types": "Nap típusok",
        "document-types": "Dokument típusok",
        "finances": {
          "grade-costs": "Óradíjak",
          "invoice-categories": "Számla kategóriák",
          "invoice-types": "Számla típusok",
          "nav-settings": "NAV beállítások",
          "payment-methods": "Kifizetési módok",
          "payments": "Pénznem beállítások",
          "root": "Pénzügy",
          "tax-number-patterns": "Adószám regexek",
          "uoms": "Mértékegységek",
          "vats": "Áfa beállítások"
        },
        "integrations": {
          "mappers": "Kötések",
          "root": "Integrációk",
          "services": "Integrációk"
        },
        "invoices": {},
        "operations": {
          "fetchmail": "Fetchmail",
          "operation-service-level-type": "Szerződéses szolgáltatás típusok",
          "priority": "Prioritások",
          "priority-importance": "Üzleti hatás",
          "priority-matrix": "Prioritási mátrix",
          "priority-urgency": "Sürgősségek",
          "repair-claims": "Javítás jogcíme",
          "report-types": "Bejelentés típusok",
          "resource-groups": "Tervezési csoportok",
          "resource-plan-priorities": "Terv prioritások",
          "root": "Üzemeltetés",
          "service-areas": "Szolgáltatás területek",
          "service-levels": "Szolgáltatási szintek",
          "service-periods": "Szolgáltatási időszakok",
          "solving-group": "Megoldó csoportok",
          "statuses": "Státuszok",
          "ticket-audit-list": "Ellenőrzési lista"
        },
        "partners": {
          "countries": "Országok",
          "partner-sectors": "Szektorok",
          "root": "Partnerek"
        },
        "project": {
          "file-business-types": "Üzletágak",
          "file-states": "Akta státuszok",
          "file-types": "Akta típusok",
          "product-result-templates": "Előre definiált eredménytermékek",
          "profit-centers": "Profit centerek",
          "project-ticket-template-groups": "Előre definiált feladat csoportok",
          "project-ticket-templates": "Előre definiált feladatok",
          "root": "Akták"
        },
        "regions": "Régiók",
        "reports": "Jelentések",
        "root": "Beállítások"
      },
      "settings-entity-types": "CI típusok",
      "solved-reports": "Megoldott jegyek",
      "srepo": {
        "root": "SWRepo"
      },
      "statements": "Kimutatások",
      "storno": "Sztornó számla",
      "supports": "Támogatások",
      "tasks": {
        "availabilities": "Rendelkezésre állás",
        "goals-fulfillment": "Egyéni céljaim teljesülése",
        "root": "Feladataim",
        "tasks": "Feladataim",
        "work-logs": "Munkalapjaim"
      },
      "tasks-and-reports": "Feladatok, bejelentések",
      "ticket": "Incidens",
      "ticket-states": "Státuszok",
      "tickets": "Jegy adatok",
      "time-off": "Szabadságok",
      "user-grades": "Munkakörök",
      "view-pdf": "PDF megtekintése",
      "visualisation": "Vizualizácíó",
      "warranties": "Garanciák",
      "wiki": {
        "root": "Tudásbázis"
      }
    },
    "modals": {
      "clone-confirmation": {
        "error": {
          "info": "Hiba a duplikálás közben",
          "title": "Hiba történt"
        },
        "info": "Biztos benne, hogy duplikálni szeretné a kiválasztott rekordot?",
        "success": {
          "info": "Az új rekord létrejött!",
          "title": "Sikeres duplikálás!"
        },
        "title": "Rekord duplikálása"
      },
      "delete-confirmation": "Biztos benne, hogy törölni szeretné?",
      "file-upload": "Fájl feltöltés"
    },
    "models": {
      "accountManagerUser": "Ügyfél kapcsolattartó",
      "allSpentTime": "Minden ráfordított idő",
      "alreadyAssignedTimes": "Kiosztások száma",
      "amount": "Összeg",
      "amountUntaxed": "Összeg áfa nélkül",
      "approvalDate": "Elfogadás dátuma",
      "approximatelyValue": "Becsült érték",
      "billable": "Számlázható",
      "businessService": "Üzleti szolgáltatás",
      "calendar": "Naptár",
      "callAfterBaseHours": "Alapóra utáni hívás",
      "changeRequest": {
        "category": "CI kategória",
        "email": "Email",
        "end": "Vége",
        "entityTypeCategory": "CI típus",
        "entityTypeCategoryName": "CI típus neve",
        "name": "Név",
        "partner": "Partner",
        "partnerName": "Partner neve",
        "respond": "Visszajelzés",
        "solver": "Megoldó",
        "source": "Forrás",
        "start": "Kezdés",
        "ticket": "Bejelentés"
      },
      "checklist": "Checklist",
      "clientPartner": "Partner",
      "closed": "Lezárva",
      "code": "Kód",
      "completionCertSigner": "TIG aláíró (Partner)",
      "completionLevel": "Cél teljesül",
      "conditions": "Kondíciók",
      "contactDescription": "Kapcsolat leíráása",
      "contactId": "Kapcsolat",
      "continuous": "Folyamtos",
      "contract": "Szerződés",
      "cost": "Költség",
      "crVersion": "VK verzió",
      "createDate": "Létrehozás ideje",
      "createUid": "Létrehozó azonosító",
      "createuser": "Lehetőség rögzítő",
      "currencyId": "Pénznem azonosító",
      "currencyPayment": "Valuta",
      "customer": "Vevő",
      "dateCreate": "Létrehozva",
      "dateEnd": "Befejezés",
      "dateEndType": "Szerződés lejárata",
      "dateStart": "Kezdés",
      "defaultAmount": "Összeg (alap. pénznem)",
      "deletedAt": "Törölve",
      "description": "Leírás",
      "endDate": "Érvényesség vége",
      "evaluationNegative": "Mit kell máshogy csináljunk",
      "evaluationPositive": "Mi ment jól",
      "event": {
        "modelName": "Szabadság"
      },
      "expiredDays": "Lejárt számla napjai",
      "file": "Akta",
      "fileId": "Akta azonosító",
      "fileNumber": "Akta száma",
      "fileNumberIndex": "AktaszámIndex",
      "fileState": "Projekt státusz",
      "fileType": "Projekt típus",
      "fromDate": "Érvényesség kezdete",
      "hardwareDeliveryInMoney": "Eszköz volumen",
      "impactOfChange": "A változás hatása",
      "invoiceOwner": "Szállító",
      "invoicePayment": "Típus",
      "isActive": "Aktív",
      "isactive": "Engedélyezett",
      "lastTicketStateChange": "Utolsó státusz változás",
      "lotusCode": "Lotus kód",
      "managerUser": "Menedzser",
      "manufacturer": "Gyártó",
      "maxHoursPerWorkLog": "Max. óra munkalaponként",
      "mergedParent": "Összevont jegy szülője",
      "name": "Név",
      "newCustomer": "Új ügyfél",
      "operationServiceLevel": "Szerződéses szolgáltatás",
      "operationalFile": {
        "accountable": "Elszámolandó",
        "closed": "Lezárt",
        "endDate": "Lejárati dátuma",
        "fileBusinessType": "Üzletág",
        "fileOwnerPartner": "Akta tulajdonos cég",
        "manager": "Menedzser",
        "name": "Aktanév",
        "partner": "Partner",
        "primeContractor": "Ügyfél",
        "publicExternal": "Publikus (külső)",
        "publicInternal": "Publikus (belső)",
        "smtm": "SZM/TAM",
        "sponsor": "Szponzor",
        "status": "Státusz",
        "subcontractor": "Alvállalkozás",
        "type": "Típus",
        "volumen": "Volumen (ezer)"
      },
      "operationalManagerUser": "Operatív kapcsolattartó",
      "orderIndex": "Sorrend",
      "outgoing-email": "Kimenő e-mail",
      "ownerUser": "Tulajdonos",
      "paid": "Fizetve",
      "parentId": "Partner",
      "partner": "Ügyfél",
      "partnerId": "Partner azonosító",
      "partnerSector": "Szektor",
      "partnerSponsor": "Partner szponzor",
      "paymentDisciplineNegative": "Fizetési fegy. negatív",
      "paymentDisciplineNegativeDays": "Fizetési fegy. neg. napszám",
      "paymentDisciplineNegativeOne": "Fizetési fegy. neg.",
      "paymentDisciplinePositive": "Fizetési fegy. pozitív",
      "paymentDisciplinePositiveDays": "Fizetési fegy. poz. napszám",
      "paymentDisciplinePositiveOne": "Fizetési fegy. poz.",
      "paymentdays": "Fizetési napok",
      "periodicalSettlement": "Időszaki elszámolású",
      "planPeriodCloseDate": "Tervezés lezárásának dátuma",
      "plannedEndDate": "Tervezett befejezési idő",
      "plannedExpensesInHour": "Kiosztott órák",
      "plannedStartDate": "Tervezett kezdési idő",
      "plannedUserGrade": "Szerepkör",
      "plannedUserGradeId": "Szerepkör azonosító",
      "plannedValue": "Tervezett érték",
      "primeContractor": "Ügyfél",
      "priority": "Prioritás",
      "probability": "Valószínüség",
      "productResult": "Eredménytermék",
      "productResultId": "Eredménytermék azonosító",
      "project-name": "Projekt név",
      "projectValue": "Becsült bevétel",
      "publicId": "Szerződés azonosító",
      "reactionDeadline": "Reaction határidő",
      "refOfferId": "Referencia ajánlat",
      "referenceTicketId": "Kapcsolódó feladat",
      "region": "Régió",
      "regionId": "Régió azonosító",
      "remoteAccess": "Távoli elérés",
      "reportCloseMotivation": "Motiváció lezárása",
      "reportCloseProposal": "Bejövő ajánlat lezárása",
      "reportSubmit": "Új bejelentés",
      "reportTypes": "Bejelentés típus",
      "resolver": "Megoldó",
      "resolverId": "Megoldó",
      "serviceAreaId": "Szolgáltatási terület",
      "serviceDeliveryInMoney": "Szolgáltatás volumen",
      "serviceLevel": "Szolgáltatási szint",
      "serviceRating": "Szolgáltatás értékelés",
      "shortName": "Rövid név",
      "shortNametwo": "Rövidnév",
      "solveDeadline": "Megoldás határidő",
      "solvingGroup": "Megoldó csoport",
      "solvingGroupId": "Megoldó csoport",
      "sponsor": "Szponzor",
      "sponsorUser": "Szponzor",
      "startDeadline": "Megkezdés határidő",
      "state": "Állapot",
      "stateChange": "Utolsó státusz változás dátuma",
      "subcontractor": "Alvállalkozó",
      "subject": "Tárgy",
      "subsidiaries": "Leányvállalatok",
      "successful": "Sikeres",
      "sumPublicSpentTime": "Összesen látható ráfordított idő",
      "sumSpentTime": "Összesen ráfordított idő",
      "supplier": "Beszállító",
      "supplierPartnerId": "Támogató partner azonosító",
      "tax": "Adónem",
      "taxEu": "EU adónem",
      "taxnumber": "Adószám",
      "technicalAccountManagerUser": "Technikai kapcsolattartó",
      "ticketNumber": "Feladat száma",
      "ticketNumberIndex": "Feladatszám index",
      "ticketSourceId": "Feladat forrás azonosító",
      "ticketStateId": "Feladat állapot azonosító",
      "ticketStatusId": "Státusz",
      "ticketTypeId": "Típus azonosító",
      "type": "Típus",
      "unsaved-changes": "Figyelem! Még mentetlen változások!",
      "userGradeId": "Óradíj azonosító",
      "valid": "Érvényes",
      "valuePayment": "Érték pénzneme",
      "valuePeriod": "Érték periódusa",
      "vatId": "Áfa azonosító",
      "violationReaction": "Reakció idő",
      "violationSolve": "Megoldás idő",
      "violationStart": "Megkezdés idő",
      "warning": "Cél veszélyben?",
      "workLogNotification": "Email értesítés",
      "workplaceAddressId": "Munkavégzés helyének azonosítója"
    },
    "my-ticket": {
      "alreadyAssignedTime": "Felhasznált",
      "clientPartner": "Partner",
      "contact": "Bejelentő",
      "fileName": "Akta",
      "manager": "Menedzser",
      "modelName": "Feladataim",
      "operationServiceLevel": "Szerződéses szolgáltatás",
      "plannedEndDate": "Befejezés",
      "plannedExpensesInHour": "Kiosztott",
      "primeContractor": "Ügyfél",
      "priority": "Prioritás",
      "productResult": "Eredménytermék",
      "reactionDeadline": "Reaction határidő",
      "serviceLevel": "Szolgáltatás szint",
      "solveDeadline": "Megoldás határidő",
      "solver": "Érintettek",
      "startDeadline": "Megkezdés határidő",
      "state": "Feladat állapota",
      "subject": "Tárgy",
      "ticketNumber": "Feladat száma",
      "type": "Típus"
    },
    "nav-setting": {
      "demo": "Teszt környezet",
      "exchangeKey": "XML cserekulcs",
      "import": "Automata import",
      "login": "NAV technikai felhasználónév",
      "modelName": "NAV beállítások",
      "partner": "Partner",
      "pass": "Technikai felhasználó jelszava",
      "signatureKey": "XML aláíró kulcs",
      "taxNumber": "Adószám"
    },
    "news": {
      "contents": "Tartalom",
      "createDate": "Létehozás ideje",
      "developerNews": "Fejlesztési napló frissítés",
      "developerNewsContents": "Fejlesztési napló frissítve, kattints a címre a részletekért.",
      "lastUpdate": "Utolsó módosítás ideje",
      "subject": "Téma"
    },
    "notification-group": {
      "contact": "Munkatárs",
      "inside_the_zone": "Zónán belüli értesítés",
      "modelName": "Értesítési csoport",
      "name": "Név",
      "notificationGroupType": "Értesítés típusa",
      "partner": "Partner"
    },
    "operation": {
      "access-request": {
        "new-request": "Új igénylés"
      },
      "change-request": {
        "accepted": "Elfogadva",
        "add-ci-elem": "CI elem hozzáadása",
        "add-person": "Személy hozzáadása",
        "add-task": "Feladat hozzáadása",
        "approve-request": "Véglegesítés",
        "approver": "Jóváhagyó",
        "back": "Vissza a listához",
        "canClose": "Ha kívánja, lezárhatja a változás kezelést",
        "change-manager": "Változás menedzser",
        "changeProcess": "Változás menete",
        "changeSuccess": "Sikeres változtatás!",
        "check-task": "Ellenőrző feladat",
        "clone": "Változás kérelem duplikálása",
        "clone-info": "Biztos benne, hogy duplikálni szereté a változás kérelemt?",
        "close-change-request": "Változás kérelem lezárása",
        "close-change-request-confirm": "Biztos benne, hogy le akarja zárni a változás kérelmet?",
        "closeSuccessfull": "Sikeres lezárás!",
        "contact": "Értesítendő",
        "crApproved": "Sikeres véglegesítés!",
        "crCloseError": "Hiba a <b>{changeRequest}</b> változás kérelem lezárása közben!",
        "crCloseSuccess": "A <b>{changeRequest}</b> változás kérelem lezárása megtörtént!",
        "crSaved": "A változás kérelem elmentve",
        "declined": "A változás kérelem már el lett utasítva",
        "edit-task": "Feladat szerkesztése",
        "emailsSent": "Az emailek sikeresen kiküldésre kerültek!",
        "errorInRequest": "Hiba az érintett elemek lekérdezése közben!",
        "evaluation": "Kiértékelés",
        "feedback-processing": "Kis türelmet, visszajelzése feldolgozás alatt",
        "file": "Akta",
        "from": "Kezdés",
        "fromPartner": "Partner részéről",
        "fromProvider": "Szolgáltató részéről",
        "fromThirdParty": "Harmadik fél",
        "generated-incidents": "Keletkezett bejelentések",
        "handle": "Kezelés módja",
        "id": "Azonosító",
        "impact": "Hatás",
        "mainData": "Alap adatok",
        "missing-service-levels": "Figyelem! A megadott üzleti szolgáltatáshoz nincsen Változás típusú szolgáltatási szint! Kérjük vegye fel a kapcsolatot az akta szolgáltatás menedzserével!",
        "name": "Név",
        "new": "Új változás kérelem",
        "new-cr-title": "Az új változás kérelem létrejött!",
        "noAnswer": "Nincs válasz",
        "only-draft-can-be-deleted": "Csak Draft állapotban lehet törölni!",
        "operationServiceLevel": "Üzleti szolgáltatás",
        "person": "Személy",
        "personAdded": "A személy sikeresen hozzá lett adva!",
        "personalDependencies": "Személyi feltételek",
        "persons-approve": "Jóváhagyók",
        "persons-notify": "Értesítendők",
        "please-choose-a-person": "Kérem válasszon ki egy személyt",
        "please-choose-a-resolver": "Kérem válasszon ki egy megoldót",
        "priority": "Prioritás",
        "priorityName": "Prioritás",
        "rejected": "Elutasítva",
        "risk-default": "Beavatkozás elmaradásainak kockázatai",
        "risk-infra": "Kapcsolódó rendszereket érintő kockázatok",
        "risk-intervention": "Beavatkozás kockázatai",
        "risks": "Kockázatok",
        "select-ci-elem": "CI elem kiválasztása",
        "select-ci-elem-help": "A sorra kattintva tudja az elemet kiválasztani / törölni",
        "source": "Forrás",
        "state": "Státusz",
        "successful": "Sikeres",
        "to": "Befejezés",
        "token-valid": "Jóváhagyás határideje"
      },
      "choose-main-announcement": "Válassza ki a fő bejelentést",
      "closing": "Lezárás",
      "currently-no-announcements": "Jelenleg nincs bejelentés",
      "currently-no-pre-announcements": "Jelenleg nincs bejelentés",
      "currently-no-solution": "Jelenleg nincs megoldás allatt álló jegy",
      "main": {
        "all-subtasks": "Összes alfeladat",
        "appendix": "Melléklet",
        "assigned-tasks": "Kiosztott feladatok",
        "auto-monitoring": "Auto-monitoring",
        "change-priority": "Priorítás változtatása",
        "change-priority-text": "Figyelem! A prioritás változtatással az SL idők újra számolódnak. Szigorítás esetén pedig újra indul a számítás. Biztos benne, hogy folytatni szeretné?",
        "contract-is-expired": "Szerződés lejárt",
        "contract-is-valid": "Szerződés érvényes",
        "control-subtasks": "Ellenőrőz alfeladatok",
        "critical-annoucements": "Kritikus bejelentések",
        "desk-statistic": "Fedélzet statisztikái",
        "feedbacked": "Visszajelzett",
        "new-rbk": "Új RBK",
        "not-subcontracting": "Nem alvállalkozás",
        "notes": "Jegyzetek",
        "number": "{number}",
        "own-tasks": "Saját feladatok",
        "ppriority": "P{priority}",
        "pre-notification-statistic": "Előbejelentések statisztikái",
        "priority": "P{priority}",
        "sl-time-badge": {
          "tooltip": "{datetime}",
          "tooltip-in-24-hours": "{datetime} - 24 órán belül!",
          "tooltip-violated": "{datetime} - Sértett!",
          "tooltip-violated-label": "Sértett!"
        },
        "sla-updated": "SLA feltöltve",
        "subcontracting": "Alvállalkozás",
        "subtasks": "Alfeladatok",
        "suspend": "Felfüggesztve",
        "task-notification-satistics": "Feladatok, bejelentések statisztikái",
        "web-form": "Web-form"
      },
      "no-pre-announcements": "Jelenleg nincs előbejelentés",
      "no-tickets": "Jelenleg nincs lezárt jegy",
      "notification-merger": "Bejelentés összevonás",
      "notification-merger-info": "A fő jegy alá lesz a többi jegy besorolva. Ha a többi jegy között van olyan, amely eddig főjegy volt, annak az aljegyei is át lesznek csopportosítva a most megadott főjegyhez.",
      "notification-merger-success": "A bejelentés összevonás sikeresen megtörtént",
      "operational-file": {
        "add-service-contract": "Szolgáltatási szint hozzáadása",
        "edit-service-contract": "Szolgáltatási szerződés szerkesztése",
        "its-edit": " szerkesztése",
        "new-day-type": "Új nap típus",
        "new-service-contract": "Új szerződéses szolgáltatás",
        "new-service-time": "Új szolgáltatási idő"
      },
      "portal-dashboard": {
        "new-notification": "Új Bejelentés",
        "view": "Megtekintés"
      },
      "reason-suspension": "Felfüggesztés oka",
      "reason-suspension-removal": "Felfüggesztés megszüntetésének oka",
      "report-submit": {
        "create-notification": "Bejelentés szerkesztése",
        "edit-shedule": "Ütemezés szerkesztése",
        "fill-reason": "Kérem töltse ki az indokot!",
        "new-schedule": "Új Ütemezés",
        "new-subtask": "Új részfeladat",
        "new-worksheet": "Új munkalap",
        "remote-access-notific": "Bejelentéshez tartozó távelérések és hozzáférések",
        "subtaskAdded": "Részfeladat hozzáadva!"
      },
      "restoration": "Visszaállítás",
      "restoration-success": "A visszaállítás sikeresen megtörtént",
      "restore-selected-notifications": "Biztos benne, hogy visszaállítja a kijelölt bejelentéseket?",
      "scheduled-tasks": {
        "choose-main-announcement": "Válassza ki a fő bejelentést",
        "generate": "Feladat létrehozása",
        "generate-error": "Hiba a feladat generálása közben!",
        "generate-info": "Biztos benne, hogy létre akarja hozni a feladatot?",
        "generate-success": "A feladat sikeresen legenerálódott",
        "generate-success-info": "Az új feladat létrejött {id} ID-val!",
        "new": "Új ütemezett feladat"
      },
      "sd-dashboard": {
        "customView": "Egyedi nézet",
        "liveOff": "Élő kikapcsolása",
        "liveOn": "Élő bekapcsolása",
        "mergeTicketsError": "Válasszon ki legalabb két elemet az összevonáshoz",
        "mergeTicketsErrorUnique": "A kiválasztott elemek bejelentői különböző partnerekhez tartoznak, az összevonás nem lehetséges!",
        "preTicket": "Előbejelentés",
        "preTickets": "Előbejelentések",
        "preTicketsWithNumber": "Előbejelentések ({numberOfPreTickets})",
        "repair-ticket": "Szervizjegyek",
        "settings-load-error": "A bejelentkezett felhasznalóhoz több socket-io bejegyzés tartozik"
      },
      "service-period": {
        "add": "Hozzáad",
        "change-type-alert": "Figyelem! A változás kérelemből generálódott jegyeknél 24/7 szolgáltatási idő lesz alkalmazva",
        "name": "Megnevezés",
        "office-time": "Idő intervallumok",
        "remove": "Elvesz",
        "service-periods": "Szolgáltatási időszakok"
      },
      "solve": "Megold",
      "solve-change": "Megoldottra állítás",
      "solved": "Megoldva",
      "solved2": "megoldott",
      "sure": "Biztos, hogy",
      "want-to-set": "állapotra szeretné állítani?",
      "worksheets": "Munkalapok"
    },
    "operation-service-level": {
      "businessService": "Üzleti szolgáltatás",
      "imac": "IMAC",
      "imacLimit": "IMAC limitje",
      "modelName": "Szerződéses szolgáltatás",
      "name": "Megnevezés",
      "operationServiceLevelType": "Szerződéses szolgáltatás típus",
      "slSuccessMinimum": "Sl teljesülés minimuma",
      "solvingGroup": "Megoldó csoport"
    },
    "operation-service-level-type": {
      "modelName": "Szerződéses szolgáltatás típus",
      "name": "Megnevezés"
    },
    "operational-file": {
      "modelName": "Üzemeltetési akta",
      "name": "Szolgáltatás neve"
    },
    "opportunities": {
      "executed": "Végrehajtva",
      "new-file": "Új akta",
      "offer": "Ajánlat",
      "ordered": "Megrendelt",
      "paid": "Kifizetve, lezárva",
      "possibility": "Lehetőség"
    },
    "organizations": {
      "main": {
        "new-coworker": "Új Munkatárs",
        "new-partner": "Új partner",
        "partners-tools": "Partnerek / Eszközök"
      },
      "partners": {
        "contact-departments-warning": "⚠️ <i><b>Figyelem!</b> Újonnan létrehozott szervezeti egységet csak mentett partner/szervezeti egység esetén lehet választani!</i>",
        "edit-organization": "Szervezet szerkesztése"
      },
      "sites": {
        "add-new-zone": "Új zóna felvétele",
        "edit-building": "Épület szerkesztése",
        "edit-level": "Szint szerkesztése",
        "edit-location": "Lokáció szerkesztése",
        "edit-zone": "Zóna szerkesztése",
        "new-building": "Új épület",
        "new-level": "Új szint",
        "new-location": "Új lokáció"
      }
    },
    "pagination": {
      "next": "Következő",
      "prev": "Előző"
    },
    "partner": {
      "accountManagerUser": "Ügyfél kapcsolattartó",
      "approved": "Jóváhagyva",
      "approximatelyValue": "Becsült érték",
      "callAfterBaseHours": "Alapóra utáni hívás",
      "checklist": "Checklist",
      "children": "Leányvállalatok",
      "code": "Kód",
      "conditions": "Kondíciók",
      "contactDescription": "Kapcsolat leíráása",
      "contactId": "Kapcsolat",
      "currencyPayment": "Pénznem",
      "customer": "Vevő",
      "dateEndType": "Szerződés lejárata",
      "deletedAt": "Törölve",
      "domesticPartner": "Hazai/Külföldi",
      "email": "Email",
      "expiredDays": "Lejárt napok",
      "financeContactEmail": "Pénzügyi kapcsolat",
      "impactOfChange": "A változás hatása",
      "invoicePayment": "Fizetési mód",
      "isActive": "Aktív",
      "isactive": "Engedélyezett",
      "kam": "Ügyfélfelelős",
      "lotusCode": "Lotus kód",
      "maxHoursPerWorkLog": "Max. óra munkalaponként",
      "modelName": "Partner",
      "multiOwnerPartner": "Saját cég",
      "name": "Név",
      "notPaidInvoicesTotal": "Kintlévőség (ezer)",
      "operationalManagerUser": "Operatív kapcsolattartó",
      "parent": "Leányvállalat",
      "parentId": "Partner",
      "partner": "Ügyfél",
      "partnerSector": "Szektor",
      "partnerTypes": "Típusok",
      "paymentDays": "Fizetési napok",
      "paymentDisciplineNegative": "Fizetési fegy. negatív",
      "paymentDisciplineNegativeDays": "Fizetési fegy. neg. napszám",
      "paymentDisciplineNegativeOne": "Fizetési fegy. neg.",
      "paymentDisciplinePositive": "Fizetési fegy. pozitív",
      "paymentDisciplinePositiveDays": "Fizetési fegy. poz. napszám",
      "paymentDisciplinePositiveOne": "Fizetési fegy. poz.",
      "paymentdays": "Fizetési napok",
      "paymentmethod": "Fizetési mód",
      "periodicalSettlement": "Időszaki elszámolású",
      "plannedValue": "Tervezett érték",
      "potential": "Gördülő potenciál (ezer)",
      "publicId": "Szerződés azonosító",
      "refOfferId": "Referencia ajánlat",
      "referenceTicketId": "Kapcsolódó feladat",
      "remoteAccess": "Távoli elérés",
      "reportCloseMotivation": "Motiváció lezárása",
      "reportCloseProposal": "Bejövő ajánlat lezárása",
      "reportSubmit": "Új bejelentés",
      "reportTypes": "Bejelentés típus",
      "serviceAreaId": "Szolgáltatási terület",
      "serviceRating": "Szolgáltatás értékelés",
      "shortName": "Rövid név",
      "shortNametwo": "Rövidnév",
      "solvingGroupId": "Megoldó csoport",
      "state-market": "Állami/piaci",
      "subsidiaries": "Leányvállalatok",
      "tax": "Adószám / Adóazonosító Jel",
      "taxEu": "EU adószám",
      "taxGroup": "Csoportos Adószám",
      "technicalAccountManagerUser": "Technikai kapcsolattartó",
      "ticketStatusId": "Státusz",
      "valuePayment": "Érték pénzneme",
      "valuePeriod": "Érték periódusa",
      "volumen": "Eddigi volumen (ezer)",
      "yearlyPotential": "Éves potenciál (ezer)"
    },
    "partner-bankcode": {
      "bankcode": "Bankszámlaszám",
      "bankname": "Bank neve",
      "clientPartner": "Partner",
      "currencyPayment": "Pénznem",
      "defaultBank": "Alapértemezett",
      "iban": "IBAN",
      "modelName": "Bankszámla",
      "swift": "SWIFT"
    },
    "partner-sector": {
      "modelName": "Szektor",
      "name": "Név",
      "polity": "Állami"
    },
    "payment": {
      "accountCredit": "Főkönyvi szám: tartozik",
      "accountDebit": "Főkönyvi szám: követel",
      "cashbookId": "Pénztárkönyv azonosítója",
      "code": "Valuta kód",
      "exchangeRate": "Váltás aránya",
      "exchangeRound": "Váltási arány tizedes jegyeinek száma",
      "exchangeUnit": "Váltási arány egysége",
      "modelName": "Valuta beállítás",
      "name": "Valuta neve",
      "round": "Kerekítés mértéke",
      "roundType": "Kerekítés típusa",
      "systemFlag": "Fő fizetési mód",
      "type": "Fizetés típusa"
    },
    "payment-method": {
      "code": "Kód",
      "modelName": "Kifizetési mód",
      "name": "Megnevezés"
    },
    "paymentTypes": {
      "bankCard": "Bankkártya",
      "billing": "Számlázás",
      "cash": "Készpénz",
      "coupon": "Coupon",
      "fulfilBilling": "Számla pénzügyi teljesítés",
      "loss": "Veszteség",
      "other": "Egyéb"
    },
    "pick-list": {
      "selected-element": "Kijelölve {length} Kijelölve"
    },
    "preInvoiceGenerationSuccess": "Az előszámla legenerálódott {subject} névvel és {id} azonosítóval",
    "priority": {
      "critical": "Kritikus",
      "high": "Magas",
      "low": "Alacsony",
      "modelName": "Prioritás",
      "name": "Név",
      "normal": "Közepes",
      "value": "Érték",
      "veryLow": "Nagyon alacsony"
    },
    "priority-importance": {
      "modelName": "Üzleti hatás",
      "name": "Név",
      "value": "Érték"
    },
    "priority-matrix": {
      "modelName": "Prioritás mátrix",
      "name": "Név",
      "priority": "Prioritás",
      "priorityImportance": "Üzleti hatás",
      "priorityUrgency": "Sürgősség"
    },
    "priority-urgency": {
      "modelName": "Sürgősség",
      "name": "Név",
      "value": "Érték"
    },
    "product-result-template": {
      "active": "Aktív",
      "billable": "Számlázandó",
      "description": "Leírás",
      "modelName": "Előre definiált eredménytermék",
      "name": "Név"
    },
    "profit-center": {
      "code": "Kód",
      "modelName": "Profit center",
      "name": "Név"
    },
    "project": {
      "accountable": "Elszámolandó",
      "clientPartner": "Partner",
      "closed": "Akta lezárása",
      "comment": "Megjegyzés",
      "commission": "Jutalék",
      "completionLevel": "Cél",
      "createNewProductResult": "Új eredménytermék létrehozása",
      "dateEnd": "Befejezése",
      "dateStart": "Kezdete",
      "fileNumber": "Aktaszám",
      "fileOwnerPartner": "Akta tulajdonosa",
      "fileOwnerPartnerCompany": "Akta tulajdonos cég",
      "filePlanners": "Tervezők",
      "fileSolverGroups": "Megoldó csoportok",
      "fileSolvers": "Megoldók",
      "fileState": "Státusz",
      "fileSuppliers": "Beszállítók",
      "import": {
        "error": "Hiba történt a tételek importálása közben. Kérlek próbáld újra később.",
        "success": "Tételek importálása megtörtént."
      },
      "investmentPlanned": "Terv",
      "managerUser": "Menedzser",
      "maxHoursPerWorkLog": "Max rögzíthető (óra)",
      "modelName": "Akta",
      "name": "Projekt név",
      "noEndDate": "-",
      "noStartDate": "-",
      "onBudgetInHour": "Kk(ó)",
      "onBudgetInHourPerOnGoal": "Kk(ó)/C",
      "onBudgetInValue": "Kk(é)",
      "onBudgetInValuePerOnGoal": "Kk(é)/C",
      "onTimePerOnGoal": "Idő/Cél",
      "onTimePercent": "Idő (%)",
      "opportunitites": "Aktanév",
      "orderNumber": "Megrendelés azonosító",
      "overHead": "Overhead",
      "overwriteProductResult": "Új eredménytermék létrehozása",
      "plannedBudgetMoneyThousand": "Súlyozott bevétel (ezer)",
      "primeContractor": "Ügyfél",
      "projectTicketTemplateGroupId": "Előre definiált feladatok",
      "projectValue": "Becsült bevétel",
      "projectValueThousand": "Becsült bevétel (ezer)",
      "providerCompletionCertSigner": "TIG aláíró (Szolgáltató)",
      "publicExternal": "Publikus (külső)",
      "publicInternal": "Publikus (belső)",
      "registrationDayLimit": "Munkalap rögzítés (nap)",
      "report": "Riport",
      "salesManager": "Értékesítési menedzser",
      "serviceDesk": "Service Desk",
      "sponsorUser": "Szponzor",
      "subcontractor": "Alvállalkozás",
      "tariff": "Tarifa",
      "technicalManagerUser": "Technológiai ügyfél menedzser",
      "tickets": "Feladatok",
      "workLogs": "Munkalapok"
    },
    "project-ticket": {
      "generate-project-tickets": "Sablon feladatok betöltése",
      "info": "Figyelem! A sablon alapján {ticketCount} feladat lesz automatikusan létrehozva!"
    },
    "project-ticket-template": {
      "active": "Aktív",
      "description": "Leírás",
      "emailNotification": "Email értesítés",
      "maxHoursPerWorkLog": "Max rögzíthető (óra)",
      "modelName": "Előre definiált feladat",
      "name": "Név",
      "plannedEndDate": "Befejezés dátuma",
      "plannedExpensesInHour": "Terv ráfordítás órában",
      "plannedUserGrade": "Szerepkör",
      "priority": "Prioritás",
      "productResultTemplate": "Eredménytermék",
      "projectTicketTemplateGroup": "Csoport",
      "subject": "Tárgy"
    },
    "project-ticket-template-group": {
      "active": "Aktív",
      "description": "Leírás",
      "modelName": "Előre definiált feladat csoport",
      "name": "Név",
      "operational": "Üzem akta",
      "operationalOpportunity": "Üzem akta lehetőség",
      "project": "Akta",
      "projectOpportunity": "Akta lehetőség"
    },
    "projects": {
      "actual-cost": "Tényleges költség",
      "categorize-file": "Akta kategórizálása",
      "change-request": "Változási kérelem",
      "change-request-started": "Az aktához van változás kérelem elindítva!",
      "change-story": "Változás történet",
      "changed-cost": "Változott költség",
      "collapse": "Collapse",
      "completion-certificate-generate-error": "Hiba a generáláskor!",
      "completion-certificate-insure": "Mint a {name} elnevezésű projekt teljesítés elfogadására jogosult személye igazolom, hogy a Megrendelő az elvégzett munkákat, szolgáltatásokat és eszközöket elfogadja, szerződésszerűen teljesítettnek tekinti. A továbbiakban a teljesítéssel kapcsolatban kifogással nem él.",
      "completion-certificate-sum": "A Szolgáltató jogosult az alább részletezett tételek alapján a számlát kiállítani.",
      "completion-certificate-warning": "Az ajánlathoz már tartozik TIG és előszámla. Ha újat szeretne generálni, akkor a korábbiak törlésre kerülnek. Biztosan folytatja?",
      "expand": "Expand",
      "files": {
        "accept-change-request": "Változás kérelem elfogadása",
        "accountable-tooltip": "Az elszámolandó aktán előforduló költségekről automatikus jelentést küld a rendszer e-mail formában.",
        "activities": "Tevékenységek",
        "closed-and-overbooked": "Lezárva és túlkönyvelve",
        "closed-on-time": "Időben lezárva",
        "contact-departments-warning": "⚠️ <i><b>Figyelem!</b> A sablon alapján <b>{ticketCount}</b> feladat lesz automatikusan létrehozva!</i>",
        "evaluation": "Értékelés",
        "execution": "Véghezvitel",
        "expired": "késésben",
        "file-state-change-info": "Ha a lehetőség státuszát Megrendeltre állítja, akkor a mentés után az akta a Projekt monitoring / Akták menüpont alatt fog listázódni a továbbiakban!",
        "hours": "Óra",
        "hours-active": "Aktív órák száma",
        "hours-allocated": "Kiosztott órák száma",
        "hours-inactive": "Inaktív órák száma",
        "hours-overbooked": "Túlkönyvelt órák száma",
        "incident-worksheets": "Incidenshez tartozó munkalapok",
        "kk-goal": "KK(é) / Cél",
        "no-billing-address": "Figyelem! Az aktához kiválasztott ügyfélnek nincs megadva számlázási cím.",
        "no-billing-address-title": "Nincs megadva számlázási cím",
        "no-completion-cert-signer": "Figyelem! Nincs TIG aláíró kiválasztva az Akta fülön. Kérem adjon meg egy aláírót.",
        "number-of-tickets": "Folyamatban lévő feladatok",
        "overbooked-hh-pc": "Túlkönyvelt (ó/db)",
        "overbooked-tasks": "Túlkönyvelt feladatok",
        "partner-data": "Partner adatok",
        "print-performance-certificate": "Teljesítmény igazolás nyomtatása",
        "progres-and-in-time": "Folyamatban és időben",
        "progres-and-overbooked": "Folyamatban és túlkönyvelve",
        "public-external-tooltip": "Publikus üzemeltetés aktára bejelenthet minden portal felhasználó.",
        "public-internal-tooltip": "Publikus üzemeltetés aktára bejelenthet minden belső felhasználó.",
        "rejection-change-request": "Változás kérelem elutasítása",
        "restores": "Visszaállít",
        "satisfaction": "Elégedettség",
        "sliding-result-products": "Csúszó eredeménytermékek",
        "tabs": {
          "acl": "Jogosultságok",
          "attachments": "Mellékletek",
          "balance": "Egyenleg",
          "calendar": "Naptár",
          "competencies": "Szerepkörök",
          "contract": "Szerződéses szolgáltatások",
          "day-types": "Nap típusok",
          "file": "Akta",
          "finance": "Pénzügy",
          "finance-data": "Számlázási adatok",
          "invoices": "Számlák",
          "items": "Tételek",
          "main-data": "Alap adatok",
          "mainTickets": "Jegyek",
          "others": "Egyéb",
          "outgoing-proposals": "Kimenő ajánlatok",
          "partner-data": "Ügyfél adatok",
          "product-results": "Eredmény termékek",
          "proposals": "Bejövő ajánlatok",
          "service-periods": "Szolg. időszakok",
          "settings": "Beállítások",
          "sla": "Szerződés",
          "tandm": "T&M",
          "ticket": "Bejelentések",
          "tickets": "Feladatok",
          "user-grade": "Óradíjak",
          "work-logs": "Munkalapok"
        },
        "task": "Feladat",
        "tasks-overbooked": "Túlkönyvelt feladatok száma",
        "time-goal": "Idő / Cél",
        "user": "Felhasználó",
        "user-data": "Felhasználói adatok"
      },
      "main": {
        "contract-in-order": "Szerződés rendben van",
        "copy-file-data-to-clipboard": "Akta adatainak másolása",
        "incoming-offer": "Bejövő ajánlat",
        "incoming-offer-ok": "Bejövő ajánlat rendben van",
        "incoming-offer-required": "Bejövő ajánlat szükséges",
        "live": "LIVE",
        "order": "Megrendelés",
        "order-in-order": "Megrendelés rendben van",
        "order-in-order-two": "Megrendelés rendben",
        "order-is-required": "Megrendelés szükséges",
        "outgoing-offer": "Kimenő ajánlat",
        "outgoing-offer-ok": "Kimenő ajánlat rendben van",
        "outgoing-offer-required": "Kimenő ajánlat szükséges",
        "planning": "Tervezés",
        "planning-ok": "Tervezés renben van",
        "planning-required": "Tervezés szükséges",
        "vk": "VK"
      },
      "main-data": "Alap adatok",
      "missingProposal": "Nincs érvényes kimenő ajánlat.",
      "original-cost": "Eredeti költség",
      "planned-cost": "Tervezett költség",
      "please-select-user": "Kérem válasszon felhasználót!",
      "product": "Termék",
      "project-tickets": {
        "generate-fail": "Hiba a feladatok generálásakor",
        "generate-success": "Sikeresen legenerálódtak a feladatok"
      },
      "result-products": "Eredmény termékek",
      "tickets": {
        "closed-task": "Lezárt feladatok",
        "completion-certificate": "Teljesítés igazolás",
        "confirmation": "Megerősítés",
        "hours-cannot-be-allocated": "0 órát nem lehet kiosztani",
        "open-task": "Nyitott feladatok",
        "result-products": "Eredménytermékek",
        "result-products-managment": "Eredménytermékek kezelése",
        "tasks": "Feladatok"
      }
    },
    "push-notification": {
      "messageId": "Üzenet Firebase azonosítója",
      "reaction": "Reakció az értesítésre"
    },
    "region": {
      "code": "Kód",
      "fileCodeFormat": "Fájl kód formátum",
      "files": "Akta",
      "modelName": "Régió",
      "name": "Név",
      "ownerPartner": "Partner",
      "regionalSettings": "Régió beállítások",
      "ticketCodeFormat": "Jegy kód formátum",
      "workLogFormat": "Munkalap formátum"
    },
    "regional-setting": {
      "description": "Leírás",
      "modelName": "Régió beállítás",
      "partner": "Partner",
      "settingKey": "Kulcs",
      "settingValue": "Érték"
    },
    "repair-claim": {
      "modelName": "Javítás jogcíme",
      "name": "Megnevezés"
    },
    "repair-form": {
      "clientAddress": "Kiszállási cím",
      "clientEmail": "Megrendelő email",
      "clientName": "Megrendelő neve",
      "clientPhone": "Megrendelő telefonszáma",
      "dateRepairEnd": "Munka befejezése",
      "dateRepairStart": "Munka megkezdése",
      "dateTicketCreate": "Bejelentés dátuma",
      "devicePartNumber": "P/N",
      "deviceSerialNumber": "Serial Number",
      "deviceType": "Eszköz típusa",
      "externalID": "Külső azonosító",
      "ids": "Azonosítók",
      "modelName": "Szerviz munkalap",
      "newPartName": "Cserélt alkatrész megnevezése",
      "newPartSPN": "Cserélt alkatrész SPN",
      "orderNumber": "Order Number",
      "repairClaim": "Javítás jogcíme",
      "repairEngineer": "Szerviz mérnök",
      "repairFormNumber": "Munkalapszám",
      "returnable": "Visszaküldendő",
      "save-info": "A nem kötelező mezők változtatásai a PDF generálása előtt kerülnek mentésre",
      "technial-details": "Műszaki adatok",
      "validation-error": "A jegyhez tartozik szervíz munkalap, ami nincs megfelelően kitöltve",
      "warranty": "Garanciális"
    },
    "reports": {
      "inventories": {
        "footer": "Lábléc",
        "for-assigned-contact": "Leltár Használóknak",
        "inventory-space": "Leltár ",
        "narrowing-inventory": "Leltár szűkítése",
        "reason": "Indok",
        "select-coworker": "Válasszon munkatársat",
        "select-floor": "Válasszon emeletet",
        "select-inventory": "Leltár kiküldése",
        "select-organizational-unit": "Válasszon szervezeti egységet",
        "select-pertner": "Válasszon partnert",
        "select-site": "Válasszon telephelyet",
        "tools-space": "Eszközök "
      },
      "inventory-pdf": "Leltár PDF",
      "pdf": "PDF",
      "preview": "Előkép"
    },
    "resource-group": {
      "authorizer": "Jóváhagyó",
      "manager": "Menedzser",
      "modelName": "Tervezési csoport",
      "name": "Név",
      "planners": "Tervezők",
      "solvers": "Megoldók"
    },
    "resource-period": {
      "approvedHours": "Elfogadott órák",
      "managerName": "Menedzser",
      "name": "Név",
      "planned-plannable": "Tervezett/Tervezhető",
      "plannedHours": "Tervezhető órák",
      "resourcePeriodName": "Tervezési időszak"
    },
    "resource-plan": {
      "add-resource-plan": "Terv hozzáadása",
      "approvedHours": "Elfogadott órák",
      "description": "Leírás",
      "modelName": "Kapacitás tervezés",
      "plannedHours": "Tervezett órák",
      "priority": "Prioritás",
      "project": "Akta",
      "rbk": "RBK",
      "rbkDate": "RBK dátum",
      "save-success": "A terv mentése sikeresen megtörtént",
      "save-title": "Terv mentése",
      "solver": "Megoldó",
      "state": "Státusz",
      "ticket": "Feladat"
    },
    "resource-plan-priority": {
      "color": "Szín",
      "intlKey": "Intl kulcs",
      "modelName": "Terv priortiás",
      "name": "Név",
      "priority": "Prioritás",
      "value": {
        "must-have": "Kötelező",
        "nice-to-have": "Jó lenne",
        "normal": "Normál"
      }
    },
    "roundTypes": {
      "five": "5 Forintos kerekítés",
      "normal": "Normál kerekítés"
    },
    "scheduled-task": {
      "audit-log-info": "Az utolsó {limit} jegy, ami ebből a sablonból készült"
    },
    "service-level": {
      "critical": "Critical",
      "escalation": "Eszkaláció",
      "groups": "Csoportok",
      "modelName": "Szolgáltatási szint",
      "name": "Megnevezés",
      "new-item": "Új elem",
      "normal": "normal",
      "opening": "Megnyitás",
      "overdue": "Overdue",
      "priority": "Prioritás",
      "repairService": "Szerviz szolgáltatás",
      "roles": "Szerepkörök",
      "servicePeriods": "Szolgáltatási időszak",
      "solution": "Megoldás",
      "status-change": "Státusz változás",
      "template-change-confirmation": "A sablon kiválásztásával a mostani adatok elvesznek, és a sablon adataival a szolgáltatási szint azonnal mentődik az adatbázisba. Biztos, hogy folytatni szeretné?",
      "type": "Típus",
      "users": "Felhasználók",
      "warning": "warning",
      "worksheets": "Munkalapok"
    },
    "service-level-template": {
      "modelName": "Szolgáltatási szint sablon",
      "name": "Név",
      "reactionTime": "Reakció idő",
      "solvingTime": "Megoldási idő",
      "startTime": "Megkezdési idő"
    },
    "service-order": {
      "endDate": "Vége",
      "file": "Akta",
      "servicePerson1": "Elsődleges",
      "servicePerson2": "Másodlagos",
      "startDate": "Kezdete",
      "task": "Feladat",
      "weekDay": "Hét napja"
    },
    "service-period": {
      "dateType": "Nap típusok",
      "modelName": "Szolgáltatási idő",
      "name": "Név",
      "servicePeriodIntervals": "Időpontok"
    },
    "service-period-file": {
      "dateType": "Nap típusok",
      "modelName": "Szolgáltatási idő",
      "name": "Megnevezés",
      "servicePeriodIntervals": "Időpontok"
    },
    "service-period-interval": {
      "end": "Végzés időpont",
      "modelName": "Időszak",
      "start": "Kezdés időpont"
    },
    "settings": {
      "acl": {
        "create-new-acl": "Új ACL felvétele",
        "create-role": "Új szerepkör felvétele",
        "edit-role": "Szerepkör szerkesztése",
        "new-acl": "Új ACL"
      },
      "cmdb": {
        "add-dictionary": "Új szótár felvétele",
        "create-attribute": "Új attribútum felvétele",
        "create-ci-category": "Új CI kategória felvétele",
        "create-ci-type": "Új CI típus felvétele",
        "create-connection-type": "Új kapcsolat típus felvétele",
        "create-element": "Új elem felvétele",
        "create-manufacturer": "Új gyártó felvétele",
        "create-tool-type": "Új eszköz típus felvétele",
        "edit-attribute": "Attribútum szerkesztése",
        "edit-ci-category": "CI kategória szerkesztése",
        "edit-ci-type": "CI típus szerkesztése",
        "edit-code-store": "Szótár szerkesztése",
        "edit-connection-type": "Kapcsolat típus szerkesztése",
        "edit-element": "Elem szerkesztése",
        "edit-manufacturer": "Gyártó szerkesztése",
        "edit-relation": "Reláció szerkesztése",
        "edit-tool-type": "Eszköz típus szerkesztése"
      },
      "date-type-definitions": {
        "add-date": "Dátum hozzáadása",
        "new-day-type-definition": "Új nap típus definíció"
      },
      "document-types": {
        "document-type": "Dokument típus",
        "new-document-type": "Új dokumentum típus"
      },
      "fetchmail": {
        "authenticationType": "Hitelesítés típusa",
        "authenticationTypeBasic": "Alap",
        "authority": "Hitelesítés címe (URL)",
        "clientId": "Kliens azonosító (clientId)",
        "clientSecret": "Titkos ügyfélkód (clientSecret)",
        "connect": "Szerver & Bejelentkezés",
        "error-logs": "Hibanapló"
      },
      "file-states": {
        "edit-file-type": "Akta típus szerkesztése",
        "file-status": "Akta státusz"
      },
      "finances": {
        "edit-invoice-category": "Számla kategória szerkesztése",
        "edit-invoice-type": "Számla típusának szerkesztése",
        "edit-uoms": "Mértékegység szerkesztése",
        "edit-vat": "Áfa szerkesztése",
        "exchangeKey": "exchangeKey",
        "login": "login",
        "new-invoice-category": "Új számla kategória",
        "new-invoice-type": "Új számla típus",
        "new-uoms": "Új mértékegység",
        "pass": "pass",
        "signaturekey": "signatureKey",
        "taxNumber": "taxNumber"
      },
      "grade-costs": {
        "edit-hourly-rate": "Óradíj szerkesztése"
      },
      "main": {
        "contract-not-required": "Szerződés nem szükséges",
        "contract-required": "Szerződés szükséges",
        "export-not-required": "Export nem szükséges",
        "export-required": "Export szükséges",
        "fetchmail": {
          "connect": "Szerver & Bejelentkezés",
          "error-logs": "Hibanapló"
        },
        "income-quote-is-not-required": "Bejövő árajánlat nem szükséges",
        "income-quote-required": "Bejövő árajánlat szükséges",
        "its-permission": " jogosultsága",
        "outbound-quote-not-required": "Kimenő árajánlat nem szükséges",
        "outbound-quote-required": "Kimenő árajánlat szükséges",
        "planning-is-not-required": "Tervezés nem szükséges",
        "planning-is-required": "Tervezés szükséges",
        "sponsor": "Előre beállított szponzor",
        "valid-price-offer-not-required": "Érvényes árajánlat nem szükséges",
        "valid-price-offer-required": "Érvényes árajánlat szükséges"
      },
      "manage-time-offs": {
        "new-leave-application": "Új szabadság kérelem"
      },
      "news-feed": {
        "add-news": "Hír hozzáadása",
        "edit-news": "Hír szerkesztése"
      },
      "operations": {
        "add-new-element": "Új elem hozzáadása",
        "checking-list-elements": "Ellenőrző lista elemei",
        "edit-annoucement-type": "Bejelentés típus szerkesztése",
        "edit-service-area": "Szolgáltatási terület szerkesztése",
        "edit-service-level": "Szolgáltatási szint szerkesztése",
        "edit-solving-group": "Megoldó csoport szerkesztése",
        "edit-status": "Státusz szerkesztése",
        "new-annoucement-type": "Új bejelentés típus felvétele",
        "new-list-elements": "Új ellenőrző lista",
        "new-service-area": "Új szolgáltatási terület",
        "new-service-area-two": "Új szolgáltatás terület",
        "new-service-level": "Új szolgáltatási szint",
        "new-service-level-two": "Új szolgáltatás szint",
        "new-solving-group": "Új megoldó csoport felvétele"
      },
      "payments": {
        "edit-currency": "Pénznem szerkesztése"
      },
      "priority": {
        "edit-priority": "Prioritás szerkesztése",
        "new-priority": "Új prioritás"
      },
      "priority-matrix": {
        "edit-priority-matrix": "Prioritás mátrix szerkesztése"
      },
      "priority-urgency": {
        "edit-urgency": "Sürgősség szerkesztése",
        "new-urgency": "Új"
      },
      "priotity-importance": {
        "edit-business-effect": "Üzleti hatás szerkesztése",
        "new-business-effect": "Új üzleti hatás"
      },
      "regions": {
        "edit-region": "Régió szerkesztése"
      },
      "ticket-status": {
        "new-ticket-status": "Új ticket státusz",
        "ticket-status": "Ticket státusz"
      },
      "user-grades": {
        "add-position": "Munkakör hozzáadása",
        "edit-position": "Munkakör szerkesztése"
      },
      "users": {
        "add-user": "Felhasználó felvétele",
        "edit-holiday-number": "Szabadságszám frissítése",
        "edit-user": "Felhasználó szerkesztése",
        "inactivate-info": "Biztos benne, hogy inaktiválni akarja a felhasználót?",
        "inactivate-user": "Felhasználó inaktiválása",
        "inactivateFailure": "Az inaktiválás sikeretelen volt.",
        "inactivateSuccess": "Az inaktiválás sikeres volt.",
        "inactivating": "{name} inaktiválás"
      }
    },
    "site-zone": {
      "default": "Alapértelmezett",
      "modelName": "Zóna",
      "name": "Név",
      "partner": "Partner"
    },
    "solving-group": {
      "accounts": "Felhasználók",
      "color": "Szín",
      "default": "Alapértelmezett (Eljárásrend)",
      "email": "Email",
      "leader": "Vezető",
      "modelName": "Megoldó csoport",
      "name": "Név",
      "servicePerson1": "Elsődleges megoldó",
      "servicePerson2": "Másodlagos megoldó",
      "shiftChanges": "Műszakváltások"
    },
    "substitution-modal": {
      "new-substitute": "Új helyettesítő beállítása",
      "no-substitute": "Jelenleg nincs helyettesítő beállítva",
      "remove": "Helyettesítő eltávolítása",
      "remove-confirmation": "Biztos, hogy el akarja távolítani a helyettesítőt?",
      "title": "Helyettesítő"
    },
    "supplier": {
      "modelName": "Beszállító",
      "name": "Beszállító"
    },
    "tables": {
      "add-license": "Licenc hozzáadása",
      "allocat": "Kiosztható",
      "allocated": "Kiosztott",
      "arrow": "➜",
      "choose": "Kiválaszt",
      "choose-item": "Tétel kiválasztása",
      "create-new-license": "Új licenc felvétele",
      "currently-no-critical-incidents": "Jelenleg nincs kritikus incidens",
      "currently-no-group-tasks": "Jelenleg nincs csoport feladat",
      "currently-no-out-of-hours-call": "Jelenleg nincs törzsidőn kívüli hívás",
      "currently-no-pre-notice": "elenleg nincs előbejelentés",
      "currently-no-scheduled-shutdowns": "Jelenleg nincs tervezett leállás",
      "currently-no-tasks": "Jelenleg nincs feladat",
      "day": "n",
      "delete": "Biztos benne, hogy törölni szeretné?",
      "discount": "Árrés / Árengedmény (%)",
      "discount-sum": "Árengedmény összege",
      "edit": "Szerkeszt",
      "elements": "Elemek",
      "end-time": "Befejezés ideje",
      "expire-lower": "lejár",
      "expired-lower": "lejárt",
      "file-upload": "Fájl feltöltés",
      "gross-amount": "Bruttó összeg",
      "hours": "ó",
      "minutes": "p",
      "nanda": "'N/A'",
      "nanda-lower": "n/a",
      "net-amount": "Nettó összeg",
      "new-element": "Új elem",
      "new-license": "Új licenc",
      "no-current-task": "Jelenleg nincs kiosztott feladat",
      "normal-relations": "Normál relációk",
      "not-provided": "Nincs megadva",
      "planned": "Tervezett",
      "project-task": "Projekt feladat",
      "quantity": "Mennyiség",
      "quantity-unit": "Mértékegység",
      "request-error": "⛔️ Hiba történt a lekérés során!",
      "reverse-relations": "Fordított relációk",
      "start-time": "Kezdés ideje",
      "status-changes": "Státusz változtatások",
      "sum": "Összesen: {length}",
      "surcharge-sum": "Árrés összege",
      "surcharge-sum-info": "Nem fog megjelenni az árajánlaton",
      "tax": "Adó",
      "under-consideration": "Elbírálás alatt",
      "unit-price": "Egységár",
      "vacation": "Jelenleg nincs szabadság kiírva",
      "violed": "SÉRTVE",
      "watched-hours": "Rákönyvelt órák",
      "work-time": "Munkavégzés ideje"
    },
    "tandm": {
      "activate-confirmation": "Biztos benne, hogy aktiválni szeretné a T&M keretet? Utólag már nem változtathat rajta!",
      "alreadyInvoiced": "Kiszámlázott",
      "alreadyUsed": "Felhasznált",
      "amount": "Cikusonkénti ár",
      "balance": "Egyenleg",
      "billingCycle": "Számlázás ciklusa",
      "calledHoursProposal": "Lehívott órák",
      "called_hours": "A lehívott órák számlázása",
      "close-confirmation": "Biztos benne, hogy le akarja zárnis a T&M keretet?",
      "copyFromContract": "Másolás keretszerződésből",
      "create": "Létrehozás",
      "credit": "Jóváírás",
      "creditValueDay": "Jóváírás napja",
      "cumulable": "Hány havi görgetés",
      "custom": "egyéni",
      "dateEnd": "Támogatás vége",
      "dateStart": "Támogatás kezdete",
      "delete": "Törlés",
      "fixed": "Fix keret",
      "generate-called-hours-proposal": "Lehívott órák ajánlatának generálás",
      "generate-called-hours-proposal-success": "A lehívott órák ajánlatának generálása sikeresen megtörtént.",
      "generate-overtime-proposal": "Túlkönyvelés ajánlatának generálás",
      "generate-overtime-proposal-success": "A túlkönyvelés ajánlatának generálása sikeresen megtörtént.",
      "generate-specified-amount-proposal": "Megadott összeg ajánlatának generálás",
      "generate-specified-amount-proposal-success": "A megadott összeg ajánlatának generálása sikeresen megtörtént.",
      "hoursBudget": "Óra keret",
      "methodOfSettlement": "Elszámolás módja",
      "modelName": "T&M",
      "monthly": "havi",
      "monthlyBooking": "Havi keret",
      "name": "Név",
      "operationServiceLevel": "Üzleti szolgáltatás",
      "operationServiceLevels": "Üzleti szolgáltatások",
      "overtimeModifier": "Túlóra módosító (%)",
      "overtimeProposal": "Túlkönyvelés",
      "price_includes": "Ciklusonkénti ár tartalamzza a keretben meghatározott órákat",
      "settlementOfOvertime": "Túlórák elszámolása",
      "specifiedAmountProposal": "Megadott összeg számlázása",
      "specified_amount": "Megadott összeg a keretben meghatározott órákra",
      "startBalance": "Induló órakeret",
      "state": "Státusz",
      "task": "Feladat",
      "ticket": "Jegy",
      "tickets": "Csatolt feladatok",
      "update": "Módosítás",
      "userGrade": "Kompetencia beállítása",
      "worklog": "Munkalap",
      "yearly": "éves"
    },
    "tandm-state": {
      "modelName": "T&M státusz"
    },
    "tandm-user-grade-cost": {
      "cost": "Óradíj",
      "userGrade": "Besorolás"
    },
    "task": {
      "task-worksheets": "Feladathoz tartozó munkalapok"
    },
    "tasks": {
      "main": {
        "actual-colon": "Aktuális:",
        "add-date": "Dátum hozzáadása",
        "all-colon": "Összes:",
        "all-day-single": "Egész nap",
        "application-request": "Jóváhagyásra váró kérelmek",
        "beginning": "Kezdete",
        "billeable-total-colon": "Számlázható összesen:",
        "date": "Dátum",
        "date-of-implementation": "Megvalósítás dátuma",
        "day-of-week": "Hét napja",
        "description-of-performed-task": "Elvégzett feladat leírása",
        "edit-worksheet": "Munkalap szerkesztése",
        "end-colon": "Vége:",
        "enter-time-intervals": "Idő intervallumok megadása",
        "expired-colon": "Lejárt:",
        "grouping": "Csoportosítás",
        "hour": "óra",
        "inactive-total-colon": "Inaktív összesen",
        "limit-colon": "Limit:",
        "notification-number": "Bejelentés száma",
        "own-requests": "Saját kérelmek",
        "primary": "Elsődleges",
        "secondary": "Másodlagos",
        "solved-colon": "Megoldott:",
        "start-colon": "Kezdete:",
        "time": "Idő"
      }
    },
    "tax-number-component": {
      "add-address": "Új cím hozzáadása",
      "ambiguous-country": "A partnerhez több számlázási cím van megadva különböző országokkal ({countries}). A partner országa nem állapítható meg egyértelműen.",
      "country-not-supported": "A partner országa nem támogatott.",
      "list-of-supported-countries": "A támogatott országok listája megtekinthető",
      "new-billing-address-country": "Az újonnan kiválasztott országhoz ({country}) tartozik adószám formátum ellenőrzés {regex}, aminek a jelenleg beállított adószám {taxNumber} nem felel meg. Az \"Alap adatok\" fülön változtassa meg.",
      "no-billing-address": "A partnerhez nincs számlázási cím megadva amely alapján meghatározható az országa.",
      "no-form-checking": "Megadott ország hiányában a rendszer csak egyediséget vizsgál, formai ellenőrzést nem tud végrehajtani.",
      "partner-has-country": "A számlázási cím alapján a partner országa: ",
      "regexp": "Reguláris kifejezés",
      "set-tax-number": "Adószám beállítása",
      "supported-tax-numbers": "Támogatott adószámok",
      "supported-tax-numbers-info": "A támogatott reguláris kifejezések, és a hozzájuk tartozó példák",
      "tax-number-invalid": "A megadott adószám reguláris kifejezés alapján helytelen",
      "tax-number-not-unique": "A megadott adószám nem egyedi",
      "tax-number-unique": "A megadott adószám egyedi",
      "tax-number-valid": "A megadott adószám reguláris kifejezés alapján helyes",
      "title": "Adószám szerkesztése"
    },
    "tax-number-pattern": {
      "country": "Ország",
      "modelName": "Adószám regex",
      "pattern": "Reguláris kifejezés"
    },
    "tax-number-pattern-example": {
      "comment": "Megjegyzés",
      "example": "Példa",
      "modelName": "Adószám regex példa"
    },
    "templates": {
      "main": {
        "accepted": "elfogadta",
        "close": "Close",
        "damit-it-2016": "© 2016 Damit IT",
        "email": "Email",
        "email-sent": "E-mail elküldve!",
        "fast-menu": "Gyorsmenü",
        "forgotten-password": "Elfelejtett jelszó?",
        "incorrect-email-or-pw": "Hibás email cím és/vagy jelszó!",
        "invalid-token": "Invalid token!",
        "logout": "Kijelentkezés",
        "menu-switch": "Menü kapcsoló",
        "my-profile": "Profilom",
        "navigation": "Navigation",
        "or": "vagy",
        "page-not-exist": "We're sorry, but the page you were looking for doesn't exist.",
        "password": "Password",
        "path-not-find": "A megadott útvonal nem elérhető.",
        "pw-changed": "Jelszó megváltoztatva!",
        "reason-sent-successfuly": "Az indok elküldése sikeresen megtörtént.",
        "remember-me": "Emlékezz rám",
        "request-denied": "A kérés visszautasítva:",
        "sign-in": "Sign In",
        "time-mark": "Időbélyeg",
        "toggle-sidebar": "Toggle sidebar",
        "ty-email-sent-with-info": "Köszönjük! A megadott felhasználóhoz tartozó e-mail címre elküldtük a további teendőket.",
        "useful-links": "Here are some useful links",
        "you-declined-mail": "Ön az email-ben az elutasítást választotta. Kérjük adja meg az indokot (minimum 10 karakter), és az elküld gombbal küldje el.",
        "you-from-mailstore": "Ön a leltárról küldött emailt"
      }
    },
    "ticket": {
      "activeTandm": "T&M",
      "allSpentTime": "Felhasznált összes",
      "alreadyAssignedTimes": "Felhasznált",
      "availableTimeToAssign": "Kiosztható",
      "bulk-delete-confirmation": "Biztos benne hogy folyatni szeretné? (Csak a munkalap nélküli feladatok kerülnek törlésre!)",
      "bulk-delete-response": "A kiválasztott {all} feladatból {deleted} került törlésre. {skipped} feladatra már volt könyvelve óra.",
      "businessService": "Üzleti szolgáltatás",
      "businessServiceName": "Terület",
      "clientPartner": "Ügyfél",
      "completionLevel": "Készültségi fok",
      "contact": "Bejelentő",
      "createDate": "Létrehozás dátuma",
      "creator": "Létrehozó",
      "dependencies": "Függőségek",
      "description": "Leírás",
      "duplicateTasks": "{subject} (másolat) {numberOfCopies}",
      "file": "Akta",
      "maxHoursPerWorkLog": "Max rögzíthető (óra)",
      "mergedParentTicketNumber": "Szülő jegy azonosítója",
      "modelName": "Feladat",
      "open-ticket": "Feladat megnyitása",
      "orderIndex": "Sorrend",
      "parent": "Szülő feladat",
      "partner": "Partner",
      "plannedEndDate": "Befejezés",
      "plannedExpensesInHour": "Terv ráfordítás órában",
      "plannedStartDate": "Kezdés",
      "plannedUserGrade": "Szerepkör",
      "priority": "Prioritás",
      "productResult": "Eredménytermék",
      "pst": "PST azonosító",
      "resolver": "Megoldó",
      "solveDeadline": "Határidő",
      "solverGroup": "Megoldócsoport",
      "solvers": "Érintettek",
      "solvingGroup": "Megoldócsoport",
      "state": "Feladat állapota",
      "stateChanged": "Státusz változás dátuma",
      "subject": "Tárgy",
      "sumPublicSpentTime": "Felhasznált aktív",
      "sumSpentTime": "Felhasznált inaktív",
      "supplierPartner": "Partner",
      "ticketNumber": "Azonosító",
      "ticketStateId": "Feladat állapota",
      "ticketUserAssocs": "Megoldók",
      "type": "Típus",
      "userAssocs": "Megoldók",
      "workLogNotification": "Email értesítés",
      "workLogs": "Munkalapok",
      "workplaceAddress": "Munkavégzés helye"
    },
    "ticket-state": {
      "applicationToIntervene": "RBK",
      "isSystemType": "Rendszer állapot",
      "management": "Érvényes lépések",
      "modelName": "Bejelentés státusz",
      "name": "Név",
      "project": "Projekt",
      "sd": "Üzemeltetés"
    },
    "ticket-type": {
      "modelName": "Bejelentés típus",
      "name": "Név",
      "scheduledTask": "Időzíthető"
    },
    "ticket-user-assoc": {
      "allSpentTime": "Rákönyvelt órák",
      "deadline": "Befejezés ideje",
      "endDate": "Befejezés ideje",
      "endDateLtStartDate": "A \"Befejezés ideje\" nem lehet kisebb, vagy ugyan az, mint a \"Kezdés ideje",
      "internalWork": "Belső munka",
      "modelName": "Kiosztás",
      "plannedExpensesInHour": "Kiosztott órák",
      "remainingTime": "Hátralévő idő (ó:p)",
      "startDate": "Kezdés ideje",
      "supplier": "Beszállító",
      "ticket": "Feladat",
      "user": "Lehetséges megoldó",
      "workLogs": "Rákönyvelt órák"
    },
    "ticket-user-assoc-computed": {
      "deadline": "Hátralévő idő (ó:p)",
      "endDate": "Befejezés ideje",
      "modelName": "Kiosztás",
      "plannedExpensesInHour": "Kiosztott órák",
      "startDate": "Kezdés ideje",
      "supplier": "Beszállító",
      "ticket": "Feladat",
      "user": "Lehetséges megoldó",
      "workLogs": "Rákönyvelt órák"
    },
    "ticketsInProgress": "Folyamatban lévő feladatok",
    "ticketsadd-news": "Hír hozzáadása",
    "time": {
      "day-month": "Nap (hónap)",
      "day-week": "Nap (hét)",
      "days-abbrev": "{days}n",
      "hours": "Óra",
      "hours-abbrev": "{hours}ó",
      "minute": "Perc",
      "minutes-abbrev": "{minutes}p",
      "month": "Hónap",
      "quarterOfYear": "Negyedév",
      "year": "Év"
    },
    "timeline": {
      "credit": {
        "balance": "Az új egyenleg: ",
        "credited": "jóváírása megtörtént.",
        "monthly-budget": "A havi keret"
      },
      "export": "Munkalap események exportálása",
      "proposal": {
        "called-hours-proposal": "kimenő ajánlatot a lehívott órákról.",
        "create": "létrehozta",
        "delete": "törölte",
        "overtime-proposal": "kimenő ajánlatot a túlkönyvelt órákról.",
        "specified-amount-proposal": "kimenő ajánlatot a megadott összegről."
      },
      "statistics": {
        "dataset": "Könyvelések",
        "day": "Napi",
        "month": "Havi",
        "week": "Heti"
      },
      "tandm": {
        "create": "létrehozta a T&M-et",
        "update": "megváltoztatta a T&M-et"
      },
      "tandm-osl": {
        "create": "hozzáadta",
        "delete": "elvette"
      },
      "ticket": {
        "checkbox": "jegy T&M értékét",
        "create": "létrehozta a",
        "ticket": "jegyet",
        "to-false": "hamisra.",
        "to-true": "igazra.",
        "update": "megváltoztatta a"
      },
      "work-log": {
        "cost": "óradíjjal",
        "create": "könyvelt a",
        "delete": "törölte",
        "hours": "órát",
        "ticket": "feladatra",
        "update": "megváltoztatta",
        "userGrade": "beosztásban"
      }
    },
    "translation": {
      "description": "Leírás",
      "language": "Nyelv",
      "modelName": "Fordítás",
      "name": "Megnevezés"
    },
    "tsm-import": {
      "button": "Adatbetöltés",
      "summary": {
        "all": "Összes sorok száma: ",
        "errors": "sornál történt hiba, és nem került importálásra!",
        "errors-info": "A letöltött file utolsó oszlopában találhatók a hibák szövege",
        "success": "Beimportált munkalapok száma: "
      }
    },
    "unauth": {
      "accept": "Elfogadom",
      "damit": "DAMIT",
      "dashboard": "Dashboard",
      "emailRegex": "Helytelen email!",
      "invalid-token": "A megadott token nem érvényes.",
      "invalid-token2": "Hibás vagy már érvénytelen token!",
      "login": "Bejelentkezés",
      "main": {
        "copyright": "© Copyright 2024. All Rights Reserved.",
        "e-mail": "E-mail",
        "e-mail-address": "Email cím",
        "forgotten-password": "Elfelejtett jelszó",
        "iris": "IRIS",
        "new-password": "Új jelszó",
        "not-robot": "Nem vagyok robot",
        "password-again": "Jelszó újra",
        "registration-success": "Sikeres regisztráció!",
        "two-fields-not-match": "Nem egyezik a két mező!",
        "verify-at-email-link": "A fiókja aktiválását a megadott e-mail címre küldött levében található linkre kattintva tudja megtenni"
      },
      "maximum-character": "Maximum 300 karakter",
      "messageMaxChar": "Az üzenet nem lehet hosszabb mint 300 karakter!",
      "navigation": "Navigáció",
      "note": "Megjegyzés",
      "note-length": "megjegyzés hossza: {length}",
      "passwordNotMatch": "Jelszó nem egyezik!",
      "passwordRegex": "A jelszónak tartalmaznia kell legalabább egy nagy-, egy kisbetűt és egy számot!",
      "please-accept": "Kérjük fogadja el, vagy ha nem ért vele egyet, akkor utasítsa el a változás kérelmet. Ha kívánja, indokolja meg döntését a Megjegyzés mező kitöltésével",
      "registration": "Regisztráció",
      "registration-don": "A regisztráció sikeresen befejeződött.",
      "reject": "Elutasítom",
      "successful-registration": "Sikeres regisztráció",
      "task": "Feladataim",
      "thank-you-feedback": "Köszönjük visszajelzését!"
    },
    "uom": {
      "modelName": "Mértékegység",
      "name": "Név",
      "navName": "NAV megnevezés"
    },
    "user": {
      "change-image": "Kép megváltoztatása",
      "change-password": "Jelszó megváltoztatása",
      "full-name": "Teljes név",
      "language": "Nyelv",
      "leader": "Vezető",
      "max-registred-hours": "Max rögzíthető óra",
      "number-of-tickets": "Feladatok száma",
      "password": "Jelszó",
      "password-again": "Jelszó újból",
      "position": "Munkakör",
      "raw-cost": "Nyers óradíj",
      "report-as-another-user": "Bejelenthet más nevében",
      "yearly-holidays": "Éves szabadság keret"
    },
    "user-grade": {
      "cost": "Óradíj",
      "modelName": "Óradíj",
      "name": "Név"
    },
    "validators": {
      "entityRelated": {
        "presence": "Ez a mező nem lehet üres ({attribute})"
      },
      "entityValue": {
        "between": "A mező értéke {min} és {max} között kell legyen",
        "max": "A mező értéke maximum {max} kell legyen",
        "min": "A mező értéke minimum {min} kell legyen",
        "mustBeNumber": "A mező értéke szám kell legyen",
        "presence": "Ez a mező nem lehet üres. AttributeId: {entityAttrId} Value: {value}",
        "regex": "A mező értéke nem felel meg a reguláris kifejezésnek: {regexp}. Value: {value}"
      },
      "numberOwn": {
        "mustBeNumber": "A mező csak számot tartalmazhat"
      },
      "plannedExpenses": {
        "expendedOverPlanned": "A kiosztott órák száma nem lehet nagyobb, mint a tervezett órák száma.",
        "mustBeNumber": "A mező értéke szám kell legyen.",
        "presence": "Ez a mező nem lehet üres.",
        "zeroExpense": "Nem lehet 0 órával feladatot kiosztani"
      },
      "presence": "A mező kitöltése kötelező!",
      "quarterOfHour": "Csak negyed óránkénti osztás engedélyezett! pl.: 2.25, 2.50, 2.75, stb.",
      "slug": {
        "allowedCharacters": "A mező csak betűt, számot és \"_\" vagy \"-\" karaktereket tartalmazhat",
        "bannedWords": "A mező neve nem lehet: {bannedWords}"
      }
    },
    "value": {
      "not-available": "N/A"
    },
    "vat": {
      "code1": "Kód 1",
      "code2": "Kód 2",
      "dateFrom": "Érvényesség kezdete",
      "dateTo": "Érvényesség vége",
      "modelName": "Áfa",
      "systemCode": "Rendszer kód",
      "vatCode": "Áfa kód",
      "vatId": "Áfa azonosító",
      "vatType": "Áfa típusa",
      "vatValue": "Áfa értéke"
    },
    "wiki": {
      "main-data": "Alap adatok",
      "operational-project": "Az üzemeltetési akta tipikusan üzemeltetési feladatok kezelésére szolgál. Egy üzemeltetési akta létrehozása - lekövetve a valós idejű eseményeket - lehetőség létrehozásával indul.",
      "partner": "Alvállalkozás esetén meg lehet határozni, hogy ki az Ügyfél.",
      "partner-data": "Ügyfél adatok",
      "project": "A projekt akta tipikusan rendszerintegrációs (projekt) feladatok kezelésére szolgál. Egy projekt akta létrehozása - lekövetve a valós idejű eseményeket - lehetőség létrehozásával indul.",
      "record": "bejegyzés",
      "settings": "Beállítások",
      "show-more": "mindedet mutat",
      "wiki": "Wiki"
    },
    "work-log": {
      "attachment": "Melléklet van-e",
      "beginDate": "Kezdés",
      "businessService": "Üzleti szolgáltatás",
      "clientPartnerName": "Partner név",
      "comment": "Megoldás",
      "createDate": "Regisztrálás",
      "decription": "Munkalap leírása (tartalma)",
      "endDate": "Befejezés",
      "file": "Akta",
      "fileDateEnd": "Projekt vége",
      "fileDateStart": "Projekt kezdete",
      "fileName": "Projekt neve",
      "generationDate": "Generálás Dátum / Idő",
      "isLate": "Késésben van-e",
      "managerUserName": "Projektvezető",
      "modelName": "Munkalap",
      "overlap": "Az események között nem lehet 2 átfedésnél több",
      "primeContractorName": "Ügyfél név",
      "publicSpentTime": "Aktív óra",
      "solver": "Megoldó",
      "spentTime": "Inaktív óra",
      "status": "Státusz",
      "ticketEnd": "Feladat vége",
      "ticketName": "Feladat neve",
      "ticketStart": "Feladat kezdete",
      "workDate": "Munkavégzés dátuma",
      "workLogNumber": "Munkalap száma"
    }
  }]];
  _exports.default = _default;
});